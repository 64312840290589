<!--suppress JSUnresolvedVariable -->
<template>
  <div class="popup__wrapper" v-if="cookies.enable">
    <transition name="fade" mode="out-in">
      <div class="popup__overlay" @click.self="toggle" v-if="show">
        <div class="popup">
          <div class="container">
            <div class="popup__container">
              <div class="popup__close" @click="toggle"></div>

              <div class="popup__icon">
                <cookie-svg></cookie-svg>
              </div>

              <div class="popup__content">
                <div class="popup__title">{{ cookies.title }}</div>
                <div class="popup__text" v-html="cookies.text"></div>
              </div>

              <div class="popup__buttons">
                <button class="btn" @click="accept">{{ 'Accept' }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import cookieSvg from '../../icons/cookie.svg';

export default {
  name: "popupCookies",
  components: {cookieSvg},
  data() {
    return {
      show: false,
      delay: 3000,
      // show: !this.$cookies.get('CookieConsent'),
    }
  },
  computed: {
    options() {
      return this.$store.state.options;
    },
    cookies() {
      return this.options.cookies || {};
    }
  },
  mounted() {
    if (this.cookies.enable) {
      let buttons = document.querySelectorAll('[data-popup=cookies]');
      buttons.forEach(button => {
        button.addEventListener('click', () => this.toggle());
      });

      let show = !this.$cookies.get('CookieConsent');
      if (show) {
        setTimeout(() => {
          this.show = true;
        }, this.delay);
      }
    }
  },
  methods: {
    toggle() {
      this.show = !this.show;
      document.body.classList.toggle('over-hidden');
    },
    accept() {
      this.$cookies.set('CookieConsent', true);

      this.toggle();
    },
  },
}
</script>

<style lang="scss" scoped>
.popup {
  max-width: 532px;

  &__container {
    padding: 40px 50px;
    text-align: center;
  }
}
</style>

<style lang="scss">
.popup {
  width: 100%;
  position: relative;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #000000;
  transition: all 0.4s;

  &__wrapper {

  }

  &__container {
    padding: 40px 0;
    position: relative;
  }

  &__overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 6;

    //background: rgba(#F38606, 0.15);
    //background: rgba(#F38606, 0.35);
    background: rgba(#000000, 0.6);

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__close {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
    opacity: 0.3;
    cursor: pointer;
    transition: all 0.4s;
    z-index: 9;

    &:hover {
      opacity: 1;
    }

    &:before, &:after {
      position: absolute;
      left: 15px;
      content: ' ';
      height: 26px;
      width: 2px;
      background-color: #ffffff;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  &__content {

  }

  &__title {
    margin-bottom: 10px;

    color: #ffffff;
    font-size: 34px;
    font-weight: 600;
  }

  &__text {
    color: #D4D4D4;
    font-weight: 400;
  }

  &__buttons {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    //button {
    //  &:not(:last-child){
    //      margin-right: 90px;
    //  }
    //}
  }

  &__icon {
    margin-bottom: 16px;
  }

}
</style>
