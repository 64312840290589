<template>
  <div class="file-input">
    <div class="file-input__wrapper"
         @dragover="dragover"
         @dragleave="dragActive = false"
         @drop="drop"
         :class="{'drag-active': dragActive}">

      <transition name="fade" mode="out-in">
        <div class="file-input__inner" v-if="!file">
          <div @click="$refs.file.click()" class="file-input__label">
            <span class="file-input__icon">
              <clip-svg></clip-svg>
            </span>
            <span>{{ label }}</span>
          </div>
          <div class="file-input__hint">{{ hint }}</div>
        </div>

        <div class="file-input__chosen" v-else>
          <span class="file-input__chosen__text">{{ file.name }}</span>

          <span @click="remove" class="file-input__chosen__icon">
            <bin-svg></bin-svg>
          </span>
        </div>
      </transition>
    </div>

    <div class="file-input__error" v-if="innerError">{{ innerError }}</div>

    <input type="file"
           ref="file"
           class="file-input__file"
           @change="onChange"
           :accept="fileTypes.join(', ')" />
  </div>
</template>

<script>
import clipSvg from '../../../icons/clip.svg';
import binSvg from '../../../icons/bin.svg';
import {sprintf} from 'sprintf-js';

export default {
  name: "fileInput",
  components: {clipSvg, binSvg},
  props: {
    error: String,
    value: {
      type: [String, File],
      default: '',
    },
    label: {
      type: String,
      default: 'Add file',
    },
  },
  data() {
    return {
      hintTemplate: 'Files %s and less than %d Mb',
      fileTypes: ['.pdf', '.rtf', '.doc', '.docx'],
      maxSize: 2,
      dragActive: false,
      errorMessage: null,
      messages: {
        size: 'File larger than %d mb',
        ext: 'File extension is invalid.',
      }
    }
  },
  computed: {
    file: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    hint() {
      return sprintf(this.hintTemplate, this.fileTypes.join(', '), this.maxSize);
    },
    innerError() {
      return this.error || this.errorMessage;
    }
  },
  methods: {
    onChange() {
      if (this.$refs.file.files.length) {
        let file = this.$refs.file.files[0];

        if (this.validateFile(file)) {
          this.file = this.$refs.file.files[0];
        }
      } else {
        this.file = null;
      }
    },
    checkFileSize(file) {
      let max = this.maxSize * 1048576;
      return file.size <= max;
    },
    remove() {
      this.file = null;
    },
    dragover(event) {
      event.preventDefault();

      if (!this.dragActive) {
        this.dragActive = true;
      }
    },
    drop(event) {
      event.preventDefault();


      if (event.dataTransfer.files.length) {
        let file = event.dataTransfer.files[0];

        if (this.validateFile(file)) {
          let dt = new DataTransfer();
          dt.items.add(file);
          this.$refs.file.files = dt.files;

          this.onChange();
        }
      }

      // Clean up
      this.dragActive = false;
    },
    validateFile(file) {
      let mimeTypes = this.fileTypes.map(item => 'application/' + item.slice(1));
      if (this.fileTypes.includes('.doc')) {
        mimeTypes.push('application/msword');
      }

      if (mimeTypes.includes(file.type)) {
        if (this.checkFileSize(file)) {
          this.errorMessage = null;
          return true;
        } else {
          this.errorMessage = sprintf(this.messages.size, this.maxSize);
        }
      } else {
        this.errorMessage = this.messages.ext;
      }

      this.file = null;
      return false;
    }
  },
}
</script>

<style lang="scss" scoped>
.file-input {

  &__wrapper {
    border: 1px dashed #767676;
    padding: 20px;
    min-height: 86px;
    display: flex;
    background: #000000;
    transition: 0.4s;

    &.drag-active {
      background: #343435;
    }
  }

  &__inner {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__chosen {
    flex: 1;
    //padding: 20px 66px 20px 20px;
    //padding: 27px 66px 20px 20px;
    padding-right: 46px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    font-size: 16px;
    line-height: 1;
    color: #ffffff;


    &__text {
      padding-top: 6px;
    }

    &__icon {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);

      cursor: pointer;
    }
  }

  &__label {
    font-weight: 600;
    font-size: 18px;
    color: #FEFD54;
    cursor: pointer;

    display: flex;
    align-items: center;
  }

  &__icon {
    line-height: 0;
    margin-right: 8px;
  }

  &__hint {
    font-size: 14px;
  }

  &__file {
    display: none;
  }

  &__error {
    font-size: 12px;
    color: #ff1744;
    margin-top: 3px;
  }
}
</style>

<style lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
