<template>
  <div class="popup__wrapper thank">
    <transition name="fade" mode="out-in">
      <div class="popup__overlay" @click.self="toggle" v-if="show">
        <div class="popup popup-form" :class="{'popup-success': success}">
          <div class="popup__close" @click="toggle"></div>

          <div class="popup__content">
            <!--suppress JSUnresolvedVariable -->
            <thank-you :title="options.thank_you.title"
                       :text="options.thank_you.subtitle"
                       :button="options.thank_you.button"
                       v-on:close="toggle"></thank-you>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ThankYou from "./thankYou";

export default {
  name: "popupThankYou",
  components: {ThankYou},
  data() {
    return {
      show: false,
      success: true,
    }
  },
  computed: {
    options() {
      return this.$store.state.options;
    },
  },
  mounted() {
    if (this.isContactPage()) {
      document.addEventListener('form_send', () => this.toggle());
    }
  },
  methods: {
    toggle() {
      console.log('toggle');
      this.show = !this.show;

      document.body.classList.toggle('over-hidden');
    },
    isContactPage() {
      return document.body.classList.contains('template-contacts');
    }
  },
}
</script>

<style lang="scss" scoped>
.popup {
  max-width: 600px;

  &__content {
    flex: 1;
  }
}
</style>
