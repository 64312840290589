var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isFrontPage && _vm.isDesktop
    ? _c(
        "nav",
        {
          staticClass: "scroll-navigation",
          class: _vm.direction,
          style: { opacity: _vm.opacity }
        },
        [
          _c("img", { attrs: { src: _vm.logo, alt: _vm.siteName } }),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "scroll-navigation__menu" },
            _vm._l(_vm.menu, function(item) {
              return _c("li", [
                _c(
                  "button",
                  {
                    class: { active: _vm.active === item.key },
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.scrollToSection(item.key)
                      }
                    }
                  },
                  [_vm._v(_vm._s(item.name) + "\n      ")]
                )
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "scroll-navigation__socials socials" },
            _vm._l(_vm.socials, function(item) {
              return _c(
                "a",
                { attrs: { href: item.link } },
                [_c("inline-svg", { attrs: { src: item.icon } })],
                1
              )
            }),
            0
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }