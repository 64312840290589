var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "140",
        height: "140",
        viewBox: "0 0 140 140",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("circle", {
        attrs: {
          cx: "70",
          cy: "70",
          r: "69.5",
          stroke: "#B6B6B6",
          "stroke-dasharray": "2 2"
        }
      }),
      _vm._v(" "),
      _c("circle", {
        attrs: {
          cx: "70",
          cy: "70",
          r: "69.5",
          stroke: "#C9C9C9",
          "stroke-dasharray": "2 2"
        }
      }),
      _vm._v(" "),
      _c("circle", {
        attrs: { cx: "70", cy: "70", r: "49", fill: "url(#paint0_radial)" }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "39",
          y: "49",
          width: "64",
          height: "42",
          fill: "url(#paint1_linear)"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M71.0002 79.397L100.699 49.6985H41.3018L71.0002 79.397Z",
          fill: "url(#paint2_linear)"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M14 67.2778L39 77L39 57.5555L14 67.2778Z",
          fill: "url(#paint3_linear)"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M25.1113 58.25L39.0002 64.5L39.0002 52L25.1113 58.25Z",
          fill: "url(#paint4_linear)"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M129 79.2778L104 89L104 69.5555L129 79.2778Z",
          fill: "url(#paint5_linear)"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M117.889 70.25L104 76.5L104 64L117.889 70.25Z",
          fill: "url(#paint6_linear)"
        }
      }),
      _vm._v(" "),
      _c(
        "defs",
        [
          _c(
            "radialGradient",
            {
              attrs: {
                id: "paint0_radial",
                cx: "0",
                cy: "0",
                r: "1",
                gradientUnits: "userSpaceOnUse",
                gradientTransform:
                  "translate(65.5 49) rotate(59.4) scale(54.0231 37.4755)"
              }
            },
            [
              _c("stop", { attrs: { "stop-color": "#363636" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#181818" } })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint1_linear",
                x1: "5.47619",
                y1: "70",
                x2: "103",
                y2: "70",
                gradientUnits: "userSpaceOnUse"
              }
            },
            [
              _c("stop", { attrs: { "stop-color": "#5B5B00" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#FFFD54" } })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint2_linear",
                x1: "40.5954",
                y1: "19.2929",
                x2: "85.8502",
                y2: "64.5477",
                gradientUnits: "userSpaceOnUse"
              }
            },
            [
              _c("stop", { attrs: { "stop-color": "#5B5B00" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#FFFD54" } })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint3_linear",
                x1: "-32.5617",
                y1: "67.2778",
                x2: "17.42",
                y2: "33.9967",
                gradientUnits: "userSpaceOnUse"
              }
            },
            [
              _c("stop", { attrs: { "stop-color": "white" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "white",
                  "stop-opacity": "0"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint4_linear",
                x1: "-0.756269",
                y1: "58.25",
                x2: "29.3528",
                y2: "40.924",
                gradientUnits: "userSpaceOnUse"
              }
            },
            [
              _c("stop", { attrs: { "stop-color": "white" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "white",
                  "stop-opacity": "0"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint5_linear",
                x1: "175.562",
                y1: "79.2778",
                x2: "125.58",
                y2: "45.9967",
                gradientUnits: "userSpaceOnUse"
              }
            },
            [
              _c("stop", { attrs: { "stop-color": "white" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "white",
                  "stop-opacity": "0"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint6_linear",
                x1: "143.756",
                y1: "70.25",
                x2: "113.647",
                y2: "52.924",
                gradientUnits: "userSpaceOnUse"
              }
            },
            [
              _c("stop", { attrs: { "stop-color": "white" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "white",
                  "stop-opacity": "0"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }