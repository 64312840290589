var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-container" }, [
    !this.transform
      ? _c("div", {
          ref: "original",
          staticClass: "original",
          domProps: { innerHTML: _vm._s(_vm.text) }
        })
      : _vm._e(),
    _vm._v(" "),
    _c("div", {
      key: _vm.windowWidth,
      ref: "svg",
      staticClass: "svg-container"
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }