var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "80",
        height: "80",
        viewBox: "0 0 80 80",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M77.9664 45.0265C77.3667 45.1146 76.7536 45.1612 76.1294 45.1612C70.3822 45.1612 65.5182 41.4016 63.8497 36.2088C63.5644 35.3212 62.8396 34.6377 61.9236 34.4634C52.3793 32.6462 45.1616 24.267 45.1616 14.1936C45.1616 9.96279 46.4388 6.03296 48.6233 2.75968C49.1427 1.9814 48.7093 0.944842 47.7915 0.763123C45.2715 0.264062 42.667 0 40.0004 0C17.909 0.00015625 0.000488281 17.9086 0.000488281 39.9999C0.000488281 62.0911 17.909 79.9998 40.0004 79.9998C59.9102 79.9998 76.3733 65.5074 79.4697 46.4674C79.6102 45.6035 78.8322 44.8994 77.9664 45.0265Z",
          fill: "#F5B97D"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M77.9664 45.0265C77.3667 45.1146 76.7536 45.1612 76.1294 45.1612C70.3822 45.1612 65.5182 41.4016 63.8497 36.2088C63.5644 35.3212 62.8396 34.6377 61.9236 34.4634C52.3793 32.6462 45.1616 24.267 45.1616 14.1936C45.1616 9.96279 46.4388 6.03296 48.6233 2.75968C49.1427 1.9814 48.7093 0.944842 47.7915 0.763123C45.2715 0.264062 42.667 0 40.0004 0C17.909 0.00015625 0.000488281 17.9086 0.000488281 39.9999C0.000488281 62.0911 17.909 79.9998 40.0004 79.9998C59.9102 79.9998 76.3733 65.5074 79.4697 46.4674C79.6102 45.6035 78.8322 44.8994 77.9664 45.0265Z",
          fill: "#E8E621"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M77.9664 45.0265C77.3667 45.1146 76.7536 45.1612 76.1294 45.1612C70.3822 45.1612 65.5182 41.4016 63.8497 36.2088C63.5644 35.3212 62.8396 34.6377 61.9236 34.4634C52.3793 32.6462 45.1616 24.267 45.1616 14.1936C45.1616 9.96279 46.4388 6.03296 48.6233 2.75968C49.1427 1.9814 48.7093 0.944842 47.7915 0.763123C45.2715 0.264062 42.667 0 40.0004 0C17.909 0.00015625 0.000488281 17.9086 0.000488281 39.9999C0.000488281 62.0911 17.909 79.9998 40.0004 79.9998C59.9102 79.9998 76.3733 65.5074 79.4697 46.4674C79.6102 45.6035 78.8322 44.8994 77.9664 45.0265Z",
          fill: "#E8D421"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M21.9358 32.2577C24.7861 32.2577 27.0968 29.9471 27.0968 27.0968C27.0968 24.2465 24.7861 21.9359 21.9358 21.9359C19.0855 21.9359 16.7749 24.2465 16.7749 27.0968C16.7749 29.9471 19.0855 32.2577 21.9358 32.2577Z",
          fill: "url(#paint0_linear)"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M24.5155 56.7739C26.6533 56.7739 28.3864 55.0409 28.3864 52.903C28.3864 50.7651 26.6533 49.0321 24.5155 49.0321C22.3776 49.0321 20.6445 50.7651 20.6445 52.903C20.6445 55.0409 22.3776 56.7739 24.5155 56.7739Z",
          fill: "url(#paint1_linear)"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M63.2269 52.9032C62.7531 52.9032 62.2981 52.6424 62.0714 52.1899L60.7811 49.6093C60.4623 48.9716 60.7206 48.1968 61.3583 47.8779C61.9947 47.5604 62.7695 47.8174 63.0897 48.4551L64.38 51.0357C64.6987 51.6733 64.4404 52.4482 63.8028 52.7671C63.6175 52.8591 63.4209 52.9032 63.2269 52.9032Z",
          fill: "#E8D421"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M49.0332 45.1612C48.5595 45.1612 48.1045 44.9004 47.8778 44.4479C47.559 43.8102 47.8173 43.0354 48.4549 42.7165L51.0356 41.4262C51.6732 41.1099 52.4468 41.3657 52.767 42.0033C53.0857 42.641 52.8274 43.4158 52.1898 43.7348L49.6092 45.0251C49.4239 45.1171 49.2273 45.1612 49.0332 45.1612Z",
          fill: "url(#paint2_linear)"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M14.1946 42.5805C13.7209 42.5805 13.2659 42.3197 13.0392 41.8672L11.7489 39.2866C11.4301 38.649 11.6884 37.8741 12.326 37.5552C12.9624 37.239 13.7373 37.4948 14.0574 38.1324L15.3478 40.713C15.6665 41.3507 15.4082 42.1255 14.7706 42.4444C14.5853 42.5365 14.3887 42.5805 14.1946 42.5805Z",
          fill: "url(#paint3_linear)"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M30.9688 65.8063C30.495 65.8063 30.04 65.5455 29.8133 65.093C29.4946 64.4553 29.7528 63.6805 30.3905 63.3616L32.9711 62.0713C33.6088 61.7538 34.3824 62.0108 34.7025 62.6485C35.0213 63.2861 34.763 64.061 34.1253 64.3799L31.5447 65.6702C31.3594 65.7622 31.1628 65.8063 30.9688 65.8063Z",
          fill: "url(#paint4_linear)"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M33.548 15.4839C33.2178 15.4839 32.8877 15.358 32.6356 15.1059L31.3453 13.8156C30.8413 13.3115 30.8413 12.495 31.3453 11.9911C31.8494 11.487 32.666 11.487 33.1699 11.9911L34.4602 13.2812C34.9642 13.7853 34.9642 14.6019 34.4602 15.1058C34.2083 15.358 33.8781 15.4839 33.548 15.4839Z",
          fill: "url(#paint5_linear)"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M30.9679 45.1612C30.6377 45.1612 30.3076 45.0352 30.0556 44.7832L28.7653 43.4929C28.2612 42.9888 28.2612 42.1723 28.7653 41.6684C29.2693 41.1643 30.0859 41.1643 30.5898 41.6684L31.8801 42.9587C32.3842 43.4627 32.3842 44.2793 31.8801 44.7832C31.6281 45.0352 31.2981 45.1612 30.9679 45.1612Z",
          fill: "#E8D421"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M45.1614 72.258C44.4481 72.258 43.8711 71.6808 43.8711 70.9677V69.6774C43.8711 68.9641 44.4483 68.3871 45.1614 68.3871C45.8745 68.3871 46.4517 68.9642 46.4517 69.6774V70.9677C46.4517 71.6808 45.8747 72.258 45.1614 72.258Z",
          fill: "#E8D421"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M37.4193 23.2259C37.0891 23.2259 36.759 23.1 36.507 22.848C36.0029 22.3439 36.0029 21.5273 36.507 21.0234L37.7973 19.7331C38.3013 19.2291 39.1179 19.2291 39.6218 19.7331C40.1259 20.2372 40.1259 21.0537 39.6218 21.5576L38.3315 22.848C38.0796 23.0998 37.7494 23.2259 37.4193 23.2259Z",
          fill: "#E8D421"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M56.6155 10.4586L56.2454 12.6797C55.9411 14.5051 56.9716 16.2908 58.7044 16.9406L61.9818 18.1697C64.1466 18.9814 66.5357 17.727 67.0963 15.484L68.2646 10.8112C68.9927 7.8989 66.2722 5.30203 63.3969 6.16468L59.3215 7.38734C57.9077 7.81124 56.8582 9.00249 56.6155 10.4586Z",
          fill: "#E8D421"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M69.6772 24.5162L68.1189 25.2954C66.8967 25.9065 66.3628 27.3643 66.9011 28.6201L68.1548 31.5454C68.9139 33.3165 71.2758 33.6612 72.5094 32.1809L74.5262 29.7606C75.4719 28.6257 75.2739 26.9303 74.0922 26.044L72.38 24.7598C71.5978 24.1734 70.5516 24.079 69.6772 24.5162Z",
          fill: "#E8D421"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M77.4185 19.3556C78.8437 19.3556 79.9991 18.2002 79.9991 16.775C79.9991 15.3497 78.8437 14.1944 77.4185 14.1944C75.9933 14.1944 74.8379 15.3497 74.8379 16.775C74.8379 18.2002 75.9933 19.3556 77.4185 19.3556Z",
          fill: "#E8D421"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M44.8386 75.1611C22.7473 75.1611 4.83874 57.2526 4.83874 35.1612C4.83874 25.3475 8.37358 16.3592 14.2384 9.39969C5.53155 16.7373 0 27.7223 0 39.9999C0 62.0911 17.9086 79.9998 39.9999 79.9998C52.2852 79.9998 63.2577 74.4819 70.5878 65.7824C63.636 71.6374 54.656 75.1611 44.8386 75.1611Z",
          fill: "#ACAA00"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M62.5558 15.1748C60.3914 14.3631 59.1043 12.133 59.4843 9.85296L59.9255 7.20593L59.3216 7.38718C57.9079 7.8114 56.8583 9.00265 56.6155 10.4586L56.2454 12.6797C55.9411 14.5051 56.9716 16.2908 58.7044 16.9406L61.9818 18.1697C63.7199 18.8214 65.6013 18.1428 66.5661 16.6884L62.5558 15.1748Z",
          fill: "url(#paint6_linear)"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M78.8706 17.9033C77.4454 17.9033 76.2899 16.7478 76.2899 15.3227C76.2899 14.9883 76.3542 14.6667 76.4699 14.3736C75.5132 14.7513 74.8384 15.6834 74.8384 16.7744C74.8384 18.1995 75.9938 19.355 77.419 19.355C78.5099 19.355 79.442 18.6801 79.8196 17.7234C79.5263 17.8391 79.2049 17.9033 78.8706 17.9033Z",
          fill: "url(#paint7_linear)"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M70.9081 29.8826L69.4881 26.5695C69.2214 25.9473 69.1992 25.2847 69.3608 24.6743L68.1189 25.2953C66.8967 25.9064 66.3628 27.3642 66.9011 28.6201L68.1548 31.5454C68.9139 33.3165 71.2758 33.6612 72.5092 32.181L73.0105 31.5795C72.1237 31.3982 71.313 30.8271 70.9081 29.8826Z",
          fill: "url(#paint8_linear)"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M24.6777 29.5162C21.8272 29.5162 19.5164 27.2054 19.5164 24.355C19.5164 23.6411 19.6616 22.9612 19.9236 22.3428C18.0728 23.1271 16.7744 24.9601 16.7744 27.0968C16.7744 29.9473 19.0852 32.2581 21.9357 32.2581C24.0722 32.2581 25.9053 30.9596 26.6895 29.1089C26.0713 29.3709 25.3914 29.5162 24.6777 29.5162Z",
          fill: "url(#paint9_linear)"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M26.5325 54.758C24.3947 54.758 22.6616 53.0249 22.6616 50.8871C22.6616 50.3411 22.7772 49.823 22.9813 49.3519C21.608 49.9466 20.6455 51.3113 20.6455 52.9031C20.6455 55.0409 22.3786 56.7741 24.5164 56.7741C26.1083 56.7741 27.473 55.8116 28.0677 54.4383C27.5966 54.6422 27.0785 54.758 26.5325 54.758Z",
          fill: "url(#paint10_linear)"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M49.0325 61.9358C51.8828 61.9358 54.1934 59.6252 54.1934 56.7749C54.1934 53.9246 51.8828 51.614 49.0325 51.614C46.1822 51.614 43.8716 53.9246 43.8716 56.7749C43.8716 59.6252 46.1822 61.9358 49.0325 61.9358Z",
          fill: "url(#paint11_linear)"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M51.7739 59.1935C48.9234 59.1935 46.6126 56.8827 46.6126 54.0322C46.6126 53.3183 46.7578 52.6385 47.0198 52.0201C45.169 52.8044 43.8706 54.6374 43.8706 56.7741C43.8706 59.6246 46.1814 61.9353 49.0318 61.9353C51.1684 61.9353 53.0015 60.6369 53.7857 58.7861C53.1676 59.0482 52.4876 59.1935 51.7739 59.1935Z",
          fill: "url(#paint12_linear)"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M38.6563 36.129C40.0815 36.129 41.2369 34.9736 41.2369 33.5484C41.2369 32.1231 40.0815 30.9677 38.6563 30.9677C37.2311 30.9677 36.0757 32.1231 36.0757 33.5484C36.0757 34.9736 37.2311 36.129 38.6563 36.129Z",
          fill: "url(#paint13_linear)"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M40.0268 34.758C38.6014 34.758 37.4461 33.6026 37.4461 32.1774C37.4461 31.8204 37.5188 31.4805 37.6497 31.1713C36.7244 31.5635 36.0752 32.4801 36.0752 33.5484C36.0752 34.9735 37.2305 36.129 38.6558 36.129C39.7241 36.129 40.6407 35.4798 41.0328 34.5544C40.7236 34.6854 40.3836 34.758 40.0268 34.758Z",
          fill: "url(#paint14_linear)"
        }
      }),
      _vm._v(" "),
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint0_linear",
                x1: "21.9358",
                y1: "21.9359",
                x2: "21.9358",
                y2: "32.2577",
                gradientUnits: "userSpaceOnUse"
              }
            },
            [
              _c("stop", { attrs: { "stop-color": "white" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "white",
                  "stop-opacity": "0"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint1_linear",
                x1: "24.5155",
                y1: "49.0321",
                x2: "24.5155",
                y2: "56.7739",
                gradientUnits: "userSpaceOnUse"
              }
            },
            [
              _c("stop", { attrs: { "stop-color": "white" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "white",
                  "stop-opacity": "0"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint2_linear",
                x1: "50.3224",
                y1: "41.2907",
                x2: "50.3224",
                y2: "45.1612",
                gradientUnits: "userSpaceOnUse"
              }
            },
            [
              _c("stop", { attrs: { "stop-color": "#444300" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "#444300",
                  "stop-opacity": "0"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint3_linear",
                x1: "13.5483",
                y1: "37.4198",
                x2: "13.5483",
                y2: "42.5805",
                gradientUnits: "userSpaceOnUse"
              }
            },
            [
              _c("stop", { attrs: { "stop-color": "#444300" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "#444300",
                  "stop-opacity": "0"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint4_linear",
                x1: "32.2579",
                y1: "61.9353",
                x2: "32.2579",
                y2: "65.8063",
                gradientUnits: "userSpaceOnUse"
              }
            },
            [
              _c("stop", { attrs: { "stop-color": "#444300" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "#444300",
                  "stop-opacity": "0"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint5_linear",
                x1: "32.9027",
                y1: "11.613",
                x2: "32.9027",
                y2: "15.4839",
                gradientUnits: "userSpaceOnUse"
              }
            },
            [
              _c("stop", { attrs: { "stop-color": "#444300" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "#444300",
                  "stop-opacity": "0"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint6_linear",
                x1: "61.3793",
                y1: "7.20593",
                x2: "61.3793",
                y2: "18.418",
                gradientUnits: "userSpaceOnUse"
              }
            },
            [
              _c("stop", { attrs: { "stop-color": "#444300" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "#444300",
                  "stop-opacity": "0"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint7_linear",
                x1: "77.329",
                y1: "14.3736",
                x2: "77.329",
                y2: "19.355",
                gradientUnits: "userSpaceOnUse"
              }
            },
            [
              _c("stop", { attrs: { "stop-color": "#444300" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "#444300",
                  "stop-opacity": "0"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint8_linear",
                x1: "69.8512",
                y1: "24.6743",
                x2: "69.8512",
                y2: "33.11",
                gradientUnits: "userSpaceOnUse"
              }
            },
            [
              _c("stop", { attrs: { "stop-color": "#444300" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "#444300",
                  "stop-opacity": "0"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint9_linear",
                x1: "21.732",
                y1: "22.3428",
                x2: "21.732",
                y2: "32.2581",
                gradientUnits: "userSpaceOnUse"
              }
            },
            [
              _c("stop", { attrs: { "stop-color": "#444300" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "#444300",
                  "stop-opacity": "0"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint10_linear",
                x1: "24.3566",
                y1: "49.3519",
                x2: "24.3566",
                y2: "56.7741",
                gradientUnits: "userSpaceOnUse"
              }
            },
            [
              _c("stop", { attrs: { "stop-color": "#444300" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "#444300",
                  "stop-opacity": "0"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint11_linear",
                x1: "49.0325",
                y1: "51.614",
                x2: "49.0325",
                y2: "61.9358",
                gradientUnits: "userSpaceOnUse"
              }
            },
            [
              _c("stop", { attrs: { "stop-color": "white" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "white",
                  "stop-opacity": "0"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint12_linear",
                x1: "48.8282",
                y1: "52.0201",
                x2: "48.8282",
                y2: "61.9353",
                gradientUnits: "userSpaceOnUse"
              }
            },
            [
              _c("stop", { attrs: { "stop-color": "#444300" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "#444300",
                  "stop-opacity": "0"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint13_linear",
                x1: "38.6563",
                y1: "30.9677",
                x2: "38.6563",
                y2: "36.129",
                gradientUnits: "userSpaceOnUse"
              }
            },
            [
              _c("stop", { attrs: { "stop-color": "white" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "white",
                  "stop-opacity": "0"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint14_linear",
                x1: "38.554",
                y1: "31.1713",
                x2: "38.554",
                y2: "36.129",
                gradientUnits: "userSpaceOnUse"
              }
            },
            [
              _c("stop", { attrs: { "stop-color": "#444300" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "#444300",
                  "stop-opacity": "0"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }