var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup__wrapper thank" },
    [
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _vm.show
          ? _c(
              "div",
              {
                staticClass: "popup__overlay",
                on: {
                  click: function($event) {
                    if ($event.target !== $event.currentTarget) {
                      return null
                    }
                    return _vm.toggle.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "popup popup-form",
                    class: { "popup-success": _vm.success }
                  },
                  [
                    _c("div", {
                      staticClass: "popup__close",
                      on: { click: _vm.toggle }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "popup__content" },
                      [
                        _c("thank-you", {
                          attrs: {
                            title: _vm.options.thank_you.title,
                            text: _vm.options.thank_you.subtitle,
                            button: _vm.options.thank_you.button
                          },
                          on: { close: _vm.toggle }
                        })
                      ],
                      1
                    )
                  ]
                )
              ]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }