var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "12",
        height: "19",
        viewBox: "0 0 12 19",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M6 19C2.69121 19 0 16.3368 0 13.0625V4.75C0 4.31223 0.358447 3.95838 0.799951 3.95838C1.2416 3.95838 1.60005 4.31223 1.60005 4.75V13.0625C1.60005 15.4629 3.57363 17.4166 6 17.4166C8.42637 17.4166 10.4 15.4629 10.4 13.0625V4.35412C10.4 2.82626 9.14399 1.58338 7.60005 1.58338C6.05596 1.58338 4.8 2.82626 4.8 4.35412V12.2709C4.8 12.9255 5.33833 13.4584 6 13.4584C6.66167 13.4584 7.2 12.9255 7.2 12.2709V4.75C7.2 4.31223 7.55845 3.95838 7.99995 3.95838C8.4416 3.95838 8.80005 4.31223 8.80005 4.75V12.2709C8.80005 13.7987 7.54395 15.0416 6 15.0416C4.45605 15.0416 3.19995 13.7987 3.19995 12.2709V4.35412C3.19995 1.9539 5.17354 0 7.60005 0C10.0264 0 12 1.9539 12 4.35412V13.0625C12 16.3368 9.30879 19 6 19Z",
          fill: "#FEFD54"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }