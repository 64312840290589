var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.cookies.enable
    ? _c(
        "div",
        { staticClass: "popup__wrapper" },
        [
          _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
            _vm.show
              ? _c(
                  "div",
                  {
                    staticClass: "popup__overlay",
                    on: {
                      click: function($event) {
                        if ($event.target !== $event.currentTarget) {
                          return null
                        }
                        return _vm.toggle.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "popup" }, [
                      _c("div", { staticClass: "container" }, [
                        _c("div", { staticClass: "popup__container" }, [
                          _c("div", {
                            staticClass: "popup__close",
                            on: { click: _vm.toggle }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "popup__icon" },
                            [_c("cookie-svg")],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "popup__content" }, [
                            _c("div", { staticClass: "popup__title" }, [
                              _vm._v(_vm._s(_vm.cookies.title))
                            ]),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "popup__text",
                              domProps: { innerHTML: _vm._s(_vm.cookies.text) }
                            })
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "popup__buttons" }, [
                            _c(
                              "button",
                              { staticClass: "btn", on: { click: _vm.accept } },
                              [_vm._v(_vm._s("Accept"))]
                            )
                          ])
                        ])
                      ])
                    ])
                  ]
                )
              : _vm._e()
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }