<!--suppress JSUnresolvedVariable -->
<template>
  <div class="popup__wrapper">
    <transition name="fade" mode="out-in">
      <div class="popup__overlay" @click.self="toggle" v-if="show">
        <div class="popup popup-form" :class="{'popup-success': success}">
          <div class="popup__close" @click="toggle"></div>

          <div class="popup__content">
            <transition name="fade" mode="out-in">
              <div class="popup__form" v-if="!success">
                <contact-form v-on:success="onSuccess"
                              :title="options.form.popup_title"
                              :fields="fields"
                              :accept="accept"
                              hide-text></contact-form>
              </div>

              <thank-you :title="options.thank_you.title"
                         :text="options.thank_you.subtitle"
                         :button="options.thank_you.button"
                         v-on:close="toggle"
                         v-else></thank-you>
            </transition>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ContactForm from "./contactForm";
import ThankYou from "./thankYou";

export default {
  name: "popupForm",
  components: {ThankYou, ContactForm},
  data() {
    return {
      show: false,
      success: false,
      fields: ['name', 'phone', 'email', 'message', 'file'],
    }
  },
  computed: {
    options() {
      return this.$store.state.options;
    },
    accept() {
      return {
        subject: this.options.form.popup_title,
        page: window.location.href,
      }
    },
  },
  mounted() {
    let buttons = document.querySelectorAll('[data-popup=apply-form]');

    buttons.forEach(button => {
      button.addEventListener('click', () => this.toggle());
    });
  },
  methods: {
    toggle() {
      this.show = !this.show;
      this.success = false;

      document.body.classList.toggle('over-hidden');
    },
    onSuccess() {
      this.success = true;

      // setTimeout(this.toggle, 2000);
    },
  },
}
</script>

<style lang="scss" scoped>
.popup {
  //max-width: 540px;
  max-width: 600px;

  &__content {
    flex: 1;
  }

  &__form {
    padding: 50px 88px;
  }
}
</style>
<style lang="scss">
.popup-form {
  .contact-form {
    &__title {
      text-align: center;
    }

    &__button {
      justify-content: center;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
