import Vue from 'vue';

Vue.directive('click-outside', {
  /* el, binding, vNode */
  bind: function (el, binding, vNode) {
    el.clickOutsideEvent = function (event) {
      // document.addEventListener('click', (e) => {
      let path = event.path || event.composedPath();
      let inPath = false;

      path.forEach(item => {
        if (item === el) {
          inPath = true;
        }
      });

      if (!inPath) {
        if (typeof binding.value === 'function') {
          binding.value.bind(vNode.context)(event);
        } else {
          let beg = ["'", '`', '"'].includes(binding.expression[0]) ? 1 : 0;
          let count = ["'", '`', '"'].includes(binding.expression[binding.expression.length - 1])
            ? binding.expression.length - 1 : binding.expression.length;

          let expression = binding.expression.substring(beg, count);
          new Function(expression).bind(vNode.context)(event);
        }
      }
    };

    // noinspection JSUnresolvedVariable
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind: function (el) {
    // noinspection JSUnresolvedVariable
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});


// Vue.directive('viewport', {
//   /* el, binding, vnode */
//   inserted: function (el, binding, vnode) {
//   // update: function (el, binding, vnode) {
//     let elementWatcher = scrollMonitor.create(el);
//     vnode.data.watcher = elementWatcher;
//
//     let callback = () => {
//       // console.log('in view', el);
//       if (typeof binding.value === 'function') {
//         binding.value.bind(vnode.context)(el, vnode);
//         if (binding.modifiers.once) {
//           elementWatcher.destroy();
//         }
//       }
//     };
//
//     let arg = binding.arg ? binding.arg : 'enter';
//
//     switch (arg) {
//       case 'partExit':
//         elementWatcher.partiallyExitViewport(callback);
//         break;
//       case 'inOut':
//         elementWatcher.visibilityChange(callback);
//         break;
//       case 'fully':
//         elementWatcher.fullyEnterViewport(callback);
//         break;
//       case 'exit':
//         elementWatcher.exitViewport(callback);
//         break;
//       case 'enter':
//       default:
//         elementWatcher.enterViewport(callback);
//         break;
//     }
//   },
//   unbind(el, binding, vnode) {
//     let watcher = vnode.data.watcher;
//     if ( watcher ) {
//       watcher.destroy();
//     }
//   }
// });

