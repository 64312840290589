<template>
  <div class="cm-select"
       :class="{active: active, filled: value && value.length, error: error}"
       v-click-outside="()=>this.active = false">
    <div class="cm-select__inner" @click.stop="active = !active">
      <div class="cm-select__label" v-if="label">{{ label }}</div>
      <input type="text" ref="input" class="cm-select__input" :value="value" readonly />
      <span class="cm-select__tick">
        <angle-svg></angle-svg>
      </span>
    </div>
    <div class="cm-select__error" v-if="error">{{ error }}</div>

    <transition name="slide">
      <div class="cm-select__items" v-if="active">
        <div class="cm-select__item"
             v-for="item in items"
             @click="setValue(item)">{{ item }}</div>
      </div>
    </transition>
  </div>
</template>

<script>
import angleSvg from '../../../icons/angle.svg';

export default {
  name: "cmSelect",
  components: {angleSvg},
  props: {
    label: String,
    error: String,
    value: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: function () {
        return [];
      },
    }
  },
  data() {
    return {
      active: false,
    }
  },
  watch: {
    active(value) {
      this.$emit('active', value);
    },
  },
  methods: {
    setValue(val) {
      this.active = false;
      this.$emit('input', val);
      this.$emit('change');
    },
  },
}
</script>

<style lang="scss" scoped>
.cm-select {
  position: relative;
  margin: 4px 0 24px;

  &__inner {
    width: 100%;
    min-height: 48px;
    padding-top: 16px;
    display: flex;
    position: relative;

    &:after, &:before {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 1;
      transition: border .3s cubic-bezier(.4, 0, .2, 1), opacity .3s cubic-bezier(.4, 0, .2, 1), transform 0s cubic-bezier(.4, 0, .2, 1) .3s;
      will-change: border, opacity, transform;
      content: " ";
    }

    &:before {
      height: 2px;
      z-index: 2;
      opacity: 0;
      transform: scaleX(.12);
    }

    &:after {
      height: 1px;

      background-color: rgba(255, 255, 255, 0.7);
    }
  }

  &__label {
    position: absolute;
    top: 23px;
    left: 0;
    pointer-events: none;
    transition: .4s cubic-bezier(.25, .8, .25, 1);
    transition-duration: .3s;
    font-size: 16px;
    line-height: 20px;
  }

  &__input {
    height: 32px;
    padding: 0;
    display: block;
    flex: 1;
    border: none;
    background: none;
    outline: none;

    color: white;
  }

  &__error {
    position: absolute;
    height: 20px;
    bottom: -22px;
    font-size: 12px;
    transition: .3s cubic-bezier(.4,0,.2,1);

    left: 0;
    opacity: 0;
    transform: translate3d(0,-8px,0);
  }

  &__tick {
    position: absolute;
    bottom: 2px;
    right: 5px;
    z-index: 2;
    transition: .4s cubic-bezier(.25, .8, .25, 1);
    transform: rotate(180deg);

    svg {
      width: 15px;
      height: auto;
    }
  }

  &__items {
    background: linear-gradient(0deg, #262626, #262626), #666666;
    box-shadow: 0 4px 34px rgba(0, 0, 0, 0.55);

    padding: 22px 20px;
    display: flex;
    flex-wrap: wrap;

    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 9;

    transform-origin: top;
    transition: transform .4s ease-in-out;
  }

  &__item {
    border: 1px solid #7C7C7C;
    border-radius: 60px;
    padding: 6px 16px;

    //font-size: 20px;
    font-size: 14px;
    line-height: 1.5;
    color: white;
    cursor: pointer;

    transition: .4s cubic-bezier(.25, .8, .25, 1);

    &:not(:last-child) {
      margin-right: 12px;
    }

    &:hover {
      background-color: black;
      border-color: black;
    }
  }

  &.active {
    .cm-select__tick {
      transform: rotate(0);
    }

    .cm-select__inner {
      &:before {
        opacity: 1;
        transform: scaleX(1);
        transition: .3s cubic-bezier(.4, 0, .2, 1);
        transition-property: border, opacity, transform;

        background-color: var(--md-theme-custom-primary, #FEFD54);
      }
    }
  }

  &.filled {
    .cm-select__label {
      pointer-events: auto;
      top: 0;
      opacity: 1;
      font-size: 12px;

      color: var(--md-theme-custom-primary, #FEFD54);
    }
  }

  &.error {
    .cm-select__label {
      color: var(--md-theme-custom-fieldvariant, #ff1744);
    }

    .cm-select__inner {
      &:after {
        background: var(--md-theme-custom-fieldvariant, #ff1744);
      }
    }

    .cm-select__error {
      opacity: 1;
      transform: translateZ(0);
      color: var(--md-theme-custom-fieldvariant, #ff1744);
    }
  }
}
</style>
<style lang="scss">
.slide-enter, .slide-leave-to{
  transform: scaleY(0);
}
</style>
