var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "file-input" }, [
    _c(
      "div",
      {
        staticClass: "file-input__wrapper",
        class: { "drag-active": _vm.dragActive },
        on: {
          dragover: _vm.dragover,
          dragleave: function($event) {
            _vm.dragActive = false
          },
          drop: _vm.drop
        }
      },
      [
        _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
          !_vm.file
            ? _c("div", { staticClass: "file-input__inner" }, [
                _c(
                  "div",
                  {
                    staticClass: "file-input__label",
                    on: {
                      click: function($event) {
                        return _vm.$refs.file.click()
                      }
                    }
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "file-input__icon" },
                      [_c("clip-svg")],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.label))])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "file-input__hint" }, [
                  _vm._v(_vm._s(_vm.hint))
                ])
              ])
            : _c("div", { staticClass: "file-input__chosen" }, [
                _c("span", { staticClass: "file-input__chosen__text" }, [
                  _vm._v(_vm._s(_vm.file.name))
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "file-input__chosen__icon",
                    on: { click: _vm.remove }
                  },
                  [_c("bin-svg")],
                  1
                )
              ])
        ])
      ],
      1
    ),
    _vm._v(" "),
    _vm.innerError
      ? _c("div", { staticClass: "file-input__error" }, [
          _vm._v(_vm._s(_vm.innerError))
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("input", {
      ref: "file",
      staticClass: "file-input__file",
      attrs: { type: "file", accept: _vm.fileTypes.join(", ") },
      on: { change: _vm.onChange }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }