<template>
  <div class="text-container">
    <div class="original" ref="original" v-html="text" v-if="!this.transform"></div>
    <div class="svg-container" :key="windowWidth" ref="svg"></div>
  </div>
</template>

<!--suppress ES6CheckImport -->
<script>
import { SVG } from '@svgdotjs/svg.js';


export default {
  name: "svgText",
  props: ['title'],
  data() {
    return {
      init: false,
      transform: false,
      windowWidth: window.innerWidth,
    }
  },
  computed: {
    text() {
      return this.title || this.$parent.title;
    }
  },
  mounted() {
    if (this.text) {
      this.$nextTick(() => {
        this.build();
      });
    }

    window.addEventListener('resize', this.rebuild);
  },
  methods: {
    build() {
      // noinspection JSUnresolvedVariable
      let texts = this.text.replace(' <br />', '').split("\n"),
        width = this.$el.offsetWidth,
        height = this.$el.offsetHeight,
        svg = this.$refs.svg;


      let fontSize = window.getComputedStyle(svg, null).getPropertyValue('font-size');
      let fontFamily = window.getComputedStyle(svg, null).getPropertyValue('font-family');
      fontSize = parseFloat(fontSize);

      // let draw = SVG(svg).size(width + 30, height);
      svg.innerHTML = '';
      let draw = SVG().addTo(svg).size(width + 30, height);

      let text = draw.text((add) => {
        texts.forEach((item) => {
          add.tspan(item).newLine();
        });
      });

      text.move(0,0);
      // text.move(0,height);
      // text.move(0,20);
      // text.move(0,20).font({ family: 'MonumentExtended' });
      text.font({
        family: fontFamily,
        size: fontSize,
        leading: 0.9,
      });

      text.attr({
        fill: 'none',
        stroke: '#ffffff',
        // 'stroke-dasharray': '0px, 11px',
      });

      this.transform = true;
    },
    rebuild() {
      this.windowWidth = window.innerWidth;
      this.transform = false

      this.$nextTick(() => {
        this.build();
      });
    }
  },
}
</script>

<style lang="scss" scoped>
.original {
  display: inline-block;
}

.text-container {
  &, .svg-container {
    display: inline-flex;
  }
}
</style>
