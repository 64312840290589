<template>

</template>

<script>
export default {
  name: "dragGallery",
  data() {
    return {
      slider: null,
      isDown: false,
      startX: 0,
      target: ".projects-section__items",
      scrollLeft: 0,
    }
  },
  created() {
    this.slider = document.querySelector(this.target);

    if (this.slider) {
      this.setEvents();
    }
  },
  methods: {
    setEvents() {
      this.slider.addEventListener("mousedown", (e) => this.mouseDown(e));
      this.slider.addEventListener("mouseup", (e) => this.mouseUp(e));
      this.slider.addEventListener("mousemove", (e) => this.mouseMove(e));
      this.slider.addEventListener("mouseleave", () => this.mouseLeave());
    },
    mouseDown(e) {
      this.isDown = true;
      this.slider.classList.add("active");
      this.startX = e.pageX - this.slider.offsetLeft;
      this.scrollLeft = this.slider.scrollLeft;
    },
    mouseUp(e) {
      this.isDown = false;
      this.slider.classList.remove("active");
    },
    mouseMove(e) {
      if (!this.isDown) return;
      e.preventDefault();

      let x = e.pageX - this.slider.offsetLeft;
      const walk = x - this.startX;
      this.slider.scrollLeft = this.scrollLeft - walk;
    },
    mouseLeave() {
      this.isDown = false;
      this.slider.classList.remove("active");
    },
  },
}
</script>

<style scoped>

</style>
