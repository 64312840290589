var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "22",
        height: "22",
        viewBox: "0 0 22 22",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("circle", { attrs: { cx: "11", cy: "11", r: "11", fill: "#FEFD54" } }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M15.5606 8L9.65281 13.3789L6.43941 10.4532L5 11.7637L9.65281 16L17 9.31057L15.5606 8Z",
          fill: "black"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }