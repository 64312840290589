/* eslint-disable */
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import axios from 'axios';
import * as qs from 'qs';

/* global SeoPlugin */
// noinspection JSUnresolvedVariable
export default new Vuex.Store({
  state: {
    ajaxUrl: window ? window.ajaxurl : global.ajaxurl,
    options: window.siteOptions ? window.siteOptions : {
      small_logo: '',
    },
    isFrontPage: window.isFrontPage || false,
    siteUrl: window.siteUrl || window.location.origin,
    siteName: window.siteName || '',
    fields: window.fields || {},
  },
  mutations: {
    setter(state, object) {
      Object.entries(object).forEach(([key, value]) => {
        Vue.set(state, key, value);
      });
    },
    setGlobal(state, object) {
      Object.entries(object).forEach(([key, value]) => {
        Vue.set(state.globalData, key, value);
      });
    }
  },
  actions: {
    sendRequest({commit, state}, requestBody) {
      let options = {};
      if (requestBody.options) {
        options = requestBody.options;
        requestBody = requestBody.data;
      } else {
        requestBody = qs.stringify(requestBody);
      }
      return axios.post(state.ajaxUrl, requestBody, options);
    },
    setupData({state, commit, dispatch}, element) {
      Object.keys(state).forEach(key => {
        if (element.dataset.hasOwnProperty(key)) {
          let data = {};

          try {
            data[key] = JSON.parse(element.dataset[key]);
          } catch (e) {
            data[key] = element.dataset[key];
          }

          commit('setter', data)
        }
      });
    },
  },
  getters: {
  }
});
