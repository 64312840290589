// noinspection DuplicatedCode

import Vue from 'vue';
import store from './store/store';
import './store/mixin';
import './store/directives';


import {validationMixin} from 'vuelidate';
Vue.mixin(validationMixin);

import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

// noinspection ES6CheckImport
import { MdField } from "vue-material/dist/components";
Vue.use(MdField);

// Set material theme
// noinspection JSUnresolvedVariable
Vue.material.theming.theme = 'custom';

// Remove global html class
setTimeout(()=>{
  document.documentElement.removeAttribute('class');
//   Vue.material.theming.enabled = false;
});
// import VueMaterial from "vue-material";
// Vue.use(VueMaterial);


import svgText from "./components/svgText";
import customCursor from "./components/customCursor";
import dragGallery from "./components/dragGallery";
import scrollMenu from "./components/scrollMenu";
import contactForm from "./components/contactForm";
import popupForm from "./components/popupForm";
import popupCookies from "./components/popupCookies";
import popupThankYou from "./components/popupThankYou";

window.onload = () => {
  let textWrappers = document.querySelectorAll('[data-vue=svg-text]');
  if (textWrappers) {
    textWrappers.forEach(wrapper => {
      new Vue({
        el: wrapper,
        render: h => h(svgText),
        store,
        data: {
          title: wrapper.innerText,
        }
      });
    })
  }

  let cursorContainer = document.createElement('div');
  document.body.append(cursorContainer);
  new Vue({
    el: cursorContainer,
    render: h => h(customCursor),
    store,
  });

  let dragContainer = document.createElement('div');
  document.body.append(dragContainer);
  new Vue({
    el: dragContainer,
    render: h => h(dragGallery),
    store,
  });

  let menuContainer = document.createElement('div');
  document.body.append(menuContainer);
  new Vue({
    el: menuContainer,
    render: h => h(scrollMenu),
    store,
  });

  let wrapper = document.querySelector('[data-vue=contact-form]');
  if (wrapper) {
    new Vue({
      el: wrapper,
      render: h => h(contactForm),
      store,
    });
  }

  let cookiesContainer = document.createElement('div');
  document.body.append(cookiesContainer);
  new Vue({
    el: cookiesContainer,
    render: h => h(popupCookies),
    store,
  });

  let formContainer = document.createElement('div');
  document.body.append(formContainer);
  new Vue({
    el: formContainer,
    render: h => h(popupForm),
    store,
  });

  let thankContainer = document.createElement('div');
  document.body.append(thankContainer);
  new Vue({
    el: thankContainer,
    render: h => h(popupThankYou),
    store,
  });
}
