var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "thank-you" }, [
    _c("div", { staticClass: "thank-you__icon" }, [_c("mail-svg")], 1),
    _vm._v(" "),
    _c("div", { staticClass: "thank-you__title" }, [_vm._v(_vm._s(_vm.title))]),
    _vm._v(" "),
    _vm.text
      ? _c("div", { staticClass: "thank-you__text" }, [
          _vm._v(_vm._s(_vm.text))
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "thank-you__button" }, [
      _c("button", { staticClass: "btn", on: { click: _vm.close } }, [
        _vm._v(_vm._s(_vm.button))
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "bg bg_el wave" }, [_c("wave-svg")], 1)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }