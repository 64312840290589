var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "1440",
        height: "388",
        viewBox: "0 0 1440 388",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c(
        "mask",
        {
          staticStyle: { "mask-type": "alpha" },
          attrs: {
            id: "mask0",
            maskUnits: "userSpaceOnUse",
            x: "0",
            y: "0",
            width: "1440",
            height: "388"
          }
        },
        [_c("rect", { attrs: { width: "1440", height: "388", fill: "white" } })]
      ),
      _vm._v(" "),
      _c("g", { attrs: { mask: "url(#mask0)" } }, [
        _c("path", {
          attrs: {
            d:
              "M924 206V205.313L1008.16 195.03L1104.99 73L1105.2 73.1373L1256.41 171.75L1319.18 124.984L1387.23 189.398L1458.93 155.406L1459.2 156.024L1387.09 190.223L1386.96 190.017L1319.11 125.877L1256.48 172.574L1256.28 172.505L1105.13 73.8927L1008.51 195.716L924 206Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M924 206V205.313L1008.16 195.03L1104.99 73L1105.2 73.1373L1256.41 171.75L1319.18 124.984L1387.23 189.398L1458.93 155.406L1459.2 156.024L1387.09 190.223L1386.96 190.017L1319.11 125.877L1256.48 172.574L1256.28 172.505L1105.13 73.8927L1008.51 195.716L924 206Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M956.569 205.187L956.5 204.5L1007.07 197.914L1103.14 78.0817L1166.11 114.134L1255.38 172.986L1318.49 131.783L1327.83 140.573L1371.85 177.038L1386.96 190.909L1458.93 161.312L1459.13 161.93L1386.75 191.665L1371.44 177.518L1327.35 141.054L1318.42 132.676L1255.38 173.81L1255.18 173.673L1165.77 114.752L1103.27 78.9058L1007.48 198.6L956.569 205.187Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M956.569 205.187L956.5 204.5L1007.07 197.914L1103.14 78.0817L1166.11 114.134L1255.38 172.986L1318.49 131.783L1327.83 140.573L1371.85 177.038L1386.96 190.909L1458.93 161.312L1459.13 161.93L1386.75 191.665L1371.44 177.518L1327.35 141.054L1318.42 132.676L1255.38 173.81L1255.18 173.673L1165.77 114.752L1103.27 78.9058L1007.48 198.6L956.569 205.187Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.236 205.536L967.167 204.85L1006.04 200.867L1101.28 83.0948L1164.74 114.478L1254.35 174.222L1317.81 138.65L1327.28 147.371L1371.3 178.617L1386.61 192.42L1458.86 167.218L1459.13 167.836L1386.48 193.175L1370.82 179.166L1326.8 147.921L1317.74 139.474L1254.28 175.046L1164.39 115.096L1101.42 83.9875L1006.45 201.485H1006.31L967.236 205.536Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.236 205.536L967.167 204.85L1006.04 200.867L1101.28 83.0948L1164.74 114.478L1254.35 174.222L1317.81 138.65L1327.28 147.371L1371.3 178.617L1386.61 192.42L1458.86 167.218L1459.13 167.836L1386.48 193.175L1370.82 179.166L1326.8 147.921L1317.74 139.474L1254.28 175.046L1164.39 115.096L1101.42 83.9875L1006.45 201.485H1006.31L967.236 205.536Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.305 209.588L967.236 208.901L1005.01 203.751L1099.43 88.1765L1163.43 114.89L1253.32 175.458L1317.12 145.517L1326.66 154.17L1370.68 180.265L1386.34 193.862L1458.86 173.123L1459.06 173.741L1386.13 194.618L1386 194.549L1370.27 180.815L1326.18 154.719L1316.98 146.273L1253.25 176.282L1163.09 115.439L1099.64 89.0005L1005.42 204.369L1005.28 204.438L967.305 209.588Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.305 209.588L967.236 208.901L1005.01 203.751L1099.43 88.1765L1163.43 114.89L1253.32 175.458L1317.12 145.517L1326.66 154.17L1370.68 180.265L1386.34 193.862L1458.86 173.123L1459.06 173.741L1386.13 194.618L1386 194.549L1370.27 180.815L1326.18 154.719L1316.98 146.273L1253.25 176.282L1163.09 115.439L1099.64 89.0005L1005.42 204.369L1005.28 204.438L967.305 209.588Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.373 213.64L967.236 212.953L1003.98 206.704L1097.57 93.1895L1097.78 93.2582L1162.06 115.233L1162.13 115.302L1252.22 176.694L1316.43 152.316L1326.05 160.968L1370.13 181.913L1386 195.373L1458.86 179.029L1458.99 179.716L1385.86 196.128L1369.72 182.463L1325.63 161.586L1316.29 153.14L1252.15 177.45L1252.02 177.381L1161.78 115.851L1097.78 94.0135L1004.39 207.322L967.373 213.64Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.373 213.64L967.236 212.953L1003.98 206.704L1097.57 93.1895L1097.78 93.2582L1162.06 115.233L1162.13 115.302L1252.22 176.694L1316.43 152.316L1326.05 160.968L1370.13 181.913L1386 195.373L1458.86 179.029L1458.99 179.716L1385.86 196.128L1369.72 182.463L1325.63 161.586L1316.29 153.14L1252.15 177.45L1252.02 177.381L1161.78 115.851L1097.78 94.0135L1004.39 207.322L967.373 213.64Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.442 217.691L967.305 217.005L1002.95 209.588L1095.72 98.2712L1160.75 115.645L1251.19 177.93L1315.74 159.183L1325.43 167.836L1369.51 183.493L1369.58 183.561L1385.72 196.884L1458.86 184.935L1458.93 185.622L1385.52 197.639L1385.38 197.502L1369.17 184.111L1325.08 168.385H1325.02L1315.61 159.938L1251.12 178.686L1250.99 178.617L1160.41 116.263L1095.93 99.0266L1003.36 210.206H1003.22L967.442 217.691Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.442 217.691L967.305 217.005L1002.95 209.588L1095.72 98.2712L1160.75 115.645L1251.19 177.93L1315.74 159.183L1325.43 167.836L1369.51 183.493L1369.58 183.561L1385.72 196.884L1458.86 184.935L1458.93 185.622L1385.52 197.639L1385.38 197.502L1369.17 184.111L1325.08 168.385H1325.02L1315.61 159.938L1251.12 178.686L1250.99 178.617L1160.41 116.263L1095.93 99.0266L1003.36 210.206H1003.22L967.442 217.691Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.511 221.674L967.305 221.056L1001.92 212.472L1093.87 103.284L1094 103.353L1159.38 115.989L1159.45 116.057L1250.16 179.166L1315.06 165.981L1324.88 174.634L1368.9 185.072L1385.45 198.395L1458.86 190.841L1458.93 191.527L1385.24 199.081L1368.62 185.759L1324.53 175.252L1314.92 166.737L1250.03 179.922L1249.89 179.853L1159.1 116.675L1094.07 104.04L1002.33 213.09L1002.19 213.159L967.511 221.674Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.511 221.674L967.305 221.056L1001.92 212.472L1093.87 103.284L1094 103.353L1159.38 115.989L1159.45 116.057L1250.16 179.166L1315.06 165.981L1324.88 174.634L1368.9 185.072L1385.45 198.395L1458.86 190.841L1458.93 191.527L1385.24 199.081L1368.62 185.759L1324.53 175.252L1314.92 166.737L1250.03 179.922L1249.89 179.853L1159.1 116.675L1094.07 104.04L1002.33 213.09L1002.19 213.159L967.511 221.674Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.58 225.726L967.374 225.108L1000.89 215.425L1092.01 108.366H1092.15L1158.01 116.401H1158.07L1249.13 180.403L1314.44 172.849L1324.26 181.433L1368.28 186.72L1385.1 199.905L1458.86 196.746V197.433L1384.9 200.592L1368 187.338L1323.99 182.119L1314.17 173.535L1248.93 181.158L1248.86 181.089L1157.8 117.019L1092.29 109.053L1001.3 216.043L967.58 225.726Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.58 225.726L967.374 225.108L1000.89 215.425L1092.01 108.366H1092.15L1158.01 116.401H1158.07L1249.13 180.403L1314.44 172.849L1324.26 181.433L1368.28 186.72L1385.1 199.905L1458.86 196.746V197.433L1384.9 200.592L1368 187.338L1323.99 182.119L1314.17 173.535L1248.93 181.158L1248.86 181.089L1157.8 117.019L1092.29 109.053L1001.3 216.043L967.58 225.726Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.648 229.777L967.442 229.091L999.855 218.309L1090.16 113.379L1156.7 116.744L1156.77 116.813L1248.1 181.639L1313.75 179.647L1323.64 188.231L1367.73 188.3L1367.8 188.368L1384.83 201.416L1458.79 202.652V203.339L1384.55 202.103L1367.45 188.987L1323.44 188.918L1313.48 180.334L1247.9 182.325L1156.43 117.431L1090.43 114.134L1000.27 218.927H1000.2L967.648 229.777Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.648 229.777L967.442 229.091L999.855 218.309L1090.16 113.379L1156.7 116.744L1156.77 116.813L1248.1 181.639L1313.75 179.647L1323.64 188.231L1367.73 188.3L1367.8 188.368L1384.83 201.416L1458.79 202.652V203.339L1384.55 202.103L1367.45 188.987L1323.44 188.918L1313.48 180.334L1247.9 182.325L1156.43 117.431L1090.43 114.134L1000.27 218.927H1000.2L967.648 229.777Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.717 233.829L967.442 233.142L998.825 221.262L1088.3 118.461L1155.33 117.087L1155.4 117.156L1247.07 182.875L1313.07 186.514L1313.14 186.583L1323.09 195.03L1367.11 189.948L1384.49 202.858L1458.79 208.558L1458.72 209.245L1384.28 203.545L1384.21 203.476L1366.91 190.635L1322.82 195.785L1312.79 187.201L1246.8 183.561L1246.73 183.493L1155.12 117.774L1088.58 119.147L999.237 221.812L967.717 233.829Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.717 233.829L967.442 233.142L998.825 221.262L1088.3 118.461L1155.33 117.087L1155.4 117.156L1247.07 182.875L1313.07 186.514L1313.14 186.583L1323.09 195.03L1367.11 189.948L1384.49 202.858L1458.79 208.558L1458.72 209.245L1384.28 203.545L1384.21 203.476L1366.91 190.635L1322.82 195.785L1312.79 187.201L1246.8 183.561L1246.73 183.493L1155.12 117.774L1088.58 119.147L999.237 221.812L967.717 233.829Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.785 237.812L967.511 237.194L997.795 224.146L1086.45 123.474H1086.59L1153.95 117.499L1154.09 117.568L1245.97 184.111L1312.38 193.313L1322.47 201.897L1366.49 191.527L1384.21 204.369L1458.79 214.532L1458.72 215.15L1383.94 205.056L1366.36 192.283L1322.27 202.652L1312.11 193.999L1245.7 184.797L1245.63 184.729L1153.75 118.186L1086.79 124.16L998.207 224.696L998.138 224.764L967.785 237.812Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.785 237.812L967.511 237.194L997.795 224.146L1086.45 123.474H1086.59L1153.95 117.499L1154.09 117.568L1245.97 184.111L1312.38 193.313L1322.47 201.897L1366.49 191.527L1384.21 204.369L1458.79 214.532L1458.72 215.15L1383.94 205.056L1366.36 192.283L1322.27 202.652L1312.11 193.999L1245.7 184.797L1245.63 184.729L1153.75 118.186L1086.79 124.16L998.207 224.696L998.138 224.764L967.785 237.812Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.854 241.864L967.511 241.246L996.765 227.099L1084.6 128.555H1084.66L1152.65 117.843L1152.72 117.911L1244.94 185.347L1311.69 200.18L1321.86 208.695L1365.88 193.175L1383.94 205.88L1458.79 220.438L1458.65 221.056L1383.66 206.498H1383.59L1365.81 193.931L1321.72 209.451L1321.58 209.313L1311.35 200.798L1244.67 186.034L1244.6 185.965L1152.44 118.598L1084.94 129.173L997.177 227.649H997.108L967.854 241.864Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.854 241.864L967.511 241.246L996.765 227.099L1084.6 128.555H1084.66L1152.65 117.843L1152.72 117.911L1244.94 185.347L1311.69 200.18L1321.86 208.695L1365.88 193.175L1383.94 205.88L1458.79 220.438L1458.65 221.056L1383.66 206.498H1383.59L1365.81 193.931L1321.72 209.451L1321.58 209.313L1311.35 200.798L1244.67 186.034L1244.6 185.965L1152.44 118.598L1084.94 129.173L997.177 227.649H997.108L967.854 241.864Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.923 245.915L967.579 245.297L995.735 229.984L1082.74 133.568H1082.81L1151.28 118.186L1151.41 118.323L1243.91 186.583L1311.01 206.979L1321.24 215.494L1365.26 194.755L1383.59 207.391L1458.79 226.344L1458.58 227.031L1383.32 208.009H1383.25L1365.19 195.579L1321.17 216.318L1310.66 207.597L1243.57 187.201L1151.14 118.941L1083.09 134.187L996.147 230.533L967.923 245.915Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.923 245.915L967.579 245.297L995.735 229.984L1082.74 133.568H1082.81L1151.28 118.186L1151.41 118.323L1243.91 186.583L1311.01 206.979L1321.24 215.494L1365.26 194.755L1383.59 207.391L1458.79 226.344L1458.58 227.031L1383.32 208.009H1383.25L1365.19 195.579L1321.17 216.318L1310.66 207.597L1243.57 187.201L1151.14 118.941L1083.09 134.187L996.147 230.533L967.923 245.915Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.991 249.967L967.579 249.349L994.705 232.868L1080.89 138.65L1080.96 138.582L1149.9 118.598L1242.88 187.819L1310.32 213.846H1310.39L1320.69 222.292L1364.71 196.403L1383.32 208.901L1458.72 232.25L1458.51 232.936L1382.97 209.519V209.451L1364.64 197.158L1320.62 223.116L1309.98 214.464L1242.54 188.437H1242.47L1149.77 119.353L1081.23 139.268L995.185 233.417L995.117 233.486L967.991 249.967Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.991 249.967L967.579 249.349L994.705 232.868L1080.89 138.65L1080.96 138.582L1149.9 118.598L1242.88 187.819L1310.32 213.846H1310.39L1320.69 222.292L1364.71 196.403L1383.32 208.901L1458.72 232.25L1458.51 232.936L1382.97 209.519V209.451L1364.64 197.158L1320.62 223.116L1309.98 214.464L1242.54 188.437H1242.47L1149.77 119.353L1081.23 139.268L995.185 233.417L995.117 233.486L967.991 249.967Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.06 253.95L967.648 253.401L993.675 235.821L1079.03 143.663H1079.1L1148.6 118.941L1241.79 189.055L1309.63 220.644L1309.7 220.713L1320.07 229.091L1364.09 197.982L1382.97 210.343L1458.72 238.155L1458.51 238.842L1382.7 210.961L1364.09 198.807L1320 229.983L1309.29 221.262L1241.44 189.673L1148.46 119.697L1079.45 144.281L994.155 236.37H994.087L968.06 253.95Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.06 253.95L967.648 253.401L993.675 235.821L1079.03 143.663H1079.1L1148.6 118.941L1241.79 189.055L1309.63 220.644L1309.7 220.713L1320.07 229.091L1364.09 197.982L1382.97 210.343L1458.72 238.155L1458.51 238.842L1382.7 210.961L1364.09 198.807L1320 229.983L1309.29 221.262L1241.44 189.673L1148.46 119.697L1079.45 144.281L994.155 236.37H994.087L968.06 253.95Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.129 258.001L967.717 257.452L992.645 238.705L1077.18 148.745L1077.25 148.676L1147.22 119.353L1147.36 119.422L1240.76 190.36L1308.95 227.511L1319.45 235.958L1363.47 199.631L1382.7 211.854L1458.72 244.061L1458.44 244.748L1382.43 212.472L1363.54 200.455L1319.45 236.851L1308.53 228.061L1240.41 190.909L1147.16 120.109L1077.59 149.294L993.125 239.254L968.129 258.001Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.129 258.001L967.717 257.452L992.645 238.705L1077.18 148.745L1077.25 148.676L1147.22 119.353L1147.36 119.422L1240.76 190.36L1308.95 227.511L1319.45 235.958L1363.47 199.631L1382.7 211.854L1458.72 244.061L1458.44 244.748L1382.43 212.472L1363.54 200.455L1319.45 236.851L1308.53 228.061L1240.41 190.909L1147.16 120.109L1077.59 149.294L993.125 239.254L968.129 258.001Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.197 262.053L967.717 261.504L991.615 241.658L1075.33 153.758H1075.39L1145.85 119.697L1239.73 191.527L1308.26 234.31L1318.84 242.756L1362.85 201.21L1382.43 213.365L1458.72 250.036L1458.38 250.654L1382.08 213.983L1362.99 202.103L1318.9 243.649L1307.85 234.859L1239.31 192.145L1145.78 120.521L1075.74 154.307L992.095 242.138L968.197 262.053Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.197 262.053L967.717 261.504L991.615 241.658L1075.33 153.758H1075.39L1145.85 119.697L1239.73 191.527L1308.26 234.31L1318.84 242.756L1362.85 201.21L1382.43 213.365L1458.72 250.036L1458.38 250.654L1382.08 213.983L1362.99 202.103L1318.9 243.649L1307.85 234.859L1239.31 192.145L1145.78 120.521L1075.74 154.307L992.095 242.138L968.197 262.053Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.266 266.036L967.785 265.555L990.584 244.542L1073.47 158.84L1073.54 158.771L1144.55 120.04L1144.68 120.178L1238.7 192.763L1307.57 241.177L1318.29 249.555L1362.3 202.858L1382.08 214.876L1458.65 255.941L1458.38 256.559L1381.74 215.494L1362.37 203.751L1318.35 250.516L1307.16 241.726L1238.28 193.313L1144.48 120.864L1073.88 159.389L991.065 245.023L968.266 266.036Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.266 266.036L967.785 265.555L990.584 244.542L1073.47 158.84L1073.54 158.771L1144.55 120.04L1144.68 120.178L1238.7 192.763L1307.57 241.177L1318.29 249.555L1362.3 202.858L1382.08 214.876L1458.65 255.941L1458.38 256.559L1381.74 215.494L1362.37 203.751L1318.35 250.516L1307.16 241.726L1238.28 193.313L1144.48 120.864L1073.88 159.389L991.065 245.023L968.266 266.036Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.266 270.088L967.785 269.607L1071.62 163.853H1071.69L1143.17 120.452L1143.38 120.589L1317.67 256.353L1361.69 204.438L1361.96 204.644L1458.65 261.847L1458.31 262.465L1361.82 205.33L1317.8 257.315L1317.53 257.109L1143.1 121.276L1072.03 164.402L968.266 270.088Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.266 270.088L967.785 269.607L1071.62 163.853H1071.69L1143.17 120.452L1143.38 120.589L1317.67 256.353L1361.69 204.438L1361.96 204.644L1458.65 261.847L1458.31 262.465L1361.82 205.33L1317.8 257.315L1317.53 257.109L1143.1 121.276L1072.03 164.402L968.266 270.088Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.197 271.598L967.717 271.118L1069.9 169.14H1069.97L1142.35 125.396L1142.49 125.534L1310.39 258.345L1361.96 207.94L1362.24 208.077L1458.65 264.457L1458.31 265.075L1362.1 208.764L1310.39 259.238L1310.18 259.032L1142.28 126.221L1070.38 169.69L968.197 271.598Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.197 271.598L967.717 271.118L1069.9 169.14H1069.97L1142.35 125.396L1142.49 125.534L1310.39 258.345L1361.96 207.94L1362.24 208.077L1458.65 264.457L1458.31 265.075L1362.1 208.764L1310.39 259.238L1310.18 259.032L1142.28 126.221L1070.38 169.69L968.197 271.598Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.129 273.178L967.648 272.697L1068.25 174.428L1141.46 130.341L1141.66 130.478L1303.04 260.268L1362.24 211.373L1362.44 211.511L1458.65 267.066L1458.31 267.616L1362.3 212.198L1303.04 261.16L1302.83 260.954L1141.46 131.165L1068.66 174.978L968.129 273.178Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.129 273.178L967.648 272.697L1068.25 174.428L1141.46 130.341L1141.66 130.478L1303.04 260.268L1362.24 211.373L1362.44 211.511L1458.65 267.066L1458.31 267.616L1362.3 212.198L1303.04 261.16L1302.83 260.954L1141.46 131.165L1068.66 174.978L968.129 273.178Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.06 274.689L967.579 274.208L1066.54 179.716H1066.6L1140.63 135.285L1140.84 135.423L1295.69 262.19L1362.58 214.807L1458.65 269.607L1458.31 270.225L1362.58 215.631L1295.69 263.083L1295.49 262.877L1140.56 136.109L1066.95 180.265L968.06 274.689Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.06 274.689L967.579 274.208L1066.54 179.716H1066.6L1140.63 135.285L1140.84 135.423L1295.69 262.19L1362.58 214.807L1458.65 269.607L1458.31 270.225L1362.58 215.631L1295.69 263.083L1295.49 262.877L1140.56 136.109L1066.95 180.265L968.06 274.689Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.991 276.2L967.511 275.719L1064.82 185.004H1064.89L1139.81 140.23L1140.01 140.367L1288.34 264.113L1362.85 218.241L1362.99 218.378L1458.65 272.217L1458.31 272.835L1362.85 219.065L1288.34 264.937L1139.74 141.054L1065.23 185.553L967.991 276.2Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.991 276.2L967.511 275.719L1064.82 185.004H1064.89L1139.81 140.23L1140.01 140.367L1288.34 264.113L1362.85 218.241L1362.99 218.378L1458.65 272.217L1458.31 272.835L1362.85 219.065L1288.34 264.937L1139.74 141.054L1065.23 185.553L967.991 276.2Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.923 277.779L967.442 277.23L1063.1 190.36L1138.98 145.174L1139.12 145.311L1281.07 266.036L1363.13 221.674L1363.27 221.812L1458.65 274.826L1458.31 275.444L1363.13 222.498L1280.93 266.86L1280.79 266.723L1138.92 145.998L1063.51 190.909L967.923 277.779Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.923 277.779L967.442 277.23L1063.1 190.36L1138.98 145.174L1139.12 145.311L1281.07 266.036L1363.13 221.674L1363.27 221.812L1458.65 274.826L1458.31 275.444L1363.13 222.498L1280.93 266.86L1280.79 266.723L1138.92 145.998L1063.51 190.909L967.923 277.779Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.854 279.29L967.374 278.74L1061.45 195.648L1138.16 150.118L1273.72 267.959L1363.4 225.108L1363.54 225.245L1458.65 277.436L1458.31 278.054L1363.4 225.932L1273.58 268.783L1273.44 268.646L1138.02 150.942L1061.87 196.197L967.854 279.29Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.854 279.29L967.374 278.74L1061.45 195.648L1138.16 150.118L1273.72 267.959L1363.4 225.108L1363.54 225.245L1458.65 277.436L1458.31 278.054L1363.4 225.932L1273.58 268.783L1273.44 268.646L1138.02 150.942L1061.87 196.197L967.854 279.29Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.785 280.801L967.305 280.32L1059.74 200.935L1137.27 155.063L1137.47 155.2L1266.37 269.882L1363.68 228.541L1458.65 280.045L1458.31 280.595L1363.68 229.297L1266.23 270.706L1266.1 270.568L1137.2 155.887L1060.15 201.485L967.785 280.801Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.785 280.801L967.305 280.32L1059.74 200.935L1137.27 155.063L1137.47 155.2L1266.37 269.882L1363.68 228.541L1458.65 280.045L1458.31 280.595L1363.68 229.297L1266.23 270.706L1266.1 270.568L1137.2 155.887L1060.15 201.485L967.785 280.801Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1458.31 283.204L1363.88 232.73L1258.88 272.629L1258.75 272.491L1136.37 160.831L1058.43 206.772L967.717 282.38L967.236 281.831L1058.02 206.223L1136.44 160.007L1259.02 271.873L1363.95 231.975L1364.09 232.112L1458.65 282.586L1458.31 283.204Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1458.31 283.204L1363.88 232.73L1258.88 272.629L1258.75 272.491L1136.37 160.831L1058.43 206.772L967.717 282.38L967.236 281.831L1058.02 206.223L1136.44 160.007L1259.02 271.873L1363.95 231.975L1364.09 232.112L1458.65 282.586L1458.31 283.204Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1458.31 285.814L1364.16 236.164L1251.54 274.551L1251.4 274.414L1135.48 165.775L1056.72 212.06L967.58 283.891L967.167 283.341L1056.37 211.511L1135.62 164.951L1135.83 165.157L1251.74 273.796L1364.23 235.477L1364.36 235.546L1458.65 285.196L1458.31 285.814Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1458.31 285.814L1364.16 236.164L1251.54 274.551L1251.4 274.414L1135.48 165.775L1056.72 212.06L967.58 283.891L967.167 283.341L1056.37 211.511L1135.62 164.951L1135.83 165.157L1251.74 273.796L1364.23 235.477L1364.36 235.546L1458.65 285.196L1458.31 285.814Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1458.31 288.423L1364.43 239.598L1244.19 276.474L1244.05 276.337L1134.66 170.72L1055.07 217.348L967.511 285.402L967.099 284.852L1054.66 216.799L1134.79 169.896L1134.93 170.102L1244.39 275.719L1364.5 238.911L1364.64 238.979L1458.65 287.805L1458.31 288.423Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1458.31 288.423L1364.43 239.598L1244.19 276.474L1244.05 276.337L1134.66 170.72L1055.07 217.348L967.511 285.402L967.099 284.852L1054.66 216.799L1134.79 169.896L1134.93 170.102L1244.39 275.719L1364.5 238.911L1364.64 238.979L1458.65 287.805L1458.31 288.423Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1458.31 291.033L1364.71 243.031L1236.84 278.397L1236.7 278.26L1133.83 175.733L1053.35 222.636L967.442 286.981L967.03 286.432L1052.94 222.086L1133.9 174.84L1134.11 175.046L1237.05 277.642L1364.78 242.344L1364.91 242.413L1458.65 290.415L1458.31 291.033Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1458.31 291.033L1364.71 243.031L1236.84 278.397L1236.7 278.26L1133.83 175.733L1053.35 222.636L967.442 286.981L967.03 286.432L1052.94 222.086L1133.9 174.84L1134.11 175.046L1237.05 277.642L1364.78 242.344L1364.91 242.413L1458.65 290.415L1458.31 291.033Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1458.31 293.573L1364.98 246.465L1229.49 280.32L1229.36 280.182L1132.94 180.677L1051.63 227.923L967.373 288.492L966.961 287.942L1051.29 227.374L1133.08 179.784L1229.7 279.564L1365.05 245.778L1365.19 245.847L1458.65 292.955L1458.31 293.573Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1458.31 293.573L1364.98 246.465L1229.49 280.32L1229.36 280.182L1132.94 180.677L1051.63 227.923L967.373 288.492L966.961 287.942L1051.29 227.374L1133.08 179.784L1229.7 279.564L1365.05 245.778L1365.19 245.847L1458.65 292.955L1458.31 293.573Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1458.31 296.183L1365.26 249.898L1222.15 282.243L1222.01 282.105L1132.12 185.621L1049.99 233.28L967.305 290.002L966.893 289.453L1049.57 232.662L1132.25 184.729L1132.46 184.935L1222.35 281.487L1365.33 249.211L1458.65 295.565L1458.31 296.183Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1458.31 296.183L1365.26 249.898L1222.15 282.243L1222.01 282.105L1132.12 185.621L1049.99 233.28L967.305 290.002L966.893 289.453L1049.57 232.662L1132.25 184.729L1132.46 184.935L1222.35 281.487L1365.33 249.211L1458.65 295.565L1458.31 296.183Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1458.31 298.793L1365.53 253.332L1214.8 284.165L1214.66 284.028L1131.29 190.566L1048.27 238.567L967.236 291.582L966.824 290.964L1047.93 237.949L1131.43 189.673L1131.57 189.879L1215 283.41L1365.6 252.645L1365.74 252.714L1458.65 298.174L1458.31 298.793Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1458.31 298.793L1365.53 253.332L1214.8 284.165L1214.66 284.028L1131.29 190.566L1048.27 238.567L967.236 291.582L966.824 290.964L1047.93 237.949L1131.43 189.673L1131.57 189.879L1215 283.41L1365.6 252.645L1365.74 252.714L1458.65 298.174L1458.31 298.793Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1458.31 301.402L1365.81 256.765L1207.45 286.088L1207.31 285.882L1130.4 195.51L1046.55 243.855L967.167 293.093L966.755 292.475L1046.21 243.237L1130.61 194.617L1130.74 194.823L1207.72 285.333L1365.88 256.079L1366.01 256.147L1458.65 300.784L1458.31 301.402Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1458.31 301.402L1365.81 256.765L1207.45 286.088L1207.31 285.882L1130.4 195.51L1046.55 243.855L967.167 293.093L966.755 292.475L1046.21 243.237L1130.61 194.617L1130.74 194.823L1207.72 285.333L1365.88 256.079L1366.01 256.147L1458.65 300.784L1458.31 301.402Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1458.31 304.012L1366.08 260.199L1200.1 288.011L1129.58 200.455L1044.84 249.143L967.03 294.604L966.687 294.054L1044.49 248.525L1129.71 199.562L1129.92 199.768L1200.38 287.256L1366.15 259.512L1366.29 259.581L1458.65 303.394L1458.31 304.012Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1458.31 304.012L1366.08 260.199L1200.1 288.011L1129.58 200.455L1044.84 249.143L967.03 294.604L966.687 294.054L1044.49 248.525L1129.71 199.562L1129.92 199.768L1200.38 287.256L1366.15 259.512L1366.29 259.581L1458.65 303.394L1458.31 304.012Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1458.31 306.552L1366.36 263.633L1192.75 289.934L1192.62 289.728L1128.75 205.399L1043.19 254.431L966.962 296.183L966.618 295.565L1042.84 253.812L1128.89 204.506L1193.03 289.178L1366.42 262.946L1366.56 263.015L1458.65 305.934L1458.31 306.552Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1458.31 306.552L1366.36 263.633L1192.75 289.934L1192.62 289.728L1128.75 205.399L1043.19 254.431L966.962 296.183L966.618 295.565L1042.84 253.812L1128.89 204.506L1193.03 289.178L1366.42 262.946L1366.56 263.015L1458.65 305.934L1458.31 306.552Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1458.31 309.162L1366.63 267.066L1185.41 291.857L1185.27 291.651L1127.86 210.343L1041.47 259.718L966.893 297.694L966.549 297.076L1041.13 259.1L1128.07 209.451L1128.27 209.725L1185.68 291.101L1366.7 266.379L1366.84 266.448L1458.65 308.544L1458.31 309.162Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1458.31 309.162L1366.63 267.066L1185.41 291.857L1185.27 291.651L1127.86 210.343L1041.47 259.718L966.893 297.694L966.549 297.076L1041.13 259.1L1128.07 209.451L1128.27 209.725L1185.68 291.101L1366.7 266.379L1366.84 266.448L1458.65 308.544L1458.31 309.162Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1458.31 311.565C1457.55 311.153 1368.55 272.011 1365.67 271.942C1364.23 272.011 1323.78 277.436 1323.37 277.504L1183.69 293.161L1168.92 273.865L1123.4 216.18L1039.41 264.388L966.893 301.608L966.549 300.99L1039 263.77L1123.53 215.219L1169.41 273.384L1183.9 292.337L1323.16 276.749C1324.81 276.543 1364.09 271.255 1365.53 271.186C1368.28 271.186 1458.31 310.535 1458.65 310.947L1458.38 311.153L1458.03 311.085L1458.38 311.153L1458.31 311.565Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1458.31 311.565C1457.55 311.153 1368.55 272.011 1365.67 271.942C1364.23 272.011 1323.78 277.436 1323.37 277.504L1183.69 293.161L1168.92 273.865L1123.4 216.18L1039.41 264.388L966.893 301.608L966.549 300.99L1039 263.77L1123.53 215.219L1169.41 273.384L1183.9 292.337L1323.16 276.749C1324.81 276.543 1364.09 271.255 1365.53 271.186C1368.28 271.186 1458.31 310.535 1458.65 310.947L1458.38 311.153L1458.03 311.085L1458.38 311.153L1458.31 311.565Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1458.31 314.038C1457.62 313.626 1370.54 276.955 1364.71 276.955C1364.71 276.955 1364.71 276.955 1364.64 276.955C1361.82 277.092 1322.47 282.243 1322.06 282.311L1181.9 294.603L1166.52 276.062L1118.86 222.017L1037.28 269.126L966.962 305.591L966.618 304.973L1036.94 268.508L1119 221.125L1119.21 221.331L1167.07 275.513L1182.25 293.779L1321.99 281.556C1323.57 281.35 1361.76 276.337 1364.64 276.199C1367.39 276.131 1392.79 286.157 1413.53 294.603C1431.59 301.951 1458.51 313.145 1458.79 313.488L1458.93 313.626L1458.86 313.763L1458.17 313.557L1458.51 313.626L1458.31 314.038Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1458.31 314.038C1457.62 313.626 1370.54 276.955 1364.71 276.955C1364.71 276.955 1364.71 276.955 1364.64 276.955C1361.82 277.092 1322.47 282.243 1322.06 282.311L1181.9 294.603L1166.52 276.062L1118.86 222.017L1037.28 269.126L966.962 305.591L966.618 304.973L1036.94 268.508L1119 221.125L1119.21 221.331L1167.07 275.513L1182.25 293.779L1321.99 281.556C1323.57 281.35 1361.76 276.337 1364.64 276.199C1367.39 276.131 1392.79 286.157 1413.53 294.603C1431.59 301.951 1458.51 313.145 1458.79 313.488L1458.93 313.626L1458.86 313.763L1458.17 313.557L1458.51 313.626L1458.31 314.038Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1458.31 316.441C1457.69 316.098 1372.47 281.831 1363.75 281.831C1363.68 281.831 1363.68 281.831 1363.61 281.831C1359.35 282.037 1321.17 286.912 1320.83 286.981L1180.19 295.908L1164.19 278.054L1114.33 227.923L1035.08 273.865L966.893 309.574L966.549 308.956L1034.74 273.315L1114.4 227.099L1164.6 277.642L1180.39 295.222L1320.69 286.294C1322.27 286.088 1359.21 281.35 1363.54 281.144C1363.61 281.144 1363.61 281.144 1363.68 281.144C1368.14 281.144 1393.41 290.415 1413.88 298.243C1433.24 305.591 1458.38 315.548 1458.72 315.96L1458.86 316.098L1458.79 316.304L1458.1 316.098L1458.44 316.166L1458.31 316.441Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1458.31 316.441C1457.69 316.098 1372.47 281.831 1363.75 281.831C1363.68 281.831 1363.68 281.831 1363.61 281.831C1359.35 282.037 1321.17 286.912 1320.83 286.981L1180.19 295.908L1164.19 278.054L1114.33 227.923L1035.08 273.865L966.893 309.574L966.549 308.956L1034.74 273.315L1114.4 227.099L1164.6 277.642L1180.39 295.222L1320.69 286.294C1322.27 286.088 1359.21 281.35 1363.54 281.144C1363.61 281.144 1363.61 281.144 1363.68 281.144C1368.14 281.144 1393.41 290.415 1413.88 298.243C1433.24 305.591 1458.38 315.548 1458.72 315.96L1458.86 316.098L1458.79 316.304L1458.1 316.098L1458.44 316.166L1458.31 316.441Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1458.31 318.913C1457.48 318.433 1436.4 310.398 1413.6 302.295C1385.31 292.269 1367.32 286.775 1362.79 286.775C1362.72 286.775 1362.65 286.775 1362.58 286.775C1356.88 286.981 1319.93 291.651 1319.52 291.719L1178.4 297.282L1161.71 280.182L1109.73 233.76L1033.02 278.534L966.961 313.488L966.687 312.87L1032.75 277.985L1110 232.936L1162.33 279.702L1178.81 296.595L1319.59 291.033C1319.93 290.964 1356.95 286.363 1362.65 286.088C1362.72 286.088 1362.79 286.088 1362.85 286.088C1368.76 286.088 1394.03 294.604 1414.36 301.814C1433.45 308.613 1458.38 317.883 1458.79 318.433L1458.93 318.57L1458.86 318.776L1458.51 318.707L1458.31 318.913Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1458.31 318.913C1457.48 318.433 1436.4 310.398 1413.6 302.295C1385.31 292.269 1367.32 286.775 1362.79 286.775C1362.72 286.775 1362.65 286.775 1362.58 286.775C1356.88 286.981 1319.93 291.651 1319.52 291.719L1178.4 297.282L1161.71 280.182L1109.73 233.76L1033.02 278.534L966.961 313.488L966.687 312.87L1032.75 277.985L1110 232.936L1162.33 279.702L1178.81 296.595L1319.59 291.033C1319.93 290.964 1356.95 286.363 1362.65 286.088C1362.72 286.088 1362.79 286.088 1362.85 286.088C1368.76 286.088 1394.03 294.604 1414.36 301.814C1433.45 308.613 1458.38 317.883 1458.79 318.433L1458.93 318.57L1458.86 318.776L1458.51 318.707L1458.31 318.913Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1458.24 321.317C1457.41 320.768 1436.95 313.42 1413.95 305.866C1385.38 296.526 1366.77 291.445 1361.55 291.651C1354.41 291.925 1318.63 296.32 1318.29 296.389L1176.75 298.587L1159.45 282.174L1105.33 239.529L1030.89 283.273L967.03 317.403L966.687 316.785L1030.62 282.655L1105.4 238.773L1159.93 281.693L1177.03 297.9L1318.29 295.702C1318.63 295.634 1354.41 291.307 1361.55 290.964C1368.35 290.689 1394.24 298.655 1414.7 305.385C1435.03 312.046 1458.31 320.287 1458.72 320.836L1458.86 320.974L1458.79 321.18L1458.44 321.111L1458.24 321.317Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1458.24 321.317C1457.41 320.768 1436.95 313.42 1413.95 305.866C1385.38 296.526 1366.77 291.445 1361.55 291.651C1354.41 291.925 1318.63 296.32 1318.29 296.389L1176.75 298.587L1159.45 282.174L1105.33 239.529L1030.89 283.273L967.03 317.403L966.687 316.785L1030.62 282.655L1105.4 238.773L1159.93 281.693L1177.03 297.9L1318.29 295.702C1318.63 295.634 1354.41 291.307 1361.55 290.964C1368.35 290.689 1394.24 298.655 1414.7 305.385C1435.03 312.046 1458.31 320.287 1458.72 320.836L1458.86 320.974L1458.79 321.18L1458.44 321.111L1458.24 321.317Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1458.24 323.789C1457.41 323.24 1437.16 316.372 1414.36 309.505C1385.93 300.921 1366.77 296.389 1360.52 296.595C1351.93 296.938 1317.32 301.059 1316.98 301.127L1174.97 299.96L1157.04 284.303L1100.8 245.435L1028.83 287.942L967.03 321.317L966.687 320.767L1028.49 287.393L1100.87 244.611L1157.53 283.753L1175.31 299.273L1317.05 300.441C1317.32 300.372 1352 296.32 1360.59 295.908C1368.76 295.565 1394.79 302.844 1415.18 308.956C1435.71 315.136 1458.31 322.622 1458.79 323.24L1458.93 323.377L1458.86 323.583L1458.17 323.377L1458.51 323.446L1458.24 323.789Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1458.24 323.789C1457.41 323.24 1437.16 316.372 1414.36 309.505C1385.93 300.921 1366.77 296.389 1360.52 296.595C1351.93 296.938 1317.32 301.059 1316.98 301.127L1174.97 299.96L1157.04 284.303L1100.8 245.435L1028.83 287.942L967.03 321.317L966.687 320.767L1028.49 287.393L1100.87 244.611L1157.53 283.753L1175.31 299.273L1317.05 300.441C1317.32 300.372 1352 296.32 1360.59 295.908C1368.76 295.565 1394.79 302.844 1415.18 308.956C1435.71 315.136 1458.31 322.622 1458.79 323.24L1458.93 323.377L1458.86 323.583L1458.17 323.377L1458.51 323.446L1458.24 323.789Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1458.24 326.193C1457.41 325.643 1437.77 319.394 1414.84 313.076C1398.43 308.544 1369.45 301.059 1359.56 301.471C1349.6 301.883 1316.16 305.728 1315.81 305.797L1173.32 301.265L1173.25 301.196L1154.78 286.294L1096.41 251.203L1026.71 292.681L967.03 325.3L966.687 324.682L1026.36 292.063L1096.34 250.448L1096.54 250.585L1155.19 285.814L1173.59 300.647L1315.81 305.179C1316.09 305.11 1349.53 301.265 1359.56 300.853C1369.1 300.441 1395.27 307.033 1415.59 312.596C1436.4 318.295 1458.31 325.094 1458.79 325.712L1458.93 325.849L1458.86 326.055L1458.17 325.849L1458.51 325.918L1458.24 326.193Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1458.24 326.193C1457.41 325.643 1437.77 319.394 1414.84 313.076C1398.43 308.544 1369.45 301.059 1359.56 301.471C1349.6 301.883 1316.16 305.728 1315.81 305.797L1173.32 301.265L1173.25 301.196L1154.78 286.294L1096.41 251.203L1026.71 292.681L967.03 325.3L966.687 324.682L1026.36 292.063L1096.34 250.448L1096.54 250.585L1155.19 285.814L1173.59 300.647L1315.81 305.179C1316.09 305.11 1349.53 301.265 1359.56 300.853C1369.1 300.441 1395.27 307.033 1415.59 312.596C1436.4 318.295 1458.31 325.094 1458.79 325.712L1458.93 325.849L1458.86 326.055L1458.17 325.849L1458.51 325.918L1458.24 326.193Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.099 329.214L966.755 328.596L1024.3 296.732L1091.88 256.285L1152.79 287.805L1171.88 301.951L1314.58 309.78C1314.85 309.711 1347.13 306.14 1358.53 305.66C1370 305.179 1399.46 311.977 1416.01 316.098C1437.02 321.317 1458.24 327.36 1458.79 328.047L1458.93 328.184L1458.86 328.39L1458.17 328.184L1458.51 328.253L1458.31 328.459C1455.84 326.742 1378.24 305.454 1358.6 306.278C1347.2 306.758 1314.92 310.329 1314.58 310.398L1171.6 302.501L1152.37 288.217L1091.81 256.903L1024.58 297.144L967.099 329.214Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.099 329.214L966.755 328.596L1024.3 296.732L1091.88 256.285L1152.79 287.805L1171.88 301.951L1314.58 309.78C1314.85 309.711 1347.13 306.14 1358.53 305.66C1370 305.179 1399.46 311.977 1416.01 316.098C1437.02 321.317 1458.24 327.36 1458.79 328.047L1458.93 328.184L1458.86 328.39L1458.17 328.184L1458.51 328.253L1458.31 328.459C1455.84 326.742 1378.24 305.454 1358.6 306.278C1347.2 306.758 1314.92 310.329 1314.58 310.398L1171.6 302.501L1152.37 288.217L1091.81 256.903L1024.58 297.144L967.099 329.214Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.099 333.197L966.755 332.579L1022.17 301.471L1087.27 262.19L1087.41 262.259L1150.31 289.934L1170.02 303.393L1313.2 314.587C1313.48 314.587 1344.52 311.222 1357.43 310.673C1370.34 310.123 1399.87 316.166 1416.28 319.806C1435.85 324.132 1458.03 329.763 1458.72 330.587L1458.86 330.725L1458.79 330.931L1458.44 330.862L1458.24 331.068C1457.28 330.45 1438.26 325.3 1415.66 320.355C1399.39 316.784 1370.27 310.879 1357.5 311.359C1344.66 311.909 1313.62 315.205 1313.27 315.274L1169.89 304.08L1150.04 290.552L1087.34 263.014L1022.52 302.157L967.099 333.197Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.099 333.197L966.755 332.579L1022.17 301.471L1087.27 262.19L1087.41 262.259L1150.31 289.934L1170.02 303.393L1313.2 314.587C1313.48 314.587 1344.52 311.222 1357.43 310.673C1370.34 310.123 1399.87 316.166 1416.28 319.806C1435.85 324.132 1458.03 329.763 1458.72 330.587L1458.86 330.725L1458.79 330.931L1458.44 330.862L1458.24 331.068C1457.28 330.45 1438.26 325.3 1415.66 320.355C1399.39 316.784 1370.27 310.879 1357.5 311.359C1344.66 311.909 1313.62 315.205 1313.27 315.274L1169.89 304.08L1150.04 290.552L1087.34 263.014L1022.52 302.157L967.099 333.197Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.099 337.111L966.755 336.493L1020.04 306.14L1082.74 268.028L1082.88 268.096L1147.98 291.994L1168.31 304.767L1311.9 319.325C1313.07 319.188 1342.25 316.235 1356.4 315.617C1370.82 314.999 1400.35 320.218 1416.69 323.377C1436.47 327.223 1458.03 332.167 1458.72 332.991L1458.86 333.128L1458.79 333.334L1458.44 333.266L1458.17 333.472C1456.32 332.167 1381.46 315.205 1356.4 316.304C1342.11 316.922 1312.24 319.943 1311.97 320.012H1311.9H1311.83L1168.03 305.454L1147.57 292.612L1082.67 268.852L1020.25 306.827L967.099 337.111Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.099 337.111L966.755 336.493L1020.04 306.14L1082.74 268.028L1082.88 268.096L1147.98 291.994L1168.31 304.767L1311.9 319.325C1313.07 319.188 1342.25 316.235 1356.4 315.617C1370.82 314.999 1400.35 320.218 1416.69 323.377C1436.47 327.223 1458.03 332.167 1458.72 332.991L1458.86 333.128L1458.79 333.334L1458.44 333.266L1458.17 333.472C1456.32 332.167 1381.46 315.205 1356.4 316.304C1342.11 316.922 1312.24 319.943 1311.97 320.012H1311.9H1311.83L1168.03 305.454L1147.57 292.612L1082.67 268.852L1020.25 306.827L967.099 337.111Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.099 341.094L966.755 340.476L1017.92 310.947L1078.21 273.933L1145.65 294.054L1166.59 306.072L1310.73 324.064C1311.97 323.926 1339.99 321.248 1355.44 320.561C1371.3 319.875 1400.97 324.338 1417.17 327.016C1437.09 330.313 1458.1 334.57 1458.79 335.463L1458.93 335.6L1458.86 335.806L1458.17 335.6L1458.51 335.669L1458.24 335.875C1457.35 335.257 1439.49 331.343 1416.49 327.566C1400.42 324.888 1371.16 320.561 1355.44 321.179C1339.71 321.866 1311.01 324.613 1310.73 324.682H1310.66H1310.59L1166.32 306.758L1166.25 306.69L1145.23 294.603L1078.21 274.62L1018.19 311.497L967.099 341.094Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.099 341.094L966.755 340.476L1017.92 310.947L1078.21 273.933L1145.65 294.054L1166.59 306.072L1310.73 324.064C1311.97 323.926 1339.99 321.248 1355.44 320.561C1371.3 319.875 1400.97 324.338 1417.17 327.016C1437.09 330.313 1458.1 334.57 1458.79 335.463L1458.93 335.6L1458.86 335.806L1458.17 335.6L1458.51 335.669L1458.24 335.875C1457.35 335.257 1439.49 331.343 1416.49 327.566C1400.42 324.888 1371.16 320.561 1355.44 321.179C1339.71 321.866 1311.01 324.613 1310.73 324.682H1310.66H1310.59L1166.32 306.758L1166.25 306.69L1145.23 294.603L1078.21 274.62L1018.19 311.497L967.099 341.094Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.168 345.008L966.824 344.39L1015.86 315.617L1073.75 279.77H1073.88L1143.31 296.046H1143.38L1165.01 307.376L1309.56 328.665C1310.8 328.527 1337.65 326.124 1354.48 325.368C1383.87 324.132 1457.55 336.15 1458.79 337.798L1458.93 337.935L1458.86 338.141L1458.51 338.073L1458.24 338.279C1456.04 336.768 1384.76 324.819 1354.41 326.055C1337.24 326.742 1309.77 329.283 1309.5 329.351H1309.43H1309.36L1164.6 308.063L1142.9 296.664L1073.81 280.526L1016.2 316.235L967.168 345.008Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.168 345.008L966.824 344.39L1015.86 315.617L1073.75 279.77H1073.88L1143.31 296.046H1143.38L1165.01 307.376L1309.56 328.665C1310.8 328.527 1337.65 326.124 1354.48 325.368C1383.87 324.132 1457.55 336.15 1458.79 337.798L1458.93 337.935L1458.86 338.141L1458.51 338.073L1458.24 338.279C1456.04 336.768 1384.76 324.819 1354.41 326.055C1337.24 326.742 1309.77 329.283 1309.5 329.351H1309.43H1309.36L1164.6 308.063L1142.9 296.664L1073.81 280.526L1016.2 316.235L967.168 345.008Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.168 348.991L966.824 348.373L1013.73 320.355L1069.21 285.676H1069.35L1140.91 298.174H1140.98L1163.23 308.818L1308.26 333.403C1309.5 333.266 1335.18 331.068 1353.45 330.313C1385.17 329.008 1457.41 338.485 1458.79 340.27L1458.93 340.407L1458.86 340.613L1458.17 340.407L1458.51 340.476L1458.24 340.682C1456.52 339.446 1387.09 329.557 1353.45 330.931C1334.9 331.686 1308.53 333.952 1308.26 334.021H1308.19H1308.12L1162.95 309.368H1162.88L1140.63 298.724L1069.28 286.225L1014.07 320.905L967.168 348.991Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.168 348.991L966.824 348.373L1013.73 320.355L1069.21 285.676H1069.35L1140.91 298.174H1140.98L1163.23 308.818L1308.26 333.403C1309.5 333.266 1335.18 331.068 1353.45 330.313C1385.17 329.008 1457.41 338.485 1458.79 340.27L1458.93 340.407L1458.86 340.613L1458.17 340.407L1458.51 340.476L1458.24 340.682C1456.52 339.446 1387.09 329.557 1353.45 330.931C1334.9 331.686 1308.53 333.952 1308.26 334.021H1308.19H1308.12L1162.95 309.368H1162.88L1140.63 298.724L1069.28 286.225L1014.07 320.905L967.168 348.991Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.168 352.906L966.824 352.288L1011.6 325.025L1064.61 291.445H1064.75L1138.43 300.166L1161.37 310.123L1306.89 338.073C1308.12 338.004 1332.64 336.013 1352.28 335.188C1386.55 333.746 1457.28 340.888 1458.65 342.674L1458.79 342.811L1458.72 343.017L1458.38 342.948L1458.1 343.154C1456.25 341.85 1389.09 334.364 1352.28 335.944C1332.29 336.768 1307.09 338.759 1306.89 338.828H1306.82H1306.75L1161.1 310.81H1161.03L1138.09 300.853L1064.61 292.131L1011.8 325.574L967.168 352.906Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.168 352.906L966.824 352.288L1011.6 325.025L1064.61 291.445H1064.75L1138.43 300.166L1161.37 310.123L1306.89 338.073C1308.12 338.004 1332.64 336.013 1352.28 335.188C1386.55 333.746 1457.28 340.888 1458.65 342.674L1458.79 342.811L1458.72 343.017L1458.38 342.948L1458.1 343.154C1456.25 341.85 1389.09 334.364 1352.28 335.944C1332.29 336.768 1307.09 338.759 1306.89 338.828H1306.82H1306.75L1161.1 310.81H1161.03L1138.09 300.853L1064.61 292.131L1011.8 325.574L967.168 352.906Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.236 356.82L966.893 356.271L1009.54 329.763L1060.15 297.35H1060.29L1136.17 302.295H1136.24L1159.86 311.565L1305.72 342.88C1306.95 342.811 1330.37 341.094 1351.32 340.201C1387.58 338.691 1457.28 343.292 1458.72 345.146L1458.86 345.283L1458.79 345.489L1458.1 345.283L1458.44 345.352L1458.17 345.558C1456.25 344.253 1390.8 339.171 1351.32 340.82C1329.89 341.712 1305.92 343.429 1305.65 343.498H1305.58H1305.51L1159.45 312.183L1135.83 302.913L1060.15 297.968L1009.74 330.244L967.236 356.82Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.236 356.82L966.893 356.271L1009.54 329.763L1060.15 297.35H1060.29L1136.17 302.295H1136.24L1159.86 311.565L1305.72 342.88C1306.95 342.811 1330.37 341.094 1351.32 340.201C1387.58 338.691 1457.28 343.292 1458.72 345.146L1458.86 345.283L1458.79 345.489L1458.1 345.283L1458.44 345.352L1458.17 345.558C1456.25 344.253 1390.8 339.171 1351.32 340.82C1329.89 341.712 1305.92 343.429 1305.65 343.498H1305.58H1305.51L1159.45 312.183L1135.83 302.913L1060.15 297.968L1009.74 330.244L967.236 356.82Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.236 360.803L966.893 360.254L1007.41 334.502L1055.62 303.256H1055.75L1133.76 304.424H1133.83L1158.07 313.008L1304.55 347.687C1305.79 347.618 1328.04 346.176 1350.42 345.215C1389.64 343.566 1457.41 345.901 1458.86 347.687L1458.99 347.824L1458.93 348.03L1458.58 347.961L1458.31 348.168C1456.04 346.657 1390.25 344.253 1350.42 345.901C1327.56 346.863 1304.76 348.305 1304.55 348.374H1304.48H1304.41L1157.87 313.694L1133.56 305.042L1055.75 303.874L1007.75 335.051L967.236 360.803Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.236 360.803L966.893 360.254L1007.41 334.502L1055.62 303.256H1055.75L1133.76 304.424H1133.83L1158.07 313.008L1304.55 347.687C1305.79 347.618 1328.04 346.176 1350.42 345.215C1389.64 343.566 1457.41 345.901 1458.86 347.687L1458.99 347.824L1458.93 348.03L1458.58 347.961L1458.31 348.168C1456.04 346.657 1390.25 344.253 1350.42 345.901C1327.56 346.863 1304.76 348.305 1304.55 348.374H1304.48H1304.41L1157.87 313.694L1133.56 305.042L1055.75 303.874L1007.75 335.051L967.236 360.803Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.236 364.717L966.893 364.168L1005.28 339.171L1051.08 309.025H1051.15L1131.29 306.346H1131.36L1156.22 314.244L1303.18 352.288C1304.41 352.219 1325.57 351.052 1349.26 350.022C1388.61 348.373 1457.21 348.099 1458.72 350.022L1458.86 350.159L1458.79 350.365L1458.1 350.159L1458.44 350.228L1458.17 350.434C1455.9 348.992 1392.59 348.854 1349.26 350.64C1324.95 351.67 1303.32 352.837 1303.11 352.906H1303.04H1302.97L1155.95 314.862L1131.09 307.033L1051.15 309.711L1005.55 339.721L967.236 364.717Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.236 364.717L966.893 364.168L1005.28 339.171L1051.08 309.025H1051.15L1131.29 306.346H1131.36L1156.22 314.244L1303.18 352.288C1304.41 352.219 1325.57 351.052 1349.26 350.022C1388.61 348.373 1457.21 348.099 1458.72 350.022L1458.86 350.159L1458.79 350.365L1458.1 350.159L1458.44 350.228L1458.17 350.434C1455.9 348.992 1392.59 348.854 1349.26 350.64C1324.95 351.67 1303.32 352.837 1303.11 352.906H1303.04H1302.97L1155.95 314.862L1131.09 307.033L1051.15 309.711L1005.55 339.721L967.236 364.717Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.305 368.7L966.893 368.151L1046.48 314.999H1046.55L1128.89 308.544H1128.96L1301.94 357.095C1303.66 357.026 1341.63 355.241 1379.4 353.798C1457.35 350.846 1458.31 352.082 1458.72 352.562C1458.79 352.7 1458.86 352.837 1458.79 352.974L1458.1 352.768C1458.03 352.906 1458.1 353.043 1458.17 353.043C1455.15 351.12 1344.31 355.79 1301.87 357.781H1301.8H1301.74L1128.75 309.23L1046.62 315.686L967.305 368.7Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.305 368.7L966.893 368.151L1046.48 314.999H1046.55L1128.89 308.544H1128.96L1301.94 357.095C1303.66 357.026 1341.63 355.241 1379.4 353.798C1457.35 350.846 1458.31 352.082 1458.72 352.562C1458.79 352.7 1458.86 352.837 1458.79 352.974L1458.1 352.768C1458.03 352.906 1458.1 353.043 1458.17 353.043C1455.15 351.12 1344.31 355.79 1301.87 357.781H1301.8H1301.74L1128.75 309.23L1046.62 315.686L967.305 368.7Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.236 375.018L966.893 374.469L1047.17 324.27L1129.37 318.158H1129.44L1302.35 364.031C1303.93 363.962 1341.98 362.314 1379.68 360.94C1457.48 358.125 1458.38 359.361 1458.72 359.773L1458.86 359.91L1458.79 360.116L1458.17 359.91L1458.51 359.979L1458.31 360.185C1455.35 358.331 1344.72 362.794 1302.35 364.649H1302.29H1302.22L1129.3 318.845L1047.38 324.888L967.236 375.018Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.236 375.018L966.893 374.469L1047.17 324.27L1129.37 318.158H1129.44L1302.35 364.031C1303.93 363.962 1341.98 362.314 1379.68 360.94C1457.48 358.125 1458.38 359.361 1458.72 359.773L1458.86 359.91L1458.79 360.116L1458.17 359.91L1458.51 359.979L1458.31 360.185C1455.35 358.331 1344.72 362.794 1302.35 364.649H1302.29H1302.22L1129.3 318.845L1047.38 324.888L967.236 375.018Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.168 381.404L966.824 380.855L1047.72 333.609H1047.79L1129.71 327.909H1129.78L1302.7 371.035C1328.59 369.936 1456.93 364.717 1458.72 367.052L1458.79 367.189V367.395L1458.17 367.189L1458.44 367.258L1458.24 367.395C1455.49 365.747 1355.23 369.387 1302.63 371.584H1302.56H1302.49L1129.71 328.459L1047.93 334.158L967.168 381.404Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.168 381.404L966.824 380.855L1047.72 333.609H1047.79L1129.71 327.909H1129.78L1302.7 371.035C1328.59 369.936 1456.93 364.717 1458.72 367.052L1458.79 367.189V367.395L1458.17 367.189L1458.44 367.258L1458.24 367.395C1455.49 365.747 1355.23 369.387 1302.63 371.584H1302.56H1302.49L1129.71 328.459L1047.93 334.158L967.168 381.404Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.03 387.722L966.755 387.173L1048.34 342.88H1048.41L1130.13 337.523H1130.19L1302.97 377.971C1328.79 376.941 1456.93 372.065 1458.65 374.194L1458.72 374.331V374.537L1458.17 374.331L1458.44 374.4L1458.24 374.537C1455.56 372.958 1355.51 376.46 1302.97 378.452H1302.9H1302.83L1130.13 338.141L1048.54 343.498L967.03 387.722Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.03 387.722L966.755 387.173L1048.34 342.88H1048.41L1130.13 337.523H1130.19L1302.97 377.971C1328.79 376.941 1456.93 372.065 1458.65 374.194L1458.72 374.331V374.537L1458.17 374.331L1458.44 374.4L1458.24 374.537C1455.56 372.958 1355.51 376.46 1302.97 378.452H1302.9H1302.83L1130.13 338.141L1048.54 343.498L967.03 387.722Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M966.961 394.109L966.687 393.628L1048.96 352.288H1049.02L1130.61 347.275H1130.67L1303.45 385.044C1329.2 384.083 1457.21 379.55 1458.72 381.542L1458.79 381.679V381.885L1458.24 381.748L1458.51 381.817L1458.31 381.954C1455.7 380.512 1355.85 383.739 1303.45 385.662H1303.38H1303.32L1130.61 347.893L1049.09 352.768L966.961 394.109Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M966.961 394.109L966.687 393.628L1048.96 352.288H1049.02L1130.61 347.275H1130.67L1303.45 385.044C1329.2 384.083 1457.21 379.55 1458.72 381.542L1458.79 381.679V381.885L1458.24 381.748L1458.51 381.817L1458.31 381.954C1455.7 380.512 1355.85 383.739 1303.45 385.662H1303.38H1303.32L1130.61 347.893L1049.09 352.768L966.961 394.109Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M966.893 400.427L966.687 399.946L1049.57 361.558H1049.64L1131.02 356.889H1131.09L1303.8 391.98C1329.34 391.087 1457.21 386.83 1458.65 388.752L1458.72 388.89V389.096L1458.17 388.958L1458.44 389.027L1458.24 389.164C1455.7 387.791 1356.06 390.813 1303.73 392.598L1130.95 357.507L1049.71 362.108L966.893 400.427Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M966.893 400.427L966.687 399.946L1049.57 361.558H1049.64L1131.02 356.889H1131.09L1303.8 391.98C1329.34 391.087 1457.21 386.83 1458.65 388.752L1458.72 388.89V389.096L1458.17 388.958L1458.44 389.027L1458.24 389.164C1455.7 387.791 1356.06 390.813 1303.73 392.598L1130.95 357.507L1049.71 362.108L966.893 400.427Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M966.824 406.813L966.618 406.332L1050.19 370.898H1050.26L1131.43 366.64L1304.14 399.053C1329.69 398.229 1457.28 394.315 1458.65 396.032L1458.72 396.169V396.306L1458.44 396.238L1458.31 396.375C1455.84 395.07 1356.4 397.886 1304.14 399.534L1131.43 367.121L1050.4 371.447L966.824 406.813Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M966.824 406.813L966.618 406.332L1050.19 370.898H1050.26L1131.43 366.64L1304.14 399.053C1329.69 398.229 1457.28 394.315 1458.65 396.032L1458.72 396.169V396.306L1458.44 396.238L1458.31 396.375C1455.84 395.07 1356.4 397.886 1304.14 399.534L1131.43 367.121L1050.4 371.447L966.824 406.813Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M966.755 413.131L966.549 412.65L1050.74 380.168H1050.81L1131.77 376.254L1304.41 405.92C1329.89 405.165 1457.35 401.594 1458.58 403.173L1458.65 403.242V403.379L1458.17 403.242L1458.38 403.311L1458.24 403.448C1455.9 402.281 1356.6 404.821 1304.41 406.332L1131.77 376.666L1050.88 380.58L966.755 413.131Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M966.755 413.131L966.549 412.65L1050.74 380.168H1050.81L1131.77 376.254L1304.41 405.92C1329.89 405.165 1457.35 401.594 1458.58 403.173L1458.65 403.242V403.379L1458.17 403.242L1458.38 403.311L1458.24 403.448C1455.9 402.281 1356.6 404.821 1304.41 406.332L1131.77 376.666L1050.88 380.58L966.755 413.131Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M966.687 419.517L966.549 419.105L1051.43 389.577L1132.19 386.006L1304.83 412.925C1305.17 412.925 1343.35 411.895 1381.19 411.071C1457.83 409.423 1458.38 410.109 1458.58 410.453L1458.65 410.521V410.659L1458.24 410.521L1458.44 410.59L1458.31 410.727C1456.04 409.629 1356.95 411.963 1304.83 413.337L1132.19 386.418L1051.5 389.989L966.687 419.517Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M966.687 419.517L966.549 419.105L1051.43 389.577L1132.19 386.006L1304.83 412.925C1305.17 412.925 1343.35 411.895 1381.19 411.071C1457.83 409.423 1458.38 410.109 1458.58 410.453L1458.65 410.521V410.659L1458.24 410.521L1458.44 410.59L1458.31 410.727C1456.04 409.629 1356.95 411.963 1304.83 413.337L1132.19 386.418L1051.5 389.989L966.687 419.517Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M489.2 197V196.313L405.036 186.03L308.208 64L308.002 64.1373L156.787 162.75L94.0215 115.984L25.9678 180.398L-45.7253 146.406L-46.0001 147.024L26.1052 181.223L26.2426 181.017L94.0901 116.877L156.719 163.574L156.925 163.505L308.071 64.8927L404.692 186.716L489.2 197Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M489.2 197V196.313L405.036 186.03L308.208 64L308.002 64.1373L156.787 162.75L94.0215 115.984L25.9678 180.398L-45.7253 146.406L-46.0001 147.024L26.1052 181.223L26.2426 181.017L94.0901 116.877L156.719 163.574L156.925 163.505L308.071 64.8927L404.692 186.716L489.2 197Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M456.631 196.187L456.7 195.5L406.134 188.914L310.063 69.0817L247.091 105.134L157.818 163.986L94.7083 122.783L85.3689 131.573L41.3503 168.038L26.2426 181.909L-45.7253 152.312L-45.9314 152.93L26.4486 182.665L41.7624 168.518L85.8496 132.054L94.7769 123.676L157.818 164.81L158.024 164.673L247.434 105.752L309.925 69.9058L405.722 189.6L456.631 196.187Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M456.631 196.187L456.7 195.5L406.134 188.914L310.063 69.0817L247.091 105.134L157.818 163.986L94.7083 122.783L85.3689 131.573L41.3503 168.038L26.2426 181.909L-45.7253 152.312L-45.9314 152.93L26.4486 182.665L41.7624 168.518L85.8496 132.054L94.7769 123.676L157.818 164.81L158.024 164.673L247.434 105.752L309.925 69.9058L405.722 189.6L456.631 196.187Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.964 196.536L446.032 195.85L407.164 191.867L311.917 74.0948L248.464 105.478L158.848 165.222L95.3949 129.65L85.9182 138.371L41.8996 169.617L26.5858 183.42L-45.6568 158.218L-45.9314 158.836L26.7232 184.175L42.3803 170.166L86.3989 138.921L95.4636 130.474L158.916 166.046L248.807 106.096L311.779 74.9875L406.752 192.485H406.89L445.964 196.536Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.964 196.536L446.032 195.85L407.164 191.867L311.917 74.0948L248.464 105.478L158.848 165.222L95.3949 129.65L85.9182 138.371L41.8996 169.617L26.5858 183.42L-45.6568 158.218L-45.9314 158.836L26.7232 184.175L42.3803 170.166L86.3989 138.921L95.4636 130.474L158.916 166.046L248.807 106.096L311.779 74.9875L406.752 192.485H406.89L445.964 196.536Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.895 200.588L445.964 199.901L408.194 194.751L313.771 79.1765L249.769 105.89L159.878 166.458L96.0816 136.517L86.5363 145.17L42.5177 171.265L26.8606 184.862L-45.6567 164.123L-45.8628 164.741L27.0666 185.618L27.2039 185.549L42.9297 171.815L87.017 145.719L96.219 137.273L159.946 167.282L250.112 106.439L313.565 80.0005L407.782 195.369L407.92 195.438L445.895 200.588Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.895 200.588L445.964 199.901L408.194 194.751L313.771 79.1765L249.769 105.89L159.878 166.458L96.0816 136.517L86.5363 145.17L42.5177 171.265L26.8606 184.862L-45.6567 164.123L-45.8628 164.741L27.0666 185.618L27.2039 185.549L42.9297 171.815L87.017 145.719L96.219 137.273L159.946 167.282L250.112 106.439L313.565 80.0005L407.782 195.369L407.92 195.438L445.895 200.588Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.827 204.64L445.964 203.953L409.225 197.704L315.625 84.1895L315.419 84.2582L251.142 106.233L251.074 106.302L160.976 167.694L96.7684 143.316L87.1543 151.968L43.0671 172.913L27.204 186.373L-45.6567 170.029L-45.7941 170.716L27.3413 187.128L43.4791 173.463L87.5663 152.586L96.9057 144.14L161.045 168.45L161.182 168.381L251.417 106.851L315.419 85.0135L408.812 198.322L445.827 204.64Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.827 204.64L445.964 203.953L409.225 197.704L315.625 84.1895L315.419 84.2582L251.142 106.233L251.074 106.302L160.976 167.694L96.7684 143.316L87.1543 151.968L43.0671 172.913L27.204 186.373L-45.6567 170.029L-45.7941 170.716L27.3413 187.128L43.4791 173.463L87.5663 152.586L96.9057 144.14L161.045 168.45L161.182 168.381L251.417 106.851L315.419 85.0135L408.812 198.322L445.827 204.64Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.758 208.691L445.895 208.005L410.255 200.588L317.479 89.2712L252.447 106.645L162.006 168.93L97.455 150.183L87.7723 158.836L43.6852 174.493L43.6165 174.561L27.4786 187.884L-45.6567 175.935L-45.7254 176.622L27.6846 188.639L27.822 188.502L44.0285 175.111L88.1157 159.385H88.1844L97.5924 150.938L162.075 169.686L162.212 169.617L252.79 107.263L317.273 90.0266L409.843 201.206H409.98L445.758 208.691Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.758 208.691L445.895 208.005L410.255 200.588L317.479 89.2712L252.447 106.645L162.006 168.93L97.455 150.183L87.7723 158.836L43.6852 174.493L43.6165 174.561L27.4786 187.884L-45.6567 175.935L-45.7254 176.622L27.6846 188.639L27.822 188.502L44.0285 175.111L88.1157 159.385H88.1844L97.5924 150.938L162.075 169.686L162.212 169.617L252.79 107.263L317.273 90.0266L409.843 201.206H409.98L445.758 208.691Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.689 212.674L445.895 212.056L411.285 203.472L319.333 94.2842L319.196 94.3529L253.82 106.989L253.752 107.057L163.036 170.166L98.1418 156.981L88.3217 165.634L44.3032 176.072L27.7533 189.395L-45.6567 181.841L-45.7254 182.527L27.9593 190.081L44.5779 176.759L88.6651 166.252L98.2791 157.737L163.174 170.922L163.311 170.853L254.095 107.675L319.127 95.0396L410.873 204.09L411.01 204.159L445.689 212.674Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.689 212.674L445.895 212.056L411.285 203.472L319.333 94.2842L319.196 94.3529L253.82 106.989L253.752 107.057L163.036 170.166L98.1418 156.981L88.3217 165.634L44.3032 176.072L27.7533 189.395L-45.6567 181.841L-45.7254 182.527L27.9593 190.081L44.5779 176.759L88.6651 166.252L98.2791 157.737L163.174 170.922L163.311 170.853L254.095 107.675L319.127 95.0396L410.873 204.09L411.01 204.159L445.689 212.674Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.621 216.726L445.827 216.108L412.315 206.425L321.187 99.3659H321.05L255.194 107.401H255.125L164.067 171.403L98.7599 163.849L88.9398 172.433L44.9213 177.72L28.0967 190.905L-45.6567 187.746V188.433L28.3027 191.592L45.196 178.338L89.2145 173.119L99.0345 164.535L164.273 172.158L164.341 172.089L255.4 108.019L320.913 100.053L411.903 207.043L445.621 216.726Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.621 216.726L445.827 216.108L412.315 206.425L321.187 99.3659H321.05L255.194 107.401H255.125L164.067 171.403L98.7599 163.849L88.9398 172.433L44.9213 177.72L28.0967 190.905L-45.6567 187.746V188.433L28.3027 191.592L45.196 178.338L89.2145 173.119L99.0345 164.535L164.273 172.158L164.341 172.089L255.4 108.019L320.913 100.053L411.903 207.043L445.621 216.726Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.552 220.777L445.758 220.091L413.345 209.309L323.042 104.379L256.499 107.744L256.43 107.813L165.097 172.639L99.4465 170.647L89.5578 179.231L45.4706 179.3L45.4019 179.368L28.3713 192.416L-45.588 193.652V194.339L28.646 193.103L45.7453 179.987L89.7638 179.918L99.7212 171.334L165.303 173.325L256.773 108.431L322.767 105.134L412.933 209.927H413.001L445.552 220.777Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.552 220.777L445.758 220.091L413.345 209.309L323.042 104.379L256.499 107.744L256.43 107.813L165.097 172.639L99.4465 170.647L89.5578 179.231L45.4706 179.3L45.4019 179.368L28.3713 192.416L-45.588 193.652V194.339L28.646 193.103L45.7453 179.987L89.7638 179.918L99.7212 171.334L165.303 173.325L256.773 108.431L322.767 105.134L412.933 209.927H413.001L445.552 220.777Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.483 224.829L445.758 224.142L414.375 212.262L324.896 109.461L257.872 108.087L257.803 108.156L166.127 173.875L100.133 177.514L100.065 177.583L90.1072 186.03L46.0886 180.948L28.7147 193.858L-45.588 199.558L-45.5194 200.245L28.9207 194.545L28.9894 194.476L46.2946 181.635L90.3819 186.785L100.408 178.201L166.401 174.561L166.47 174.493L258.078 108.774L324.621 110.147L413.963 212.812L445.483 224.829Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.483 224.829L445.758 224.142L414.375 212.262L324.896 109.461L257.872 108.087L257.803 108.156L166.127 173.875L100.133 177.514L100.065 177.583L90.1072 186.03L46.0886 180.948L28.7147 193.858L-45.588 199.558L-45.5194 200.245L28.9207 194.545L28.9894 194.476L46.2946 181.635L90.3819 186.785L100.408 178.201L166.401 174.561L166.47 174.493L258.078 108.774L324.621 110.147L413.963 212.812L445.483 224.829Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.415 228.812L445.689 228.194L415.405 215.146L326.75 114.474H326.613L259.246 108.499L259.108 108.568L167.226 175.111L100.82 184.313L90.7253 192.897L46.7068 182.527L28.9895 195.369L-45.588 205.532L-45.5193 206.15L29.2642 196.056L46.8441 183.283L90.9313 193.652L101.095 184.999L167.5 175.797L167.569 175.729L259.452 109.186L326.407 115.16L414.993 215.696L415.062 215.764L445.415 228.812Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.415 228.812L445.689 228.194L415.405 215.146L326.75 114.474H326.613L259.246 108.499L259.108 108.568L167.226 175.111L100.82 184.313L90.7253 192.897L46.7068 182.527L28.9895 195.369L-45.588 205.532L-45.5193 206.15L29.2642 196.056L46.8441 183.283L90.9313 193.652L101.095 184.999L167.5 175.797L167.569 175.729L259.452 109.186L326.407 115.16L414.993 215.696L415.062 215.764L445.415 228.812Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.346 232.864L445.689 232.246L416.435 218.099L328.604 119.555H328.535L260.55 108.843L260.482 108.911L168.256 176.347L101.507 191.18L91.3434 199.695L47.3248 184.175L29.2642 196.88L-45.588 211.438L-45.4507 212.056L29.5388 197.498H29.6075L47.3935 184.931L91.4807 200.451L91.618 200.313L101.85 191.798L168.53 177.034L168.599 176.965L260.756 109.598L328.261 120.173L416.023 218.649H416.092L445.346 232.864Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.346 232.864L445.689 232.246L416.435 218.099L328.604 119.555H328.535L260.55 108.843L260.482 108.911L168.256 176.347L101.507 191.18L91.3434 199.695L47.3248 184.175L29.2642 196.88L-45.588 211.438L-45.4507 212.056L29.5388 197.498H29.6075L47.3935 184.931L91.4807 200.451L91.618 200.313L101.85 191.798L168.53 177.034L168.599 176.965L260.756 109.598L328.261 120.173L416.023 218.649H416.092L445.346 232.864Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.277 236.915L445.62 236.297L417.465 220.984L330.458 124.568H330.389L261.924 109.186L261.786 109.323L169.286 177.583L102.193 197.979L91.9613 206.494L47.9427 185.755L29.6075 198.391L-45.588 217.344L-45.382 218.031L29.8821 199.009H29.9508L48.0114 186.579L92.03 207.318L102.537 198.597L169.629 178.201L262.061 109.941L330.115 125.187L417.053 221.533L445.277 236.915Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.277 236.915L445.62 236.297L417.465 220.984L330.458 124.568H330.389L261.924 109.186L261.786 109.323L169.286 177.583L102.193 197.979L91.9613 206.494L47.9427 185.755L29.6075 198.391L-45.588 217.344L-45.382 218.031L29.8821 199.009H29.9508L48.0114 186.579L92.03 207.318L102.537 198.597L169.629 178.201L262.061 109.941L330.115 125.187L417.053 221.533L445.277 236.915Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.208 240.967L445.62 240.349L418.495 223.868L332.312 129.65L332.244 129.582L263.297 109.598L170.316 178.819L102.88 204.846H102.811L92.5107 213.292L48.4922 187.403L29.8821 199.901L-45.5194 223.25L-45.3134 223.936L30.2255 200.519V200.451L48.5608 188.158L92.5793 214.116L103.223 205.464L170.659 179.437H170.728L263.435 110.353L331.969 130.268L418.014 224.417L418.083 224.486L445.208 240.967Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.208 240.967L445.62 240.349L418.495 223.868L332.312 129.65L332.244 129.582L263.297 109.598L170.316 178.819L102.88 204.846H102.811L92.5107 213.292L48.4922 187.403L29.8821 199.901L-45.5194 223.25L-45.3134 223.936L30.2255 200.519V200.451L48.5608 188.158L92.5793 214.116L103.223 205.464L170.659 179.437H170.728L263.435 110.353L331.969 130.268L418.014 224.417L418.083 224.486L445.208 240.967Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.14 244.95L445.552 244.401L419.525 226.821L334.166 134.663H334.098L264.602 109.941L171.414 180.055L103.567 211.644L103.498 211.713L93.1287 220.091L49.1102 188.982L30.2254 201.343L-45.5194 229.155L-45.3134 229.842L30.5002 201.961L49.1102 189.807L93.1974 220.983L103.91 212.262L171.758 180.673L264.739 110.697L333.754 135.281L419.044 227.37H419.113L445.14 244.95Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.14 244.95L445.552 244.401L419.525 226.821L334.166 134.663H334.098L264.602 109.941L171.414 180.055L103.567 211.644L103.498 211.713L93.1287 220.091L49.1102 188.982L30.2254 201.343L-45.5194 229.155L-45.3134 229.842L30.5002 201.961L49.1102 189.807L93.1974 220.983L103.91 212.262L171.758 180.673L264.739 110.697L333.754 135.281L419.044 227.37H419.113L445.14 244.95Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.071 249.001L445.483 248.452L420.555 229.705L336.021 139.745L335.952 139.676L265.975 110.353L265.838 110.422L172.445 181.36L104.254 218.511L93.7468 226.958L49.7283 190.631L30.5002 202.854L-45.5193 235.061L-45.2447 235.748L30.7749 203.472L49.6596 191.455L93.7468 227.851L104.666 219.061L172.788 181.909L266.044 111.109L335.608 140.294L420.075 230.254L445.071 249.001Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.071 249.001L445.483 248.452L420.555 229.705L336.021 139.745L335.952 139.676L265.975 110.353L265.838 110.422L172.445 181.36L104.254 218.511L93.7468 226.958L49.7283 190.631L30.5002 202.854L-45.5193 235.061L-45.2447 235.748L30.7749 203.472L49.6596 191.455L93.7468 227.851L104.666 219.061L172.788 181.909L266.044 111.109L335.608 140.294L420.075 230.254L445.071 249.001Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.002 253.053L445.483 252.504L421.585 232.658L337.875 144.758H337.806L267.349 110.697L173.475 182.527L104.94 225.31L94.3649 233.756L50.3463 192.21L30.7749 204.365L-45.5193 241.036L-45.176 241.654L31.1182 204.983L50.209 193.103L94.2962 234.649L105.352 225.859L173.887 183.145L267.417 111.521L337.463 145.307L421.105 233.138L445.002 253.053Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.002 253.053L445.483 252.504L421.585 232.658L337.875 144.758H337.806L267.349 110.697L173.475 182.527L104.94 225.31L94.3649 233.756L50.3463 192.21L30.7749 204.365L-45.5193 241.036L-45.176 241.654L31.1182 204.983L50.209 193.103L94.2962 234.649L105.352 225.859L173.887 183.145L267.417 111.521L337.463 145.307L421.105 233.138L445.002 253.053Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M444.934 257.036L445.414 256.555L422.615 235.542L339.729 149.84L339.66 149.771L268.654 111.04L268.516 111.178L174.505 183.763L105.627 232.177L94.9142 240.555L50.8956 193.858L31.1182 205.876L-45.4508 246.941L-45.1761 247.559L31.4615 206.494L50.8269 194.751L94.8455 241.516L106.039 232.726L174.917 184.313L268.722 111.864L339.317 150.389L422.135 236.023L444.934 257.036Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M444.934 257.036L445.414 256.555L422.615 235.542L339.729 149.84L339.66 149.771L268.654 111.04L268.516 111.178L174.505 183.763L105.627 232.177L94.9142 240.555L50.8956 193.858L31.1182 205.876L-45.4508 246.941L-45.1761 247.559L31.4615 206.494L50.8269 194.751L94.8455 241.516L106.039 232.726L174.917 184.313L268.722 111.864L339.317 150.389L422.135 236.023L444.934 257.036Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M444.934 261.088L445.414 260.607L341.583 154.853H341.514L270.027 111.452L269.821 111.589L95.5323 247.353L51.5136 195.438L51.239 195.644L-45.4508 252.847L-45.1074 253.465L51.3763 196.33L95.3949 248.315L95.6696 248.109L270.096 112.276L341.171 155.402L444.934 261.088Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M444.934 261.088L445.414 260.607L341.583 154.853H341.514L270.027 111.452L269.821 111.589L95.5323 247.353L51.5136 195.438L51.239 195.644L-45.4508 252.847L-45.1074 253.465L51.3763 196.33L95.3949 248.315L95.6696 248.109L270.096 112.276L341.171 155.402L444.934 261.088Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.002 262.598L445.483 262.118L343.3 160.14H343.231L270.851 116.396L270.714 116.534L102.811 249.345L51.239 198.94L50.9644 199.077L-45.4507 255.457L-45.1073 256.075L51.1017 199.764L102.811 250.238L103.017 250.032L270.92 117.221L342.819 160.69L445.002 262.598Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.002 262.598L445.483 262.118L343.3 160.14H343.231L270.851 116.396L270.714 116.534L102.811 249.345L51.239 198.94L50.9644 199.077L-45.4507 255.457L-45.1073 256.075L51.1017 199.764L102.811 250.238L103.017 250.032L270.92 117.221L342.819 160.69L445.002 262.598Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.071 264.178L445.552 263.697L344.948 165.428L271.744 121.341L271.538 121.478L110.159 251.268L50.9643 202.373L50.7583 202.511L-45.4507 258.066L-45.1074 258.616L50.8957 203.198L110.159 252.16L110.365 251.954L271.744 122.165L344.536 165.978L445.071 264.178Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.071 264.178L445.552 263.697L344.948 165.428L271.744 121.341L271.538 121.478L110.159 251.268L50.9643 202.373L50.7583 202.511L-45.4507 258.066L-45.1074 258.616L50.8957 203.198L110.159 252.16L110.365 251.954L271.744 122.165L344.536 165.978L445.071 264.178Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.14 265.689L445.62 265.208L346.665 170.716H346.596L272.568 126.285L272.362 126.423L117.507 253.19L50.621 205.807L-45.4507 260.607L-45.1074 261.225L50.621 206.631L117.507 254.083L117.713 253.877L272.637 127.109L346.253 171.265L445.14 265.689Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.14 265.689L445.62 265.208L346.665 170.716H346.596L272.568 126.285L272.362 126.423L117.507 253.19L50.621 205.807L-45.4507 260.607L-45.1074 261.225L50.621 206.631L117.507 254.083L117.713 253.877L272.637 127.109L346.253 171.265L445.14 265.689Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.209 267.2L445.689 266.719L348.381 176.004H348.313L273.392 131.23L273.186 131.367L124.855 255.113L50.3463 209.241L50.209 209.378L-45.4507 263.217L-45.1073 263.835L50.3463 210.065L124.855 255.937L273.461 132.054L347.969 176.553L445.209 267.2Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.209 267.2L445.689 266.719L348.381 176.004H348.313L273.392 131.23L273.186 131.367L124.855 255.113L50.3463 209.241L50.209 209.378L-45.4507 263.217L-45.1073 263.835L50.3463 210.065L124.855 255.937L273.461 132.054L347.969 176.553L445.209 267.2Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.277 268.779L445.758 268.23L350.098 181.36L274.216 136.174L274.079 136.311L132.134 257.036L50.0716 212.674L49.9342 212.812L-45.4507 265.826L-45.1074 266.444L50.0716 213.498L132.272 257.86L132.409 257.723L274.285 136.998L349.686 181.909L445.277 268.779Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.277 268.779L445.758 268.23L350.098 181.36L274.216 136.174L274.079 136.311L132.134 257.036L50.0716 212.674L49.9342 212.812L-45.4507 265.826L-45.1074 266.444L50.0716 213.498L132.272 257.86L132.409 257.723L274.285 136.998L349.686 181.909L445.277 268.779Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.346 270.29L445.827 269.74L351.746 186.648L275.04 141.118L139.482 258.959L49.797 216.108L49.6596 216.245L-45.4507 268.436L-45.1073 269.054L49.797 216.932L139.62 259.783L139.757 259.646L275.177 141.942L351.334 187.197L445.346 270.29Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.346 270.29L445.827 269.74L351.746 186.648L275.04 141.118L139.482 258.959L49.797 216.108L49.6596 216.245L-45.4507 268.436L-45.1073 269.054L49.797 216.932L139.62 259.783L139.757 259.646L275.177 141.942L351.334 187.197L445.346 270.29Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.414 271.801L445.895 271.32L353.463 191.935L275.933 146.063L275.727 146.2L146.83 260.882L49.5222 219.541L-45.4507 271.045L-45.1074 271.595L49.5222 220.297L146.967 261.706L147.105 261.568L276.001 146.887L353.051 192.485L445.414 271.801Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.414 271.801L445.895 271.32L353.463 191.935L275.933 146.063L275.727 146.2L146.83 260.882L49.5222 219.541L-45.4507 271.045L-45.1074 271.595L49.5222 220.297L146.967 261.706L147.105 261.568L276.001 146.887L353.051 192.485L445.414 271.801Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M-45.1073 274.204L49.3162 223.73L154.315 263.629L154.453 263.491L276.826 151.831L354.768 197.772L445.483 273.38L445.964 272.831L355.18 197.223L276.757 151.007L154.178 262.873L49.2476 222.975L49.1102 223.112L-45.4507 273.586L-45.1073 274.204Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M-45.1073 274.204L49.3162 223.73L154.315 263.629L154.453 263.491L276.826 151.831L354.768 197.772L445.483 273.38L445.964 272.831L355.18 197.223L276.757 151.007L154.178 262.873L49.2476 222.975L49.1102 223.112L-45.4507 273.586L-45.1073 274.204Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M-45.1074 276.814L49.0415 227.164L161.663 265.551L161.8 265.414L277.718 156.775L356.485 203.06L445.62 274.891L446.032 274.341L356.828 202.511L277.581 155.951L277.375 156.157L161.457 264.796L48.9728 226.477L48.8355 226.546L-45.4507 276.196L-45.1074 276.814Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M-45.1074 276.814L49.0415 227.164L161.663 265.551L161.8 265.414L277.718 156.775L356.485 203.06L445.62 274.891L446.032 274.341L356.828 202.511L277.581 155.951L277.375 156.157L161.457 264.796L48.9728 226.477L48.8355 226.546L-45.4507 276.196L-45.1074 276.814Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M-45.1074 279.423L48.7668 230.598L169.011 267.474L169.148 267.337L278.542 161.72L358.133 208.348L445.689 276.402L446.101 275.852L358.545 207.799L278.405 160.896L278.268 161.102L168.805 266.719L48.6982 229.911L48.5608 229.979L-45.4507 278.805L-45.1074 279.423Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M-45.1074 279.423L48.7668 230.598L169.011 267.474L169.148 267.337L278.542 161.72L358.133 208.348L445.689 276.402L446.101 275.852L358.545 207.799L278.405 160.896L278.268 161.102L168.805 266.719L48.6982 229.911L48.5608 229.979L-45.4507 278.805L-45.1074 279.423Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M-45.1073 282.033L48.4922 234.031L176.359 269.397L176.496 269.26L279.366 166.733L359.85 213.636L445.758 277.981L446.17 277.432L360.262 213.086L279.298 165.84L279.092 166.046L176.153 268.642L48.4236 233.344L48.2862 233.413L-45.4507 281.415L-45.1073 282.033Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M-45.1073 282.033L48.4922 234.031L176.359 269.397L176.496 269.26L279.366 166.733L359.85 213.636L445.758 277.981L446.17 277.432L360.262 213.086L279.298 165.84L279.092 166.046L176.153 268.642L48.4236 233.344L48.2862 233.413L-45.4507 281.415L-45.1073 282.033Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M-45.1073 284.573L48.2175 237.465L183.707 271.32L183.844 271.182L280.259 171.677L361.566 218.923L445.826 279.492L446.239 278.942L361.91 218.374L280.122 170.784L183.501 270.564L48.1488 236.778L48.0115 236.847L-45.4507 283.955L-45.1073 284.573Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M-45.1073 284.573L48.2175 237.465L183.707 271.32L183.844 271.182L280.259 171.677L361.566 218.923L445.826 279.492L446.239 278.942L361.91 218.374L280.122 170.784L183.501 270.564L48.1488 236.778L48.0115 236.847L-45.4507 283.955L-45.1073 284.573Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M-45.1073 287.183L47.9428 240.898L191.055 273.243L191.192 273.105L281.083 176.621L363.215 224.28L445.895 281.002L446.307 280.453L363.627 223.662L280.946 175.729L280.74 175.935L190.849 272.487L47.8741 240.211L-45.4507 286.565L-45.1073 287.183Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M-45.1073 287.183L47.9428 240.898L191.055 273.243L191.192 273.105L281.083 176.621L363.215 224.28L445.895 281.002L446.307 280.453L363.627 223.662L280.946 175.729L280.74 175.935L190.849 272.487L47.8741 240.211L-45.4507 286.565L-45.1073 287.183Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M-45.1074 289.793L47.6681 244.332L198.402 275.165L198.54 275.028L281.907 181.566L364.931 229.567L445.964 282.582L446.376 281.964L365.275 228.949L281.77 180.673L281.632 180.879L198.196 274.41L47.5994 243.645L47.4621 243.714L-45.4508 289.174L-45.1074 289.793Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M-45.1074 289.793L47.6681 244.332L198.402 275.165L198.54 275.028L281.907 181.566L364.931 229.567L445.964 282.582L446.376 281.964L365.275 228.949L281.77 180.673L281.632 180.879L198.196 274.41L47.5994 243.645L47.4621 243.714L-45.4508 289.174L-45.1074 289.793Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M-45.1073 292.402L47.3934 247.765L205.75 277.088L205.888 276.882L282.8 186.51L366.648 234.855L446.033 284.093L446.445 283.475L366.991 234.237L282.594 185.617L282.457 185.823L205.476 276.333L47.3248 247.079L47.1874 247.147L-45.4507 291.784L-45.1073 292.402Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M-45.1073 292.402L47.3934 247.765L205.75 277.088L205.888 276.882L282.8 186.51L366.648 234.855L446.033 284.093L446.445 283.475L366.991 234.237L282.594 185.617L282.457 185.823L205.476 276.333L47.3248 247.079L47.1874 247.147L-45.4507 291.784L-45.1073 292.402Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M-45.1074 295.012L47.1187 251.199L213.098 279.011L283.624 191.455L368.365 240.143L446.17 285.604L446.513 285.054L368.708 239.525L283.487 190.562L283.281 190.768L212.823 278.256L47.05 250.512L46.9127 250.581L-45.4507 294.394L-45.1074 295.012Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M-45.1074 295.012L47.1187 251.199L213.098 279.011L283.624 191.455L368.365 240.143L446.17 285.604L446.513 285.054L368.708 239.525L283.487 190.562L283.281 190.768L212.823 278.256L47.05 250.512L46.9127 250.581L-45.4507 294.394L-45.1074 295.012Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M-45.1073 297.552L46.8441 254.633L220.446 280.934L220.583 280.728L284.448 196.399L370.013 245.431L446.239 287.183L446.582 286.565L370.356 244.812L284.311 195.506L220.171 280.178L46.7754 253.946L46.6381 254.015L-45.4507 296.934L-45.1073 297.552Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M-45.1073 297.552L46.8441 254.633L220.446 280.934L220.583 280.728L284.448 196.399L370.013 245.431L446.239 287.183L446.582 286.565L370.356 244.812L284.311 195.506L220.171 280.178L46.7754 253.946L46.6381 254.015L-45.4507 296.934L-45.1073 297.552Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M-45.1074 300.162L46.5693 258.066L227.794 282.857L227.931 282.651L285.341 201.343L371.73 250.718L446.307 288.694L446.651 288.076L372.073 250.1L285.135 200.451L284.929 200.725L227.519 282.101L46.5006 257.379L46.3633 257.448L-45.4508 299.544L-45.1074 300.162Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M-45.1074 300.162L46.5693 258.066L227.794 282.857L227.931 282.651L285.341 201.343L371.73 250.718L446.307 288.694L446.651 288.076L372.073 250.1L285.135 200.451L284.929 200.725L227.519 282.101L46.5006 257.379L46.3633 257.448L-45.4508 299.544L-45.1074 300.162Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M-45.1074 302.565C-44.352 302.153 44.6465 263.011 47.5307 262.942C48.9728 263.011 89.4204 268.436 89.8324 268.504L229.511 284.161L244.275 264.865L289.804 207.18L373.79 255.388L446.307 292.608L446.651 291.99L374.202 254.77L289.667 206.219L243.794 264.384L229.305 283.337L90.0385 267.749C88.3904 267.543 49.1102 262.255 47.6681 262.186C44.9212 262.186 -45.1074 301.535 -45.4508 301.947L-45.1761 302.153L-44.8327 302.085L-45.1761 302.153L-45.1074 302.565Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M-45.1074 302.565C-44.352 302.153 44.6465 263.011 47.5307 262.942C48.9728 263.011 89.4204 268.436 89.8324 268.504L229.511 284.161L244.275 264.865L289.804 207.18L373.79 255.388L446.307 292.608L446.651 291.99L374.202 254.77L289.667 206.219L243.794 264.384L229.305 283.337L90.0385 267.749C88.3904 267.543 49.1102 262.255 47.6681 262.186C44.9212 262.186 -45.1074 301.535 -45.4508 301.947L-45.1761 302.153L-44.8327 302.085L-45.1761 302.153L-45.1074 302.565Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M-45.1073 305.038C-44.4206 304.626 42.6551 267.955 48.4922 267.955C48.4922 267.955 48.4922 267.955 48.5609 267.955C51.3764 268.092 90.7253 273.243 91.1373 273.311L231.296 285.603L246.679 267.062L294.337 213.017L375.919 260.126L446.239 296.591L446.582 295.973L376.262 259.508L294.199 212.125L293.993 212.331L246.129 266.513L230.953 284.779L91.206 272.556C89.6265 272.35 51.4451 267.337 48.5609 267.199C45.814 267.131 20.4055 277.157 -0.333374 285.603C-18.394 292.951 -45.3133 304.145 -45.588 304.488L-45.7253 304.626L-45.6567 304.763L-44.97 304.557L-45.3133 304.626L-45.1073 305.038Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M-45.1073 305.038C-44.4206 304.626 42.6551 267.955 48.4922 267.955C48.4922 267.955 48.4922 267.955 48.5609 267.955C51.3764 268.092 90.7253 273.243 91.1373 273.311L231.296 285.603L246.679 267.062L294.337 213.017L375.919 260.126L446.239 296.591L446.582 295.973L376.262 259.508L294.199 212.125L293.993 212.331L246.129 266.513L230.953 284.779L91.206 272.556C89.6265 272.35 51.4451 267.337 48.5609 267.199C45.814 267.131 20.4055 277.157 -0.333374 285.603C-18.394 292.951 -45.3133 304.145 -45.588 304.488L-45.7253 304.626L-45.6567 304.763L-44.97 304.557L-45.3133 304.626L-45.1073 305.038Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M-45.1075 307.441C-44.4894 307.098 40.7322 272.831 49.4535 272.831C49.5221 272.831 49.5222 272.831 49.5908 272.831C53.8484 273.037 92.0299 277.912 92.3733 277.981L233.013 286.908L249.013 269.054L298.869 218.923L378.116 264.865L446.307 300.574L446.651 299.956L378.46 264.315L298.8 218.099L248.601 268.642L232.807 286.222L92.5106 277.294C90.9312 277.088 53.9858 272.35 49.6595 272.144C49.5908 272.144 49.5909 272.144 49.5222 272.144C45.0585 272.144 19.7873 281.415 -0.676849 289.243C-20.0423 296.591 -45.1761 306.548 -45.5195 306.96L-45.6568 307.098L-45.5881 307.304L-44.9014 307.098L-45.2448 307.166L-45.1075 307.441Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M-45.1075 307.441C-44.4894 307.098 40.7322 272.831 49.4535 272.831C49.5221 272.831 49.5222 272.831 49.5908 272.831C53.8484 273.037 92.0299 277.912 92.3733 277.981L233.013 286.908L249.013 269.054L298.869 218.923L378.116 264.865L446.307 300.574L446.651 299.956L378.46 264.315L298.8 218.099L248.601 268.642L232.807 286.222L92.5106 277.294C90.9312 277.088 53.9858 272.35 49.6595 272.144C49.5908 272.144 49.5909 272.144 49.5222 272.144C45.0585 272.144 19.7873 281.415 -0.676849 289.243C-20.0423 296.591 -45.1761 306.548 -45.5195 306.96L-45.6568 307.098L-45.5881 307.304L-44.9014 307.098L-45.2448 307.166L-45.1075 307.441Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M-45.1074 309.913C-44.2833 309.433 -23.2011 301.398 -0.4021 293.295C27.8906 283.269 45.8826 277.775 50.4149 277.775C50.4836 277.775 50.5523 277.775 50.6209 277.775C56.3207 277.981 93.2661 282.651 93.6781 282.719L234.798 288.282L251.486 271.182L303.47 224.76L380.176 269.534L446.238 304.488L446.513 303.87L380.451 268.985L303.195 223.936L250.868 270.702L234.386 287.595L93.6094 282.033C93.2661 281.964 56.252 277.363 50.5523 277.088C50.4836 277.088 50.4149 277.088 50.3463 277.088C44.4405 277.088 19.1693 285.604 -1.1575 292.814C-20.2482 299.613 -45.176 308.883 -45.588 309.433L-45.7254 309.57L-45.6567 309.776L-45.3134 309.707L-45.1074 309.913Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M-45.1074 309.913C-44.2833 309.433 -23.2011 301.398 -0.4021 293.295C27.8906 283.269 45.8826 277.775 50.4149 277.775C50.4836 277.775 50.5523 277.775 50.6209 277.775C56.3207 277.981 93.2661 282.651 93.6781 282.719L234.798 288.282L251.486 271.182L303.47 224.76L380.176 269.534L446.238 304.488L446.513 303.87L380.451 268.985L303.195 223.936L250.868 270.702L234.386 287.595L93.6094 282.033C93.2661 281.964 56.252 277.363 50.5523 277.088C50.4836 277.088 50.4149 277.088 50.3463 277.088C44.4405 277.088 19.1693 285.604 -1.1575 292.814C-20.2482 299.613 -45.176 308.883 -45.588 309.433L-45.7254 309.57L-45.6567 309.776L-45.3134 309.707L-45.1074 309.913Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M-45.0387 312.317C-44.2146 311.768 -23.7505 304.42 -0.745453 296.866C27.822 287.526 46.432 282.445 51.651 282.651C58.7929 282.925 94.5708 287.32 94.9142 287.389L236.447 289.587L253.752 273.174L307.865 230.529L382.305 274.273L446.17 308.403L446.513 307.785L382.58 273.655L307.796 229.773L253.271 272.693L236.172 288.9L94.9142 286.702C94.5708 286.634 58.7929 282.307 51.651 281.964C44.8525 281.689 18.9633 289.655 -1.50089 296.385C-21.8277 303.046 -45.1074 311.287 -45.5194 311.836L-45.6567 311.974L-45.588 312.18L-45.2447 312.111L-45.0387 312.317Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M-45.0387 312.317C-44.2146 311.768 -23.7505 304.42 -0.745453 296.866C27.822 287.526 46.432 282.445 51.651 282.651C58.7929 282.925 94.5708 287.32 94.9142 287.389L236.447 289.587L253.752 273.174L307.865 230.529L382.305 274.273L446.17 308.403L446.513 307.785L382.58 273.655L307.796 229.773L253.271 272.693L236.172 288.9L94.9142 286.702C94.5708 286.634 58.7929 282.307 51.651 281.964C44.8525 281.689 18.9633 289.655 -1.50089 296.385C-21.8277 303.046 -45.1074 311.287 -45.5194 311.836L-45.6567 311.974L-45.588 312.18L-45.2447 312.111L-45.0387 312.317Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M-45.0387 314.789C-44.2146 314.24 -23.9565 307.372 -1.1575 300.505C27.2726 291.921 46.432 287.389 52.6811 287.595C61.2651 287.938 95.8756 292.059 96.2189 292.127L238.232 290.96L256.155 275.303L312.397 236.435L384.365 278.942L446.17 312.317L446.513 311.767L384.709 278.393L312.329 235.611L255.675 274.753L237.889 290.273L96.1503 291.441C95.8756 291.372 61.1964 287.32 52.6125 286.908C44.4405 286.565 18.4139 293.844 -1.98154 299.956C-22.5144 306.136 -45.1074 313.622 -45.588 314.24L-45.7254 314.377L-45.6567 314.583L-44.97 314.377L-45.3134 314.446L-45.0387 314.789Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M-45.0387 314.789C-44.2146 314.24 -23.9565 307.372 -1.1575 300.505C27.2726 291.921 46.432 287.389 52.6811 287.595C61.2651 287.938 95.8756 292.059 96.2189 292.127L238.232 290.96L256.155 275.303L312.397 236.435L384.365 278.942L446.17 312.317L446.513 311.767L384.709 278.393L312.329 235.611L255.675 274.753L237.889 290.273L96.1503 291.441C95.8756 291.372 61.1964 287.32 52.6125 286.908C44.4405 286.565 18.4139 293.844 -1.98154 299.956C-22.5144 306.136 -45.1074 313.622 -45.588 314.24L-45.7254 314.377L-45.6567 314.583L-44.97 314.377L-45.3134 314.446L-45.0387 314.789Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M-45.0387 317.193C-44.2146 316.643 -24.5745 310.394 -1.63818 304.076C14.7744 299.544 43.7538 292.059 53.6425 292.471C63.5999 292.883 97.043 296.728 97.3864 296.797L239.88 292.265L239.949 292.196L258.421 277.294L316.792 242.203L386.494 283.681L446.17 316.3L446.513 315.682L386.837 283.063L316.861 241.448L316.655 241.585L258.009 276.814L239.605 291.647L97.3864 296.179C97.1117 296.11 63.6686 292.265 53.6425 291.853C44.0972 291.441 17.9332 298.033 -2.39359 303.596C-23.2011 309.295 -45.1074 316.094 -45.588 316.712L-45.7254 316.849L-45.6567 317.055L-44.97 316.849L-45.3134 316.918L-45.0387 317.193Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M-45.0387 317.193C-44.2146 316.643 -24.5745 310.394 -1.63818 304.076C14.7744 299.544 43.7538 292.059 53.6425 292.471C63.5999 292.883 97.043 296.728 97.3864 296.797L239.88 292.265L239.949 292.196L258.421 277.294L316.792 242.203L386.494 283.681L446.17 316.3L446.513 315.682L386.837 283.063L316.861 241.448L316.655 241.585L258.009 276.814L239.605 291.647L97.3864 296.179C97.1117 296.11 63.6686 292.265 53.6425 291.853C44.0972 291.441 17.9332 298.033 -2.39359 303.596C-23.2011 309.295 -45.1074 316.094 -45.588 316.712L-45.7254 316.849L-45.6567 317.055L-44.97 316.849L-45.3134 316.918L-45.0387 317.193Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M446.101 320.214L446.445 319.596L388.898 287.732L321.325 247.285L260.413 278.805L241.322 292.951L98.6225 300.78C98.3478 300.711 66.0721 297.14 54.6726 296.66C43.2045 296.179 13.7443 302.977 -2.80554 307.098C-23.8191 312.317 -45.0386 318.36 -45.588 319.047L-45.7253 319.184L-45.6567 319.39L-44.97 319.184L-45.3134 319.253L-45.1073 319.459C-42.6352 317.742 34.9638 296.454 54.6039 297.278C66.0034 297.758 98.2792 301.329 98.6225 301.398L241.597 293.501L260.825 279.217L321.393 247.903L388.623 288.144L446.101 320.214Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M446.101 320.214L446.445 319.596L388.898 287.732L321.325 247.285L260.413 278.805L241.322 292.951L98.6225 300.78C98.3478 300.711 66.0721 297.14 54.6726 296.66C43.2045 296.179 13.7443 302.977 -2.80554 307.098C-23.8191 312.317 -45.0386 318.36 -45.588 319.047L-45.7253 319.184L-45.6567 319.39L-44.97 319.184L-45.3134 319.253L-45.1073 319.459C-42.6352 317.742 34.9638 296.454 54.6039 297.278C66.0034 297.758 98.2792 301.329 98.6225 301.398L241.597 293.501L260.825 279.217L321.393 247.903L388.623 288.144L446.101 320.214Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M446.101 324.197L446.445 323.579L391.027 292.471L325.926 253.19L325.788 253.259L262.885 280.934L243.176 294.393L99.996 305.587C99.7213 305.587 68.6816 302.222 55.7714 301.673C42.8611 301.123 13.3323 307.166 -3.08026 310.806C-22.6517 315.132 -44.8326 320.763 -45.5193 321.587L-45.6567 321.725L-45.588 321.931L-45.2447 321.862L-45.0386 322.068C-44.0772 321.45 -25.0552 316.3 -2.46219 311.355C13.813 307.784 42.9298 301.879 55.7027 302.359C68.5443 302.909 99.5839 306.205 99.9273 306.274L243.314 295.08L263.16 281.552L325.857 254.014L390.683 293.157L446.101 324.197Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M446.101 324.197L446.445 323.579L391.027 292.471L325.926 253.19L325.788 253.259L262.885 280.934L243.176 294.393L99.996 305.587C99.7213 305.587 68.6816 302.222 55.7714 301.673C42.8611 301.123 13.3323 307.166 -3.08026 310.806C-22.6517 315.132 -44.8326 320.763 -45.5193 321.587L-45.6567 321.725L-45.588 321.931L-45.2447 321.862L-45.0386 322.068C-44.0772 321.45 -25.0552 316.3 -2.46219 311.355C13.813 307.784 42.9298 301.879 55.7027 302.359C68.5443 302.909 99.5839 306.205 99.9273 306.274L243.314 295.08L263.16 281.552L325.857 254.014L390.683 293.157L446.101 324.197Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M446.101 328.111L446.445 327.493L393.155 297.14L330.458 259.028L330.321 259.096L265.22 282.994L244.893 295.767L101.301 310.325C100.133 310.188 70.9478 307.235 56.8015 306.617C42.3804 305.999 12.8516 311.218 -3.49228 314.377C-23.2697 318.223 -44.8326 323.167 -45.5193 323.991L-45.6567 324.128L-45.588 324.334L-45.2447 324.266L-44.97 324.472C-43.1158 323.167 31.7363 306.205 56.8015 307.304C71.0852 307.922 100.957 310.943 101.232 311.012H101.301H101.369L245.168 296.454L265.632 283.612L330.527 259.852L392.949 297.827L446.101 328.111Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M446.101 328.111L446.445 327.493L393.155 297.14L330.458 259.028L330.321 259.096L265.22 282.994L244.893 295.767L101.301 310.325C100.133 310.188 70.9478 307.235 56.8015 306.617C42.3804 305.999 12.8516 311.218 -3.49228 314.377C-23.2697 318.223 -44.8326 323.167 -45.5193 323.991L-45.6567 324.128L-45.588 324.334L-45.2447 324.266L-44.97 324.472C-43.1158 323.167 31.7363 306.205 56.8015 307.304C71.0852 307.922 100.957 310.943 101.232 311.012H101.301H101.369L245.168 296.454L265.632 283.612L330.527 259.852L392.949 297.827L446.101 328.111Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M446.101 332.094L446.445 331.476L395.284 301.947L334.99 264.933L267.555 285.054L246.61 297.072L102.468 315.064C101.232 314.926 73.214 312.248 57.7629 311.561C41.8997 310.875 12.2335 315.338 -3.97299 318.016C-23.8878 321.313 -44.9013 325.57 -45.588 326.463L-45.7253 326.6L-45.6567 326.806L-44.97 326.6L-45.3134 326.669L-45.0386 326.875C-44.1459 326.257 -26.2913 322.343 -3.28625 318.566C12.7829 315.888 42.037 311.561 57.7629 312.179C73.4887 312.866 102.193 315.613 102.468 315.682H102.537H102.605L246.885 297.758L246.953 297.69L267.967 285.603L334.99 265.62L395.009 302.497L446.101 332.094Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M446.101 332.094L446.445 331.476L395.284 301.947L334.99 264.933L267.555 285.054L246.61 297.072L102.468 315.064C101.232 314.926 73.214 312.248 57.7629 311.561C41.8997 310.875 12.2335 315.338 -3.97299 318.016C-23.8878 321.313 -44.9013 325.57 -45.588 326.463L-45.7253 326.6L-45.6567 326.806L-44.97 326.6L-45.3134 326.669L-45.0386 326.875C-44.1459 326.257 -26.2913 322.343 -3.28625 318.566C12.7829 315.888 42.037 311.561 57.7629 312.179C73.4887 312.866 102.193 315.613 102.468 315.682H102.537H102.605L246.885 297.758L246.953 297.69L267.967 285.603L334.99 265.62L395.009 302.497L446.101 332.094Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M446.032 336.008L446.376 335.39L397.344 306.617L339.454 270.77H339.317L269.89 287.046H269.821L248.189 298.376L103.636 319.665C102.399 319.527 75.5488 317.124 58.7242 316.368C29.3327 315.132 -44.3519 327.15 -45.588 328.798L-45.7254 328.935L-45.6567 329.141L-45.3134 329.073L-45.0387 329.279C-42.8412 327.768 28.44 315.819 58.7929 317.055C75.9608 317.742 103.429 320.283 103.704 320.351H103.773H103.842L248.601 299.063L270.302 287.664L339.385 271.526L397.001 307.235L446.032 336.008Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M446.032 336.008L446.376 335.39L397.344 306.617L339.454 270.77H339.317L269.89 287.046H269.821L248.189 298.376L103.636 319.665C102.399 319.527 75.5488 317.124 58.7242 316.368C29.3327 315.132 -44.3519 327.15 -45.588 328.798L-45.7254 328.935L-45.6567 329.141L-45.3134 329.073L-45.0387 329.279C-42.8412 327.768 28.44 315.819 58.7929 317.055C75.9608 317.742 103.429 320.283 103.704 320.351H103.773H103.842L248.601 299.063L270.302 287.664L339.385 271.526L397.001 307.235L446.032 336.008Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M446.032 339.991L446.376 339.373L399.473 311.355L343.986 276.676H343.849L272.293 289.174H272.224L249.975 299.818L104.94 324.403C103.704 324.266 78.021 322.068 59.7543 321.313C28.028 320.008 -44.2146 329.485 -45.588 331.27L-45.7254 331.407L-45.6567 331.613L-44.97 331.407L-45.3134 331.476L-45.0387 331.682C-43.3219 330.446 26.1052 320.557 59.7543 321.931C78.2957 322.686 104.666 324.952 104.94 325.021H105.009H105.078L250.25 300.368H250.318L272.568 289.724L343.918 277.225L399.13 311.905L446.032 339.991Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M446.032 339.991L446.376 339.373L399.473 311.355L343.986 276.676H343.849L272.293 289.174H272.224L249.975 299.818L104.94 324.403C103.704 324.266 78.021 322.068 59.7543 321.313C28.028 320.008 -44.2146 329.485 -45.588 331.27L-45.7254 331.407L-45.6567 331.613L-44.97 331.407L-45.3134 331.476L-45.0387 331.682C-43.3219 330.446 26.1052 320.557 59.7543 321.931C78.2957 322.686 104.666 324.952 104.94 325.021H105.009H105.078L250.25 300.368H250.318L272.568 289.724L343.918 277.225L399.13 311.905L446.032 339.991Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M446.032 343.906L446.376 343.288L401.602 316.025L348.587 282.445H348.45L274.765 291.166L251.829 301.123L106.314 329.073C105.078 329.004 80.5618 327.013 60.9217 326.188C26.6546 324.746 -44.0773 331.888 -45.4507 333.674L-45.588 333.811L-45.5194 334.017L-45.176 333.948L-44.9013 334.154C-43.0472 332.85 24.1137 325.364 60.9217 326.944C80.9052 327.768 106.108 329.759 106.314 329.828H106.382H106.451L252.104 301.81H252.172L275.109 291.853L348.587 283.131L401.396 316.574L446.032 343.906Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M446.032 343.906L446.376 343.288L401.602 316.025L348.587 282.445H348.45L274.765 291.166L251.829 301.123L106.314 329.073C105.078 329.004 80.5618 327.013 60.9217 326.188C26.6546 324.746 -44.0773 331.888 -45.4507 333.674L-45.588 333.811L-45.5194 334.017L-45.176 333.948L-44.9013 334.154C-43.0472 332.85 24.1137 325.364 60.9217 326.944C80.9052 327.768 106.108 329.759 106.314 329.828H106.382H106.451L252.104 301.81H252.172L275.109 291.853L348.587 283.131L401.396 316.574L446.032 343.906Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.964 347.82L446.307 347.271L403.662 320.763L353.051 288.35H352.914L277.032 293.295H276.963L253.34 302.565L107.481 333.88C106.245 333.811 82.8281 332.094 61.8832 331.201C25.6245 329.691 -44.0772 334.292 -45.5193 336.146L-45.6567 336.283L-45.588 336.489L-44.9013 336.283L-45.2446 336.352L-44.97 336.558C-43.0471 335.253 22.397 330.171 61.8832 331.82C83.3088 332.712 107.275 334.429 107.55 334.498H107.619H107.687L253.752 303.183L277.375 293.913L353.051 288.968L403.456 321.244L445.964 347.82Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.964 347.82L446.307 347.271L403.662 320.763L353.051 288.35H352.914L277.032 293.295H276.963L253.34 302.565L107.481 333.88C106.245 333.811 82.8281 332.094 61.8832 331.201C25.6245 329.691 -44.0772 334.292 -45.5193 336.146L-45.6567 336.283L-45.588 336.489L-44.9013 336.283L-45.2446 336.352L-44.97 336.558C-43.0471 335.253 22.397 330.171 61.8832 331.82C83.3088 332.712 107.275 334.429 107.55 334.498H107.619H107.687L253.752 303.183L277.375 293.913L353.051 288.968L403.456 321.244L445.964 347.82Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.964 351.803L446.307 351.254L405.791 325.502L357.583 294.256H357.446L279.435 295.424H279.366L255.125 304.008L108.649 338.687C107.413 338.618 85.1629 337.176 62.7759 336.215C23.5644 334.566 -44.2146 336.901 -45.6567 338.687L-45.794 338.824L-45.7253 339.03L-45.382 338.961L-45.1073 339.168C-42.8412 337.657 22.9464 335.253 62.7759 336.901C85.6436 337.863 108.443 339.305 108.649 339.374H108.717H108.786L255.331 304.694L279.641 296.042L357.446 294.874L405.448 326.051L445.964 351.803Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.964 351.803L446.307 351.254L405.791 325.502L357.583 294.256H357.446L279.435 295.424H279.366L255.125 304.008L108.649 338.687C107.413 338.618 85.1629 337.176 62.7759 336.215C23.5644 334.566 -44.2146 336.901 -45.6567 338.687L-45.794 338.824L-45.7253 339.03L-45.382 338.961L-45.1073 339.168C-42.8412 337.657 22.9464 335.253 62.7759 336.901C85.6436 337.863 108.443 339.305 108.649 339.374H108.717H108.786L255.331 304.694L279.641 296.042L357.446 294.874L405.448 326.051L445.964 351.803Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.964 355.717L446.307 355.168L407.92 330.171L362.116 300.025H362.047L281.907 297.346H281.839L256.979 305.244L110.022 343.288C108.786 343.219 87.6351 342.052 63.9434 341.022C24.5945 339.373 -44.0085 339.099 -45.5193 341.022L-45.6567 341.159L-45.588 341.365L-44.9013 341.159L-45.2446 341.228L-44.97 341.434C-42.7038 339.992 20.6115 339.854 63.9434 341.64C88.2531 342.67 109.885 343.837 110.091 343.906H110.159H110.228L257.254 305.862L282.113 298.033L362.047 300.711L407.645 330.721L445.964 355.717Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.964 355.717L446.307 355.168L407.92 330.171L362.116 300.025H362.047L281.907 297.346H281.839L256.979 305.244L110.022 343.288C108.786 343.219 87.6351 342.052 63.9434 341.022C24.5945 339.373 -44.0085 339.099 -45.5193 341.022L-45.6567 341.159L-45.588 341.365L-44.9013 341.159L-45.2446 341.228L-44.97 341.434C-42.7038 339.992 20.6115 339.854 63.9434 341.64C88.2531 342.67 109.885 343.837 110.091 343.906H110.159H110.228L257.254 305.862L282.113 298.033L362.047 300.711L407.645 330.721L445.964 355.717Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.895 359.7L446.307 359.151L366.717 305.999H366.648L284.311 299.544H284.242L111.258 348.095C109.541 348.026 71.5659 346.241 33.7965 344.798C-44.1459 341.846 -45.1073 343.082 -45.5193 343.562C-45.588 343.7 -45.6566 343.837 -45.588 343.974L-44.9013 343.768C-44.8326 343.906 -44.9013 344.043 -44.97 344.043C-41.9484 342.12 68.8877 346.79 111.327 348.781H111.395H111.464L284.448 300.23L366.579 306.686L445.895 359.7Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.895 359.7L446.307 359.151L366.717 305.999H366.648L284.311 299.544H284.242L111.258 348.095C109.541 348.026 71.5659 346.241 33.7965 344.798C-44.1459 341.846 -45.1073 343.082 -45.5193 343.562C-45.588 343.7 -45.6566 343.837 -45.588 343.974L-44.9013 343.768C-44.8326 343.906 -44.9013 344.043 -44.97 344.043C-41.9484 342.12 68.8877 346.79 111.327 348.781H111.395H111.464L284.448 300.23L366.579 306.686L445.895 359.7Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.964 366.018L446.307 365.469L366.03 315.27L283.83 309.158H283.761L110.846 355.031C109.267 354.962 71.2225 353.314 33.5218 351.94C-44.2833 349.125 -45.176 350.361 -45.5193 350.773L-45.6567 350.91L-45.588 351.116L-44.97 350.91L-45.3133 350.979L-45.1073 351.185C-42.1544 349.331 68.4757 353.794 110.846 355.649H110.915H110.983L283.899 309.845L365.824 315.888L445.964 366.018Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M445.964 366.018L446.307 365.469L366.03 315.27L283.83 309.158H283.761L110.846 355.031C109.267 354.962 71.2225 353.314 33.5218 351.94C-44.2833 349.125 -45.176 350.361 -45.5193 350.773L-45.6567 350.91L-45.588 351.116L-44.97 350.91L-45.3133 350.979L-45.1073 351.185C-42.1544 349.331 68.4757 353.794 110.846 355.649H110.915H110.983L283.899 309.845L365.824 315.888L445.964 366.018Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M446.032 372.404L446.376 371.855L365.481 324.609H365.412L283.487 318.909H283.418L110.503 362.035C84.6135 360.936 -43.7339 355.717 -45.5194 358.052L-45.588 358.189V358.395L-44.97 358.189L-45.2447 358.258L-45.0387 358.395C-42.2918 356.747 57.9688 360.387 110.571 362.584H110.64H110.709L283.487 319.459L365.275 325.158L446.032 372.404Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M446.032 372.404L446.376 371.855L365.481 324.609H365.412L283.487 318.909H283.418L110.503 362.035C84.6135 360.936 -43.7339 355.717 -45.5194 358.052L-45.588 358.189V358.395L-44.97 358.189L-45.2447 358.258L-45.0387 358.395C-42.2918 356.747 57.9688 360.387 110.571 362.584H110.64H110.709L283.487 319.459L365.275 325.158L446.032 372.404Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M446.17 378.722L446.445 378.173L364.863 333.88H364.794L283.075 328.523H283.006L110.228 368.971C84.4075 367.941 -43.7339 363.065 -45.4507 365.194L-45.5193 365.331V365.537L-44.97 365.331L-45.2447 365.4L-45.0386 365.537C-42.3604 363.958 57.6942 367.46 110.228 369.452H110.297H110.365L283.075 329.141L364.657 334.498L446.17 378.722Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M446.17 378.722L446.445 378.173L364.863 333.88H364.794L283.075 328.523H283.006L110.228 368.971C84.4075 367.941 -43.7339 363.065 -45.4507 365.194L-45.5193 365.331V365.537L-44.97 365.331L-45.2447 365.4L-45.0386 365.537C-42.3604 363.958 57.6942 367.46 110.228 369.452H110.297H110.365L283.075 329.141L364.657 334.498L446.17 378.722Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M446.238 385.109L446.513 384.628L364.245 343.288H364.176L282.594 338.275H282.525L109.747 376.044C83.9954 375.083 -44.0086 370.55 -45.5194 372.542L-45.588 372.679V372.885L-45.0387 372.748L-45.3134 372.817L-45.1074 372.954C-42.4979 371.512 57.3508 374.739 109.747 376.662H109.816H109.885L282.594 338.893L364.107 343.768L446.238 385.109Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M446.238 385.109L446.513 384.628L364.245 343.288H364.176L282.594 338.275H282.525L109.747 376.044C83.9954 375.083 -44.0086 370.55 -45.5194 372.542L-45.588 372.679V372.885L-45.0387 372.748L-45.3134 372.817L-45.1074 372.954C-42.4979 371.512 57.3508 374.739 109.747 376.662H109.816H109.885L282.594 338.893L364.107 343.768L446.238 385.109Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M446.307 391.427L446.513 390.946L363.626 352.558H363.558L282.182 347.889H282.113L109.404 382.98C83.8581 382.087 -44.0086 377.83 -45.4507 379.752L-45.5194 379.89V380.096L-44.97 379.958L-45.2447 380.027L-45.0387 380.164C-42.4978 378.791 57.1448 381.813 109.473 383.598L282.251 348.507L363.489 353.108L446.307 391.427Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M446.307 391.427L446.513 390.946L363.626 352.558H363.558L282.182 347.889H282.113L109.404 382.98C83.8581 382.087 -44.0086 377.83 -45.4507 379.752L-45.5194 379.89V380.096L-44.97 379.958L-45.2447 380.027L-45.0387 380.164C-42.4978 378.791 57.1448 381.813 109.473 383.598L282.251 348.507L363.489 353.108L446.307 391.427Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M446.376 397.813L446.582 397.332L363.008 361.898H362.94L281.77 357.64L109.061 390.053C83.5147 389.229 -44.0772 385.315 -45.4507 387.032L-45.5193 387.169V387.306L-45.2446 387.238L-45.1073 387.375C-42.6351 386.07 56.8015 388.886 109.061 390.534L281.77 358.121L362.802 362.447L446.376 397.813Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M446.376 397.813L446.582 397.332L363.008 361.898H362.94L281.77 357.64L109.061 390.053C83.5147 389.229 -44.0772 385.315 -45.4507 387.032L-45.5193 387.169V387.306L-45.2446 387.238L-45.1073 387.375C-42.6351 386.07 56.8015 388.886 109.061 390.534L281.77 358.121L362.802 362.447L446.376 397.813Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M446.444 404.131L446.651 403.65L362.459 371.168H362.39L281.426 367.254L108.786 396.92C83.3087 396.165 -44.146 392.594 -45.3821 394.173L-45.4508 394.242V394.379L-44.9701 394.242L-45.1761 394.311L-45.0387 394.448C-42.7039 393.281 56.5954 395.821 108.786 397.332L281.426 367.666L362.322 371.58L446.444 404.131Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M446.444 404.131L446.651 403.65L362.459 371.168H362.39L281.426 367.254L108.786 396.92C83.3087 396.165 -44.146 392.594 -45.3821 394.173L-45.4508 394.242V394.379L-44.9701 394.242L-45.1761 394.311L-45.0387 394.448C-42.7039 393.281 56.5954 395.821 108.786 397.332L281.426 367.666L362.322 371.58L446.444 404.131Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M446.513 410.517L446.651 410.105L361.772 380.577L281.014 377.006L108.374 403.925C108.03 403.925 69.849 402.895 32.0109 402.071C-44.6267 400.423 -45.1761 401.109 -45.3821 401.453L-45.4508 401.521V401.659L-45.0387 401.521L-45.2447 401.59L-45.1074 401.727C-42.8412 400.629 56.252 402.963 108.374 404.337L281.014 377.418L361.704 380.989L446.513 410.517Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M446.513 410.517L446.651 410.105L361.772 380.577L281.014 377.006L108.374 403.925C108.03 403.925 69.849 402.895 32.0109 402.071C-44.6267 400.423 -45.1761 401.109 -45.3821 401.453L-45.4508 401.521V401.659L-45.0387 401.521L-45.2447 401.59L-45.1074 401.727C-42.8412 400.629 56.252 402.963 108.374 404.337L281.014 377.418L361.704 380.989L446.513 410.517Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M446.582 416.835L446.719 416.423L361.154 389.847L280.534 386.62L108.031 410.929C107.687 410.929 69.5744 410.037 31.8049 409.281C-44.6953 407.77 -45.176 408.457 -45.382 408.732L-45.4507 408.801V408.938L-45.0386 408.801L-45.2447 408.869L-45.1073 409.007C-42.9785 408.045 55.9774 410.105 108.031 411.341L280.534 387.032L361.086 390.259L446.582 416.835Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M446.582 416.835L446.719 416.423L361.154 389.847L280.534 386.62L108.031 410.929C107.687 410.929 69.5744 410.037 31.8049 409.281C-44.6953 407.77 -45.176 408.457 -45.382 408.732L-45.4507 408.801V408.938L-45.0386 408.801L-45.2447 408.869L-45.1073 409.007C-42.9785 408.045 55.9774 410.105 108.031 411.341L280.534 387.032L361.086 390.259L446.582 416.835Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1021.2 222.827V221.991L918.794 209.478L800.98 61L800.729 61.1671L616.74 181.153L540.37 124.252L457.566 202.627L370.334 161.267L370 162.019L457.733 203.63L457.901 203.379L540.453 125.338L616.656 182.156L616.907 182.072L800.813 62.0862L918.376 210.314L1021.2 222.827Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1021.2 222.827V221.991L918.794 209.478L800.98 61L800.729 61.1671L616.74 181.153L540.37 124.252L457.566 202.627L370.334 161.267L370 162.019L457.733 203.63L457.901 203.379L540.453 125.338L616.656 182.156L616.907 182.072L800.813 62.0862L918.376 210.314L1021.2 222.827Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M981.572 221.837L981.656 221.002L920.13 212.988L803.236 67.1831L726.616 111.05L617.993 182.657L541.205 132.524L529.842 143.219L476.283 187.587L457.9 204.465L370.334 168.453L370.083 169.205L458.151 205.384L476.784 188.172L530.427 143.804L541.289 133.61L617.993 183.66L618.244 183.493L727.033 111.802L803.069 68.1858L919.629 213.823L981.572 221.837Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M981.572 221.837L981.656 221.002L920.13 212.988L803.236 67.1831L726.616 111.05L617.993 182.657L541.205 132.524L529.842 143.219L476.283 187.587L457.9 204.465L370.334 168.453L370.083 169.205L458.151 205.384L476.784 188.172L530.427 143.804L541.289 133.61L617.993 183.66L618.244 183.493L727.033 111.802L803.069 68.1858L919.629 213.823L981.572 221.837Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.593 222.262L968.676 221.427L921.384 216.581L805.492 73.2827L728.287 111.468L619.246 184.161L542.041 140.879L530.51 151.491L476.951 189.509L458.318 206.303L370.418 175.638L370.083 176.39L458.485 207.222L477.536 190.177L531.095 152.159L542.125 141.882L619.33 185.164L728.704 112.22L805.325 74.3689L920.882 217.333H921.05L968.593 222.262Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.593 222.262L968.676 221.427L921.384 216.581L805.492 73.2827L728.287 111.468L619.246 184.161L542.041 140.879L530.51 151.491L476.951 189.509L458.318 206.303L370.418 175.638L370.083 176.39L458.485 207.222L477.536 190.177L531.095 152.159L542.125 141.882L619.33 185.164L728.704 112.22L805.325 74.3689L920.882 217.333H921.05L968.593 222.262Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.509 227.192L968.593 226.357L922.637 220.09L807.748 79.4658L729.874 111.969L620.5 185.665L542.877 149.235L531.262 159.763L477.703 191.514L458.652 208.058L370.418 182.824L370.167 183.576L458.903 208.977L459.07 208.894L478.204 192.182L531.847 160.431L543.044 150.154L620.583 186.668L730.292 112.637L807.497 80.4685L922.136 220.842L922.303 220.926L968.509 227.192Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.509 227.192L968.593 226.357L922.637 220.09L807.748 79.4658L729.874 111.969L620.5 185.665L542.877 149.235L531.262 159.763L477.703 191.514L458.652 208.058L370.418 182.824L370.167 183.576L458.903 208.977L459.07 208.894L478.204 192.182L531.847 160.431L543.044 150.154L620.583 186.668L730.292 112.637L807.497 80.4685L922.136 220.842L922.303 220.926L968.509 227.192Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.426 232.122L968.593 231.287L923.89 223.683L810.004 85.5654L809.753 85.649L731.545 112.387L731.462 112.47L621.837 187.169L543.712 157.507L532.014 168.035L478.372 193.519L459.07 209.896L370.418 190.01L370.251 190.846L459.237 210.815L478.873 194.188L532.516 168.787L543.879 158.51L621.92 188.088L622.087 188.005L731.88 113.139L809.753 86.5681L923.389 224.435L968.426 232.122Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.426 232.122L968.593 231.287L923.89 223.683L810.004 85.5654L809.753 85.649L731.545 112.387L731.462 112.47L621.837 187.169L543.712 157.507L532.014 168.035L478.372 193.519L459.07 209.896L370.418 190.01L370.251 190.846L459.237 210.815L478.873 194.188L532.516 168.787L543.879 158.51L621.92 188.088L622.087 188.005L731.88 113.139L809.753 86.5681L923.389 224.435L968.426 232.122Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.342 237.052L968.509 236.216L925.144 227.192L812.26 91.7486L733.133 112.888L623.09 188.673L544.548 165.863L532.766 176.391L479.124 195.441L479.04 195.525L459.405 211.735L370.418 197.196L370.334 198.031L459.655 212.654L459.822 212.487L479.542 196.193L533.184 177.059H533.268L544.715 166.782L623.174 189.592L623.341 189.509L733.551 113.64L812.009 92.6677L924.643 227.944H924.81L968.342 237.052Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.342 237.052L968.509 236.216L925.144 227.192L812.26 91.7486L733.133 112.888L623.09 188.673L544.548 165.863L532.766 176.391L479.124 195.441L479.04 195.525L459.405 211.735L370.418 197.196L370.334 198.031L459.655 212.654L459.822 212.487L479.542 196.193L533.184 177.059H533.268L544.715 166.782L623.174 189.592L623.341 189.509L733.551 113.64L812.009 92.6677L924.643 227.944H924.81L968.342 237.052Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.259 241.898L968.509 241.146L926.397 230.702L814.516 97.8482L814.349 97.9318L734.804 113.306L734.72 113.39L624.343 190.177L545.383 174.135L533.435 184.663L479.876 197.363L459.739 213.573L370.418 204.382L370.334 205.217L459.989 214.408L480.21 198.199L533.853 185.415L545.55 175.054L624.511 191.096L624.678 191.013L735.138 114.142L814.266 98.7673L925.896 231.454L926.063 231.537L968.259 241.898Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.259 241.898L968.509 241.146L926.397 230.702L814.516 97.8482L814.349 97.9318L734.804 113.306L734.72 113.39L624.343 190.177L545.383 174.135L533.435 184.663L479.876 197.363L459.739 213.573L370.418 204.382L370.334 205.217L459.989 214.408L480.21 198.199L533.853 185.415L545.55 175.054L624.511 191.096L624.678 191.013L735.138 114.142L814.266 98.7673L925.896 231.454L926.063 231.537L968.259 241.898Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.175 246.828L968.426 246.076L927.651 234.295L816.772 104.031H816.605L736.475 113.807H736.392L625.597 191.681L546.135 182.49L534.187 192.935L480.628 199.368L460.157 215.411L370.418 211.567V212.403L460.407 216.247L480.962 200.12L534.521 193.77L546.47 183.326L625.848 192.6L625.931 192.517L736.726 114.559L816.438 104.867L927.149 235.047L968.175 246.828Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.175 246.828L968.426 246.076L927.651 234.295L816.772 104.031H816.605L736.475 113.807H736.392L625.597 191.681L546.135 182.49L534.187 192.935L480.628 199.368L460.157 215.411L370.418 211.567V212.403L460.407 216.247L480.962 200.12L534.521 193.77L546.47 183.326L625.848 192.6L625.931 192.517L736.726 114.559L816.438 104.867L927.149 235.047L968.175 246.828Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.091 251.758L968.342 250.922L928.904 237.804L819.028 110.131L738.063 114.225L737.979 114.309L626.85 193.185L546.971 190.762L534.939 201.207L481.296 201.29L481.212 201.374L460.491 217.249L370.501 218.753V219.589L460.825 218.085L481.63 202.126L535.189 202.042L547.305 191.598L627.101 194.021L738.397 115.061L818.694 111.05L928.402 238.556H928.486L968.091 251.758Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.091 251.758L968.342 250.922L928.904 237.804L819.028 110.131L738.063 114.225L737.979 114.309L626.85 193.185L546.971 190.762L534.939 201.207L481.296 201.29L481.212 201.374L460.491 217.249L370.501 218.753V219.589L460.825 218.085L481.63 202.126L535.189 202.042L547.305 191.598L627.101 194.021L738.397 115.061L818.694 111.05L928.402 238.556H928.486L968.091 251.758Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.008 256.688L968.342 255.852L930.157 241.397L821.284 116.314L739.734 114.643L739.65 114.726L628.103 194.689L547.806 199.118L547.723 199.201L535.607 209.479L482.048 203.295L460.908 219.004L370.501 225.939L370.585 226.775L461.159 219.839L461.243 219.756L482.299 204.131L535.941 210.398L548.141 199.953L628.438 195.525L628.521 195.441L739.984 115.478L820.95 117.15L929.656 242.065L968.008 256.688Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.008 256.688L968.342 255.852L930.157 241.397L821.284 116.314L739.734 114.643L739.65 114.726L628.103 194.689L547.806 199.118L547.723 199.201L535.607 209.479L482.048 203.295L460.908 219.004L370.501 225.939L370.585 226.775L461.159 219.839L461.243 219.756L482.299 204.131L535.941 210.398L548.141 199.953L628.438 195.525L628.521 195.441L739.984 115.478L820.95 117.15L929.656 242.065L968.008 256.688Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.924 261.534L968.259 260.782L931.41 244.906L823.54 122.414H823.373L741.405 115.144L741.238 115.228L629.44 196.193L548.642 207.39L536.359 217.834L482.8 205.217L461.243 220.842L370.501 233.208L370.585 233.96L461.577 221.678L482.967 206.136L536.61 218.753L548.976 208.225L629.775 197.029L629.858 196.945L741.656 115.98L823.122 123.249L930.909 245.575L930.993 245.658L967.924 261.534Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.924 261.534L968.259 260.782L931.41 244.906L823.54 122.414H823.373L741.405 115.144L741.238 115.228L629.44 196.193L548.642 207.39L536.359 217.834L482.8 205.217L461.243 220.842L370.501 233.208L370.585 233.96L461.577 221.678L482.967 206.136L536.61 218.753L548.976 208.225L629.775 197.029L629.858 196.945L741.656 115.98L823.122 123.249L930.909 245.575L930.993 245.658L967.924 261.534Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.841 266.464L968.259 265.712L932.664 248.499L825.796 128.597H825.713L742.992 115.562L742.909 115.646L630.694 197.697L549.478 215.745L537.111 226.106L483.552 207.223L461.577 222.68L370.501 240.394L370.668 241.146L461.911 223.432H461.995L483.636 208.142L537.278 227.025L537.445 226.858L549.895 216.497L631.028 198.533L631.111 198.449L743.243 116.481L825.378 129.349L932.162 249.167H932.246L967.841 266.464Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.841 266.464L968.259 265.712L932.664 248.499L825.796 128.597H825.713L742.992 115.562L742.909 115.646L630.694 197.697L549.478 215.745L537.111 226.106L483.552 207.223L461.577 222.68L370.501 240.394L370.668 241.146L461.911 223.432H461.995L483.636 208.142L537.278 227.025L537.445 226.858L549.895 216.497L631.028 198.533L631.111 198.449L743.243 116.481L825.378 129.349L932.162 249.167H932.246L967.841 266.464Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.757 271.393L968.175 270.641L933.917 252.008L828.052 134.696H827.969L744.664 115.98L744.496 116.147L631.947 199.201L550.313 224.017L537.863 234.378L484.304 209.144L461.995 224.519L370.501 247.58L370.752 248.416L462.329 225.271H462.413L484.388 210.147L537.947 235.381L550.731 224.769L632.365 199.953L744.831 116.899L827.634 135.448L933.416 252.677L967.757 271.393Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.757 271.393L968.175 270.641L933.917 252.008L828.052 134.696H827.969L744.664 115.98L744.496 116.147L631.947 199.201L550.313 224.017L537.863 234.378L484.304 209.144L461.995 224.519L370.501 247.58L370.752 248.416L462.329 225.271H462.413L484.388 210.147L537.947 235.381L550.731 224.769L632.365 199.953L744.831 116.899L827.634 135.448L933.416 252.677L967.757 271.393Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.674 276.323L968.175 275.571L935.171 255.518L830.308 140.879L830.225 140.796L746.335 116.481L633.2 200.705L551.149 232.373H551.065L538.532 242.65L484.973 211.15L462.329 226.357L370.585 254.766L370.836 255.601L462.747 227.109V227.025L485.056 212.069L538.615 243.653L551.566 233.125L633.618 201.457H633.702L746.502 117.4L829.89 141.631L934.586 256.186L934.669 256.27L967.674 276.323Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.674 276.323L968.175 275.571L935.171 255.518L830.308 140.879L830.225 140.796L746.335 116.481L633.2 200.705L551.149 232.373H551.065L538.532 242.65L484.973 211.15L462.329 226.357L370.585 254.766L370.836 255.601L462.747 227.109V227.025L485.056 212.069L538.615 243.653L551.566 233.125L633.618 201.457H633.702L746.502 117.4L829.89 141.631L934.586 256.186L934.669 256.27L967.674 276.323Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.59 281.169L968.092 280.501L936.424 259.11L832.564 146.979H832.481L747.922 116.899L634.537 202.209L551.984 240.645L551.901 240.728L539.284 250.922L485.725 213.071L462.747 228.111L370.585 261.951L370.836 262.787L463.081 228.863L485.725 214.074L539.367 252.008L552.402 241.397L634.955 202.961L748.089 117.818L832.063 147.731L935.839 259.779H935.923L967.59 281.169Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.59 281.169L968.092 280.501L936.424 259.11L832.564 146.979H832.481L747.922 116.899L634.537 202.209L551.984 240.645L551.901 240.728L539.284 250.922L485.725 213.071L462.747 228.111L370.585 261.951L370.836 262.787L463.081 228.863L485.725 214.074L539.367 252.008L552.402 241.397L634.955 202.961L748.089 117.818L832.063 147.731L935.839 259.779H935.923L967.59 281.169Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.506 286.099L968.008 285.431L937.677 262.62L834.82 153.162L834.736 153.078L749.593 117.4L749.426 117.484L635.79 203.797L552.82 249L540.036 259.278L486.476 215.077L463.081 229.95L370.585 269.137L370.919 269.973L463.415 230.702L486.393 216.079L540.036 260.364L553.321 249.669L636.208 204.465L749.677 118.319L834.319 153.83L937.092 263.288L967.506 286.099Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.506 286.099L968.008 285.431L937.677 262.62L834.82 153.162L834.736 153.078L749.593 117.4L749.426 117.484L635.79 203.797L552.82 249L540.036 259.278L486.476 215.077L463.081 229.95L370.585 269.137L370.919 269.973L463.415 230.702L486.393 216.079L540.036 260.364L553.321 249.669L636.208 204.465L749.677 118.319L834.319 153.83L937.092 263.288L967.506 286.099Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.423 291.029L968.008 290.36L938.93 266.213L837.076 159.262H836.993L751.264 117.818L637.044 205.217L553.655 257.272L540.788 267.55L487.228 216.999L463.415 231.788L370.585 276.407L371.003 277.159L463.833 232.54L487.061 218.085L540.704 268.636L554.157 257.941L637.545 205.969L751.348 118.821L836.575 159.93L938.346 266.798L967.423 291.029Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.423 291.029L968.008 290.36L938.93 266.213L837.076 159.262H836.993L751.264 117.818L637.044 205.217L553.655 257.272L540.788 267.55L487.228 216.999L463.415 231.788L370.585 276.407L371.003 277.159L463.833 232.54L487.061 218.085L540.704 268.636L554.157 257.941L637.545 205.969L751.348 118.821L836.575 159.93L938.346 266.798L967.423 291.029Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.339 295.875L967.924 295.29L940.184 269.722L839.332 165.445L839.249 165.361L752.852 118.236L752.685 118.403L638.297 206.721L554.491 265.628L541.456 275.822L487.897 219.004L463.833 233.626L370.668 283.592L371.003 284.344L464.251 234.378L487.813 220.09L541.373 276.992L554.992 266.296L638.798 207.39L752.936 119.238L838.831 166.113L939.599 270.307L967.339 295.875Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.339 295.875L967.924 295.29L940.184 269.722L839.332 165.445L839.249 165.361L752.852 118.236L752.685 118.403L638.297 206.721L554.491 265.628L541.456 275.822L487.897 219.004L463.833 233.626L370.668 283.592L371.003 284.344L464.251 234.378L487.813 220.09L541.373 276.992L554.992 266.296L638.798 207.39L752.936 119.238L838.831 166.113L939.599 270.307L967.339 295.875Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.339 300.805L967.924 300.22L841.588 171.544H841.505L754.523 118.737L754.272 118.904L542.208 284.094L488.649 220.925L488.315 221.176L370.668 290.778L371.086 291.53L488.482 222.012L542.041 285.263L542.375 285.013L754.607 119.74L841.087 172.213L967.339 300.805Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.339 300.805L967.924 300.22L841.588 171.544H841.505L754.523 118.737L754.272 118.904L542.208 284.094L488.649 220.925L488.315 221.176L370.668 290.778L371.086 291.53L488.482 222.012L542.041 285.263L542.375 285.013L754.607 119.74L841.087 172.213L967.339 300.805Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.423 302.643L968.008 302.058L843.677 177.978H843.593L755.526 124.753L755.359 124.92L551.065 286.517L488.315 225.187L487.98 225.354L370.668 293.953L371.086 294.705L488.148 226.19L551.065 287.603L551.316 287.352L755.609 125.756L843.092 178.646L967.423 302.643Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.423 302.643L968.008 302.058L843.677 177.978H843.593L755.526 124.753L755.359 124.92L551.065 286.517L488.315 225.187L487.98 225.354L370.668 293.953L371.086 294.705L488.148 226.19L551.065 287.603L551.316 287.352L755.609 125.756L843.092 178.646L967.423 302.643Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.507 304.565L968.092 303.98L845.682 184.412L756.612 130.769L756.361 130.936L560.006 288.857L487.981 229.365L487.73 229.532L370.668 297.129L371.086 297.797L487.897 230.368L560.006 289.943L560.256 289.692L756.612 131.772L845.181 185.08L967.507 304.565Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.507 304.565L968.092 303.98L845.682 184.412L756.612 130.769L756.361 130.936L560.006 288.857L487.981 229.365L487.73 229.532L370.668 297.129L371.086 297.797L487.897 230.368L560.006 289.943L560.256 289.692L756.612 131.772L845.181 185.08L967.507 304.565Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.59 306.403L968.175 305.818L847.771 190.846H847.688L757.615 136.785L757.364 136.952L568.946 291.196L487.563 233.543L370.668 300.22L371.086 300.972L487.563 234.545L568.946 292.282L569.197 292.032L757.698 137.788L847.27 191.514L967.59 306.403Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.59 306.403L968.175 305.818L847.771 190.846H847.688L757.615 136.785L757.364 136.952L568.946 291.196L487.563 233.543L370.668 300.22L371.086 300.972L487.563 234.545L568.946 292.282L569.197 292.032L757.698 137.788L847.27 191.514L967.59 306.403Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.674 308.241L968.259 307.656L849.86 197.279H849.777L758.617 142.801L758.367 142.968L577.886 293.536L487.229 237.72L487.061 237.887L370.668 303.395L371.086 304.147L487.229 238.723L577.886 294.538L758.701 143.804L849.359 197.948L967.674 308.241Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.674 308.241L968.259 307.656L849.86 197.279H849.777L758.617 142.801L758.367 142.968L577.886 293.536L487.229 237.72L487.061 237.887L370.668 303.395L371.086 304.147L487.229 238.723L577.886 294.538L758.701 143.804L849.359 197.948L967.674 308.241Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.757 310.163L968.342 309.495L851.949 203.797L759.62 148.817L759.453 148.984L586.743 295.875L486.894 241.898L486.727 242.065L370.668 306.57L371.086 307.322L486.894 242.901L586.91 296.878L587.078 296.711L759.704 149.82L851.448 204.465L967.757 310.163Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.757 310.163L968.342 309.495L851.949 203.797L759.62 148.817L759.453 148.984L586.743 295.875L486.894 241.898L486.727 242.065L370.668 306.57L371.086 307.322L486.894 242.901L586.91 296.878L587.078 296.711L759.704 149.82L851.448 204.465L967.757 310.163Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.841 312.001L968.426 311.333L853.955 210.231L760.623 154.833L595.684 298.215L486.56 246.076L486.393 246.243L370.668 309.745L371.086 310.497L486.56 247.079L595.851 299.217L596.018 299.05L760.79 155.836L853.453 210.899L967.841 312.001Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.841 312.001L968.426 311.333L853.955 210.231L760.623 154.833L595.684 298.215L486.56 246.076L486.393 246.243L370.668 309.745L371.086 310.497L486.56 247.079L595.851 299.217L596.018 299.05L760.79 155.836L853.453 210.899L967.841 312.001Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.924 313.84L968.509 313.255L856.043 216.664L761.709 160.849L761.458 161.016L604.624 300.554L486.226 250.254L370.668 312.921L371.086 313.589L486.226 251.173L604.791 301.557L604.959 301.39L761.793 161.852L855.542 217.333L967.924 313.84Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M967.924 313.84L968.509 313.255L856.043 216.664L761.709 160.849L761.458 161.016L604.624 300.554L486.226 250.254L370.668 312.921L371.086 313.589L486.226 251.173L604.791 301.557L604.959 301.39L761.793 161.852L855.542 217.333L967.924 313.84Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M371.086 316.764L485.975 255.35L613.732 303.896L613.899 303.729L762.795 167.868L857.631 223.766L968.008 315.761L968.593 315.093L858.132 223.098L762.712 166.865L613.565 302.977L485.892 254.431L485.725 254.598L370.668 316.012L371.086 316.764Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M371.086 316.764L485.975 255.35L613.732 303.896L613.899 303.729L762.795 167.868L857.631 223.766L968.008 315.761L968.593 315.093L858.132 223.098L762.712 166.865L613.565 302.977L485.892 254.431L485.725 254.598L370.668 316.012L371.086 316.764Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M371.086 319.939L485.641 259.528L622.672 306.236L622.839 306.069L763.881 173.884L859.72 230.2L968.175 317.6L968.676 316.931L860.137 229.532L763.714 172.881L763.464 173.132L622.422 305.317L485.557 258.693L485.39 258.776L370.668 319.187L371.086 319.939Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M371.086 319.939L485.641 259.528L622.672 306.236L622.839 306.069L763.881 173.884L859.72 230.2L968.175 317.6L968.676 316.931L860.137 229.532L763.714 172.881L763.464 173.132L622.422 305.317L485.557 258.693L485.39 258.776L370.668 319.187L371.086 319.939Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M371.086 323.114L485.307 263.706L631.613 308.576L631.78 308.408L764.884 179.9L861.725 236.634L968.259 319.438L968.76 318.769L862.227 235.966L764.717 178.897L764.55 179.148L631.362 307.656L485.223 262.871L485.056 262.954L370.668 322.362L371.086 323.114Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M371.086 323.114L485.307 263.706L631.613 308.576L631.78 308.408L764.884 179.9L861.725 236.634L968.259 319.438L968.76 318.769L862.227 235.966L764.717 178.897L764.55 179.148L631.362 307.656L485.223 262.871L485.056 262.954L370.668 322.362L371.086 323.114Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M371.086 326.289L484.973 267.884L640.553 310.915L640.72 310.748L765.887 185.999L863.814 243.068L968.342 321.36L968.844 320.691L864.315 242.4L765.803 184.913L765.553 185.164L640.303 309.996L484.889 267.048L484.722 267.132L370.668 325.537L371.086 326.289Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M371.086 326.289L484.973 267.884L640.553 310.915L640.72 310.748L765.887 185.999L863.814 243.068L968.342 321.36L968.844 320.691L864.315 242.4L765.803 184.913L765.553 185.164L640.303 309.996L484.889 267.048L484.722 267.132L370.668 325.537L371.086 326.289Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M371.086 329.381L484.638 272.062L649.494 313.255L649.661 313.087L766.973 192.015L865.903 249.502L968.426 323.198L968.927 322.529L866.321 248.833L766.806 190.929L649.243 312.335L484.555 271.226L484.388 271.31L370.668 328.629L371.086 329.381Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M371.086 329.381L484.638 272.062L649.494 313.255L649.661 313.087L766.973 192.015L865.903 249.502L968.426 323.198L968.927 322.529L866.321 248.833L766.806 190.929L649.243 312.335L484.555 271.226L484.388 271.31L370.668 328.629L371.086 329.381Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M371.086 332.556L484.304 276.239L658.434 315.594L658.601 315.427L767.976 198.031L867.908 256.019L968.509 325.036L969.01 324.367L868.409 255.267L767.808 196.945L767.558 197.196L658.183 314.675L484.22 275.404L370.668 331.804L371.086 332.556Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M371.086 332.556L484.304 276.239L658.434 315.594L658.601 315.427L767.976 198.031L867.908 256.019L968.509 325.036L969.01 324.367L868.409 255.267L767.808 196.945L767.558 197.196L658.183 314.675L484.22 275.404L370.668 331.804L371.086 332.556Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M371.086 335.731L483.97 280.417L667.374 317.934L667.542 317.767L768.978 204.047L869.997 262.453L968.593 326.958L969.094 326.206L870.415 261.701L768.811 202.961L768.644 203.212L667.124 317.015L483.886 279.582L483.719 279.665L370.668 334.979L371.086 335.731Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M371.086 335.731L483.97 280.417L667.374 317.934L667.542 317.767L768.978 204.047L869.997 262.453L968.593 326.958L969.094 326.206L870.415 261.701L768.811 202.961L768.644 203.212L667.124 317.015L483.886 279.582L483.719 279.665L370.668 334.979L371.086 335.731Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M371.086 338.906L483.636 284.595L676.315 320.273L676.482 320.023L770.065 210.063L872.086 268.887L968.676 328.796L969.178 328.044L872.504 268.135L769.814 208.977L769.647 209.228L675.981 319.354L483.552 283.759L483.385 283.843L370.668 338.154L371.086 338.906Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M371.086 338.906L483.636 284.595L676.315 320.273L676.482 320.023L770.065 210.063L872.086 268.887L968.676 328.796L969.178 328.044L872.504 268.135L769.814 208.977L769.647 209.228L675.981 319.354L483.552 283.759L483.385 283.843L370.668 338.154L371.086 338.906Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M371.086 342.081L483.301 288.773L685.255 322.613L771.067 216.079L874.175 275.32L968.843 330.634L969.261 329.966L874.593 274.568L770.9 214.993L770.649 215.244L684.921 321.694L483.218 287.937L483.051 288.021L370.668 341.329L371.086 342.081Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M371.086 342.081L483.301 288.773L685.255 322.613L771.067 216.079L874.175 275.32L968.843 330.634L969.261 329.966L874.593 274.568L770.9 214.993L770.649 215.244L684.921 321.694L483.218 287.937L483.051 288.021L370.668 341.329L371.086 342.081Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M371.086 345.173L482.967 292.951L694.196 324.952L694.363 324.702L772.07 222.095L876.18 281.754L968.927 332.556L969.345 331.804L876.598 281.002L771.903 221.009L693.862 324.033L482.884 292.115L482.716 292.199L370.668 344.421L371.086 345.173Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M371.086 345.173L482.967 292.951L694.196 324.952L694.363 324.702L772.07 222.095L876.18 281.754L968.927 332.556L969.345 331.804L876.598 281.002L771.903 221.009L693.862 324.033L482.884 292.115L482.716 292.199L370.668 344.421L371.086 345.173Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M371.086 348.348L482.633 297.128L703.136 327.292L703.303 327.041L773.156 228.111L878.269 288.188L969.01 334.394L969.428 333.642L878.687 287.436L772.905 227.025L772.655 227.359L702.802 326.373L482.549 296.293L482.382 296.376L370.668 347.596L371.086 348.348Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M371.086 348.348L482.633 297.128L703.136 327.292L703.303 327.041L773.156 228.111L878.269 288.188L969.01 334.394L969.428 333.642L878.687 287.436L772.905 227.025L772.655 227.359L702.802 326.373L482.549 296.293L482.382 296.376L370.668 347.596L371.086 348.348Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M371.086 351.272C372.005 350.771 480.293 303.144 483.803 303.061C485.557 303.144 534.772 309.745 535.273 309.829L705.225 328.879L723.19 305.4L778.587 235.214L880.776 293.87L969.01 339.157L969.428 338.405L881.277 293.118L778.42 234.044L722.605 304.815L704.975 327.877L535.524 308.91C533.518 308.659 485.724 302.225 483.97 302.142C480.628 302.142 371.086 350.019 370.668 350.52L371.002 350.771L371.42 350.688L371.002 350.771L371.086 351.272Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M371.086 351.272C372.005 350.771 480.293 303.144 483.803 303.061C485.557 303.144 534.772 309.745 535.273 309.829L705.225 328.879L723.19 305.4L778.587 235.214L880.776 293.87L969.01 339.157L969.428 338.405L881.277 293.118L778.42 234.044L722.605 304.815L704.975 327.877L535.524 308.91C533.518 308.659 485.724 302.225 483.97 302.142C480.628 302.142 371.086 350.019 370.668 350.52L371.002 350.771L371.42 350.688L371.002 350.771L371.086 351.272Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M371.086 354.28C371.922 353.779 477.87 309.16 484.973 309.16C484.973 309.16 484.973 309.16 485.056 309.16C488.482 309.327 536.359 315.594 536.861 315.678L707.398 330.634L726.114 308.074L784.102 242.316L883.366 299.635L968.927 344.003L969.345 343.251L883.784 298.883L783.935 241.23L783.684 241.48L725.446 307.406L706.98 329.631L536.944 314.759C535.022 314.508 488.565 308.408 485.056 308.241C481.714 308.158 450.798 320.357 425.564 330.634C403.589 339.575 370.835 353.194 370.501 353.612L370.334 353.779L370.418 353.946L371.253 353.696L370.835 353.779L371.086 354.28Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M371.086 354.28C371.922 353.779 477.87 309.16 484.973 309.16C484.973 309.16 484.973 309.16 485.056 309.16C488.482 309.327 536.359 315.594 536.861 315.678L707.398 330.634L726.114 308.074L784.102 242.316L883.366 299.635L968.927 344.003L969.345 343.251L883.784 298.883L783.935 241.23L783.684 241.48L725.446 307.406L706.98 329.631L536.944 314.759C535.022 314.508 488.565 308.408 485.056 308.241C481.714 308.158 450.798 320.357 425.564 330.634C403.589 339.575 370.835 353.194 370.501 353.612L370.334 353.779L370.418 353.946L371.253 353.696L370.835 353.779L371.086 354.28Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M371.086 357.205C371.838 356.787 475.531 315.093 486.142 315.093C486.226 315.093 486.226 315.093 486.309 315.093C491.49 315.344 537.947 321.276 538.364 321.36L709.486 332.222L728.955 310.497L789.616 249.502L886.04 305.4L969.01 348.849L969.428 348.097L886.457 304.732L789.533 248.499L728.454 309.996L709.236 331.386L538.532 320.524C536.61 320.273 491.657 314.508 486.393 314.257C486.309 314.257 486.309 314.257 486.226 314.257C480.795 314.257 450.046 325.537 425.146 335.063C401.584 344.003 371.002 356.119 370.585 356.62L370.418 356.787L370.501 357.038L371.337 356.787L370.919 356.871L371.086 357.205Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M371.086 357.205C371.838 356.787 475.531 315.093 486.142 315.093C486.226 315.093 486.226 315.093 486.309 315.093C491.49 315.344 537.947 321.276 538.364 321.36L709.486 332.222L728.955 310.497L789.616 249.502L886.04 305.4L969.01 348.849L969.428 348.097L886.457 304.732L789.533 248.499L728.454 309.996L709.236 331.386L538.532 320.524C536.61 320.273 491.657 314.508 486.393 314.257C486.309 314.257 486.309 314.257 486.226 314.257C480.795 314.257 450.046 325.537 425.146 335.063C401.584 344.003 371.002 356.119 370.585 356.62L370.418 356.787L370.501 357.038L371.337 356.787L370.919 356.871L371.086 357.205Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M371.086 360.213C372.089 359.628 397.74 349.852 425.481 339.993C459.906 327.793 481.797 321.109 487.312 321.109C487.396 321.109 487.479 321.109 487.563 321.109C494.498 321.36 539.451 327.041 539.952 327.125L711.659 333.893L731.963 313.088L795.215 256.604L888.546 311.082L968.927 353.612L969.261 352.86L888.881 310.414L794.881 255.601L731.211 312.503L711.158 333.057L539.869 326.289C539.451 326.206 494.414 320.608 487.479 320.273C487.396 320.273 487.312 320.273 487.229 320.273C480.043 320.273 449.294 330.634 424.562 339.408C401.333 347.68 371.003 358.96 370.501 359.628L370.334 359.795L370.418 360.046L370.835 359.962L371.086 360.213Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M371.086 360.213C372.089 359.628 397.74 349.852 425.481 339.993C459.906 327.793 481.797 321.109 487.312 321.109C487.396 321.109 487.479 321.109 487.563 321.109C494.498 321.36 539.451 327.041 539.952 327.125L711.659 333.893L731.963 313.088L795.215 256.604L888.546 311.082L968.927 353.612L969.261 352.86L888.881 310.414L794.881 255.601L731.211 312.503L711.158 333.057L539.869 326.289C539.451 326.206 494.414 320.608 487.479 320.273C487.396 320.273 487.312 320.273 487.229 320.273C480.043 320.273 449.294 330.634 424.562 339.408C401.333 347.68 371.003 358.96 370.501 359.628L370.334 359.795L370.418 360.046L370.835 359.962L371.086 360.213Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M371.17 363.137C372.172 362.469 397.072 353.528 425.063 344.337C459.822 332.974 482.466 326.791 488.816 327.041C497.506 327.376 541.038 332.723 541.456 332.807L713.664 335.48L734.72 315.511L800.562 263.623L891.137 316.848L968.843 358.375L969.261 357.623L891.471 316.096L800.479 262.703L734.136 314.926L713.33 334.645L541.456 331.971C541.038 331.888 497.506 326.624 488.816 326.206C480.544 325.872 449.044 335.564 424.144 343.752C399.411 351.857 371.086 361.884 370.585 362.552L370.418 362.72L370.501 362.97L370.919 362.887L371.17 363.137Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M371.17 363.137C372.172 362.469 397.072 353.528 425.063 344.337C459.822 332.974 482.466 326.791 488.816 327.041C497.506 327.376 541.038 332.723 541.456 332.807L713.664 335.48L734.72 315.511L800.562 263.623L891.137 316.848L968.843 358.375L969.261 357.623L891.471 316.096L800.479 262.703L734.136 314.926L713.33 334.645L541.456 331.971C541.038 331.888 497.506 326.624 488.816 326.206C480.544 325.872 449.044 335.564 424.144 343.752C399.411 351.857 371.086 361.884 370.585 362.552L370.418 362.72L370.501 362.97L370.919 362.887L371.17 363.137Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M371.17 366.145C372.172 365.477 396.821 357.121 424.562 348.766C459.154 338.321 482.466 332.807 490.069 333.057C500.514 333.475 542.626 338.489 543.044 338.572L715.837 337.152L737.645 318.101L806.077 270.808L893.643 322.529L968.843 363.137L969.261 362.469L894.061 321.861L805.993 269.806L737.06 317.433L715.419 336.316L542.96 337.737C542.626 337.653 500.43 332.723 489.986 332.222C480.043 331.804 448.375 340.661 423.559 348.097C398.576 355.617 371.086 364.725 370.501 365.477L370.334 365.644L370.418 365.895L371.253 365.644L370.835 365.728L371.17 366.145Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M371.17 366.145C372.172 365.477 396.821 357.121 424.562 348.766C459.154 338.321 482.466 332.807 490.069 333.057C500.514 333.475 542.626 338.489 543.044 338.572L715.837 337.152L737.645 318.101L806.077 270.808L893.643 322.529L968.843 363.137L969.261 362.469L894.061 321.861L805.993 269.806L737.06 317.433L715.419 336.316L542.96 337.737C542.626 337.653 500.43 332.723 489.986 332.222C480.043 331.804 448.375 340.661 423.559 348.097C398.576 355.617 371.086 364.725 370.501 365.477L370.334 365.644L370.418 365.895L371.253 365.644L370.835 365.728L371.17 366.145Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M371.17 369.07C372.172 368.402 396.069 360.798 423.977 353.111C443.947 347.596 479.207 338.489 491.239 338.99C503.355 339.491 544.046 344.17 544.464 344.254L717.842 338.739L717.926 338.656L740.402 320.524L811.425 277.827L896.234 328.295L968.843 367.984L969.261 367.232L896.651 327.543L811.508 276.908L811.257 277.075L739.901 319.939L717.508 337.987L544.464 343.502C544.13 343.418 503.438 338.739 491.239 338.238C479.625 337.737 447.79 345.758 423.058 352.526C397.74 359.461 371.086 367.733 370.501 368.485L370.334 368.652L370.418 368.903L371.253 368.652L370.835 368.736L371.17 369.07Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M371.17 369.07C372.172 368.402 396.069 360.798 423.977 353.111C443.947 347.596 479.207 338.489 491.239 338.99C503.355 339.491 544.046 344.17 544.464 344.254L717.842 338.739L717.926 338.656L740.402 320.524L811.425 277.827L896.234 328.295L968.843 367.984L969.261 367.232L896.651 327.543L811.508 276.908L811.257 277.075L739.901 319.939L717.508 337.987L544.464 343.502C544.13 343.418 503.438 338.739 491.239 338.238C479.625 337.737 447.79 345.758 423.058 352.526C397.74 359.461 371.086 367.733 370.501 368.485L370.334 368.652L370.418 368.903L371.253 368.652L370.835 368.736L371.17 369.07Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.76 372.746L969.178 371.994L899.158 333.224L816.939 284.01L742.825 322.362L719.597 339.575L545.968 349.1C545.634 349.016 506.363 344.672 492.493 344.087C478.539 343.502 442.693 351.774 422.556 356.787C396.988 363.137 371.17 370.49 370.501 371.326L370.334 371.493L370.418 371.744L371.253 371.493L370.835 371.576L371.086 371.827C374.094 369.738 468.512 343.836 492.409 344.839C506.279 345.424 545.55 349.768 545.968 349.852L719.931 340.243L743.327 322.863L817.023 284.762L898.824 333.726L968.76 372.746Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.76 372.746L969.178 371.994L899.158 333.224L816.939 284.01L742.825 322.362L719.597 339.575L545.968 349.1C545.634 349.016 506.363 344.672 492.493 344.087C478.539 343.502 442.693 351.774 422.556 356.787C396.988 363.137 371.17 370.49 370.501 371.326L370.334 371.493L370.418 371.744L371.253 371.493L370.835 371.576L371.086 371.827C374.094 369.738 468.512 343.836 492.409 344.839C506.279 345.424 545.55 349.768 545.968 349.852L719.931 340.243L743.327 322.863L817.023 284.762L898.824 333.726L968.76 372.746Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.76 377.592L969.178 376.84L901.748 338.99L822.538 291.196L822.37 291.279L745.833 324.952L721.853 341.329L547.639 354.949C547.305 354.949 509.538 350.855 493.829 350.186C478.121 349.518 442.192 356.871 422.222 361.299C398.409 366.563 371.42 373.415 370.585 374.417L370.418 374.584L370.501 374.835L370.919 374.752L371.17 375.002C372.34 374.25 395.484 367.984 422.974 361.968C442.777 357.623 478.205 350.437 493.746 351.022C509.371 351.69 547.138 355.701 547.556 355.784L722.02 342.165L746.168 325.704L822.454 292.199L901.331 339.825L968.76 377.592Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.76 377.592L969.178 376.84L901.748 338.99L822.538 291.196L822.37 291.279L745.833 324.952L721.853 341.329L547.639 354.949C547.305 354.949 509.538 350.855 493.829 350.186C478.121 349.518 442.192 356.871 422.222 361.299C398.409 366.563 371.42 373.415 370.585 374.417L370.418 374.584L370.501 374.835L370.919 374.752L371.17 375.002C372.34 374.25 395.484 367.984 422.974 361.968C442.777 357.623 478.205 350.437 493.746 351.022C509.371 351.69 547.138 355.701 547.556 355.784L722.02 342.165L746.168 325.704L822.454 292.199L901.331 339.825L968.76 377.592Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.76 382.355L969.178 381.603L904.339 344.672L828.052 298.298L827.885 298.382L748.674 327.459L723.942 343L549.227 360.714C547.806 360.547 512.295 356.954 495.083 356.202C477.536 355.45 441.607 361.801 421.721 365.644C397.657 370.323 371.42 376.339 370.585 377.342L370.418 377.509L370.501 377.76L370.919 377.676L371.253 377.927C373.509 376.339 464.585 355.701 495.083 357.038C512.462 357.79 548.809 361.466 549.143 361.55H549.227H549.31L724.276 343.836L749.176 328.211L828.136 299.301L904.088 345.507L968.76 382.355Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.76 382.355L969.178 381.603L904.339 344.672L828.052 298.298L827.885 298.382L748.674 327.459L723.942 343L549.227 360.714C547.806 360.547 512.295 356.954 495.083 356.202C477.536 355.45 441.607 361.801 421.721 365.644C397.657 370.323 371.42 376.339 370.585 377.342L370.418 377.509L370.501 377.76L370.919 377.676L371.253 377.927C373.509 376.339 464.585 355.701 495.083 357.038C512.462 357.79 548.809 361.466 549.143 361.55H549.227H549.31L724.276 343.836L749.176 328.211L828.136 299.301L904.088 345.507L968.76 382.355Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.76 387.201L969.178 386.449L906.929 350.52L833.567 305.484L751.515 329.966L726.031 344.588L550.647 366.479C549.143 366.312 515.053 363.054 496.253 362.218C476.951 361.383 440.855 366.814 421.136 370.072C396.905 374.083 371.337 379.263 370.501 380.35L370.334 380.517L370.418 380.768L371.253 380.517L370.835 380.6L371.17 380.851C372.256 380.099 393.98 375.336 421.972 370.741C441.524 367.482 477.118 362.218 496.253 362.97C515.387 363.806 550.313 367.148 550.647 367.231H550.731H550.814L726.365 345.423L726.448 345.34L752.017 330.634L833.567 306.319L906.595 351.189L968.76 387.201Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.76 387.201L969.178 386.449L906.929 350.52L833.567 305.484L751.515 329.966L726.031 344.588L550.647 366.479C549.143 366.312 515.053 363.054 496.253 362.218C476.951 361.383 440.855 366.814 421.136 370.072C396.905 374.083 371.337 379.263 370.501 380.35L370.334 380.517L370.418 380.768L371.253 380.517L370.835 380.6L371.17 380.851C372.256 380.099 393.98 375.336 421.972 370.741C441.524 367.482 477.118 362.218 496.253 362.97C515.387 363.806 550.313 367.148 550.647 367.231H550.731H550.814L726.365 345.423L726.448 345.34L752.017 330.634L833.567 306.319L906.595 351.189L968.76 387.201Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.676 391.964L969.094 391.212L909.435 356.202L838.998 312.586H838.831L754.356 332.389H754.272L727.952 346.176L552.068 372.078C550.564 371.911 517.893 368.986 497.422 368.067C461.66 366.563 372.005 381.185 370.501 383.191L370.334 383.358L370.418 383.608L370.835 383.525L371.17 383.776C373.843 381.937 460.574 367.399 497.506 368.903C518.395 369.738 551.817 372.83 552.151 372.913H552.235H552.318L728.454 347.011L754.857 333.141L838.914 313.505L909.017 356.954L968.676 391.964Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.676 391.964L969.094 391.212L909.435 356.202L838.998 312.586H838.831L754.356 332.389H754.272L727.952 346.176L552.068 372.078C550.564 371.911 517.893 368.986 497.422 368.067C461.66 366.563 372.005 381.185 370.501 383.191L370.334 383.358L370.418 383.608L370.835 383.525L371.17 383.776C373.843 381.937 460.574 367.399 497.506 368.903C518.395 369.738 551.817 372.83 552.151 372.913H552.235H552.318L728.454 347.011L754.857 333.141L838.914 313.505L909.017 356.954L968.676 391.964Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.676 396.81L969.094 396.058L912.025 361.968L844.512 319.772H844.345L757.28 334.979H757.197L730.125 347.93L553.655 377.843C552.151 377.676 520.901 375.002 498.676 374.083C460.073 372.496 372.172 384.026 370.501 386.199L370.334 386.366L370.418 386.616L371.253 386.366L370.835 386.449L371.17 386.7C373.258 385.196 457.733 373.164 498.676 374.835C521.236 375.754 553.321 378.512 553.655 378.595H553.739H553.822L730.459 348.599H730.543L757.615 335.648L844.429 320.44L911.608 362.636L968.676 396.81Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.676 396.81L969.094 396.058L912.025 361.968L844.512 319.772H844.345L757.28 334.979H757.197L730.125 347.93L553.655 377.843C552.151 377.676 520.901 375.002 498.676 374.083C460.073 372.496 372.172 384.026 370.501 386.199L370.334 386.366L370.418 386.616L371.253 386.366L370.835 386.449L371.17 386.7C373.258 385.196 457.733 373.164 498.676 374.835C521.236 375.754 553.321 378.512 553.655 378.595H553.739H553.822L730.459 348.599H730.543L757.615 335.648L844.429 320.44L911.608 362.636L968.676 396.81Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.676 401.573L969.094 400.821L914.616 367.649L850.111 326.791H849.944L760.288 337.402L732.381 349.518L555.326 383.525C553.822 383.441 523.993 381.018 500.096 380.016C458.402 378.261 372.339 386.951 370.668 389.123L370.501 389.29L370.585 389.541L371.003 389.457L371.337 389.708C373.593 388.12 455.31 379.013 500.096 380.935C524.411 381.937 555.076 384.36 555.326 384.444H555.41H555.493L732.715 350.353H732.799L760.706 338.238L850.111 327.626L914.365 368.318L968.676 401.573Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.676 401.573L969.094 400.821L914.616 367.649L850.111 326.791H849.944L760.288 337.402L732.381 349.518L555.326 383.525C553.822 383.441 523.993 381.018 500.096 380.016C458.402 378.261 372.339 386.951 370.668 389.123L370.501 389.29L370.585 389.541L371.003 389.457L371.337 389.708C373.593 388.12 455.31 379.013 500.096 380.935C524.411 381.937 555.076 384.36 555.326 384.444H555.41H555.493L732.715 350.353H732.799L760.706 338.238L850.111 327.626L914.365 368.318L968.676 401.573Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.593 406.336L969.01 405.667L917.122 373.415L855.542 333.976H855.375L763.046 339.992H762.962L734.219 351.272L556.747 389.374C555.243 389.29 526.75 387.201 501.266 386.115C457.148 384.277 372.339 389.875 370.585 392.131L370.418 392.298L370.501 392.549L371.337 392.298L370.919 392.382L371.253 392.632C373.593 391.045 453.221 384.862 501.266 386.867C527.335 387.953 556.496 390.042 556.83 390.126H556.914H556.997L734.72 352.024L763.464 340.744L855.542 334.728L916.872 374L968.593 406.336Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.593 406.336L969.01 405.667L917.122 373.415L855.542 333.976H855.375L763.046 339.992H762.962L734.219 351.272L556.747 389.374C555.243 389.29 526.75 387.201 501.266 386.115C457.148 384.277 372.339 389.875 370.585 392.131L370.418 392.298L370.501 392.549L371.337 392.298L370.919 392.382L371.253 392.632C373.593 391.045 453.221 384.862 501.266 386.867C527.335 387.953 556.496 390.042 556.83 390.126H556.914H556.997L734.72 352.024L763.464 340.744L855.542 334.728L916.872 374L968.593 406.336Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.593 411.182L969.01 410.514L919.713 379.18L861.057 341.162H860.889L765.97 342.583H765.887L736.391 353.027L558.167 395.223C556.663 395.139 529.591 393.385 502.352 392.215C454.642 390.21 372.172 393.05 370.418 395.223L370.25 395.39L370.334 395.641L370.752 395.557L371.086 395.808C373.843 393.97 453.89 391.045 502.352 393.05C530.176 394.22 557.917 395.975 558.167 396.058H558.251H558.334L736.642 353.863L766.221 343.335L860.889 341.914L919.295 379.849L968.593 411.182Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.593 411.182L969.01 410.514L919.713 379.18L861.057 341.162H860.889L765.97 342.583H765.887L736.391 353.027L558.167 395.223C556.663 395.139 529.591 393.385 502.352 392.215C454.642 390.21 372.172 393.05 370.418 395.223L370.25 395.39L370.334 395.641L370.752 395.557L371.086 395.808C373.843 393.97 453.89 391.045 502.352 393.05C530.176 394.22 557.917 395.975 558.167 396.058H558.251H558.334L736.642 353.863L766.221 343.335L860.889 341.914L919.295 379.849L968.593 411.182Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.593 415.945L969.01 415.276L922.303 384.862L866.571 348.181H866.488L768.978 344.922H768.895L738.647 354.531L559.838 400.821C558.334 400.738 532.599 399.317 503.772 398.064C455.895 396.058 372.423 395.724 370.585 398.064L370.418 398.231L370.501 398.482L371.337 398.231L370.919 398.314L371.253 398.565C374.01 396.81 451.049 396.643 503.772 398.816C533.351 400.069 559.671 401.49 559.922 401.573H560.005H560.089L738.982 355.283L769.229 345.758L866.488 349.017L921.969 385.53L968.593 415.945Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.593 415.945L969.01 415.276L922.303 384.862L866.571 348.181H866.488L768.978 344.922H768.895L738.647 354.531L559.838 400.821C558.334 400.738 532.599 399.317 503.772 398.064C455.895 396.058 372.423 395.724 370.585 398.064L370.418 398.231L370.501 398.482L371.337 398.231L370.919 398.314L371.253 398.565C374.01 396.81 451.049 396.643 503.772 398.816C533.351 400.069 559.671 401.49 559.922 401.573H560.005H560.089L738.982 355.283L769.229 345.758L866.488 349.017L921.969 385.53L968.593 415.945Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.509 420.791L969.01 420.122L872.169 355.45H872.086L771.903 347.596H771.819L561.342 406.67C559.253 406.586 513.047 404.414 467.092 402.659C372.256 399.066 371.086 400.57 370.585 401.155C370.501 401.322 370.418 401.489 370.501 401.657L371.337 401.406C371.42 401.573 371.337 401.74 371.253 401.74C374.93 399.4 509.788 405.082 561.426 407.505H561.509H561.593L772.07 348.432L872.002 356.286L968.509 420.791Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.509 420.791L969.01 420.122L872.169 355.45H872.086L771.903 347.596H771.819L561.342 406.67C559.253 406.586 513.047 404.414 467.092 402.659C372.256 399.066 371.086 400.57 370.585 401.155C370.501 401.322 370.418 401.489 370.501 401.657L371.337 401.406C371.42 401.573 371.337 401.74 371.253 401.74C374.93 399.4 509.788 405.082 561.426 407.505H561.509H561.593L772.07 348.432L872.002 356.286L968.509 420.791Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.593 428.478L969.01 427.809L871.334 366.73L771.318 359.294H771.234L560.841 415.109C558.919 415.025 512.629 413.02 466.757 411.349C372.089 407.923 371.003 409.427 370.585 409.928L370.418 410.096L370.501 410.346L371.253 410.096L370.835 410.179L371.086 410.43C374.679 408.174 509.287 413.605 560.841 415.861H560.925H561.008L771.401 360.129L871.083 367.482L968.593 428.478Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.593 428.478L969.01 427.809L871.334 366.73L771.318 359.294H771.234L560.841 415.109C558.919 415.025 512.629 413.02 466.757 411.349C372.089 407.923 371.003 409.427 370.585 409.928L370.418 410.096L370.501 410.346L371.253 410.096L370.835 410.179L371.086 410.43C374.679 408.174 509.287 413.605 560.841 415.861H560.925H561.008L771.401 360.129L871.083 367.482L968.593 428.478Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.676 436.248L969.094 435.58L870.665 378.094H870.582L770.9 371.159H770.816L560.423 423.632C528.923 422.295 372.757 415.944 370.585 418.785L370.501 418.952V419.203L371.253 418.952L370.919 419.036L371.17 419.203C374.512 417.198 496.503 421.626 560.507 424.3H560.59H560.674L770.9 371.827L870.415 378.762L968.676 436.248Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.676 436.248L969.094 435.58L870.665 378.094H870.582L770.9 371.159H770.816L560.423 423.632C528.923 422.295 372.757 415.944 370.585 418.785L370.501 418.952V419.203L371.253 418.952L370.919 419.036L371.17 419.203C374.512 417.198 496.503 421.626 560.507 424.3H560.59H560.674L770.9 371.827L870.415 378.762L968.676 436.248Z",
            fill: "#414141"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.844 443.936L969.178 443.267L869.914 389.374H869.83L770.399 382.857H770.315L560.089 432.071C528.672 430.817 372.757 424.885 370.668 427.475L370.585 427.642V427.893L371.253 427.642L370.919 427.726L371.17 427.893C374.428 425.971 496.169 430.233 560.089 432.656H560.173H560.256L770.399 383.609L869.663 390.126L968.844 443.936Z",
            fill: "black"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M968.844 443.936L969.178 443.267L869.914 389.374H869.83L770.399 382.857H770.315L560.089 432.071C528.672 430.817 372.757 424.885 370.668 427.475L370.585 427.642V427.893L371.253 427.642L370.919 427.726L371.17 427.893C374.428 425.971 496.169 430.233 560.089 432.656H560.173H560.256L770.399 383.609L869.663 390.126L968.844 443.936Z",
            fill: "#414141"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }