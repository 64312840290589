<template>
  <div class="contact-form">
    <div key="form" class="contact-form__wrapper">
      <div class="contact-form__title" v-if="formTitle">{{ formTitle }}</div>

      <form class="form">
        <div class="form-field" v-for="(field, key) in fields">
          <div class="step" v-if="field !== 'file'" :class="{active: active === field}">
            <span v-if="!valid[field]">0{{ key + 1 }}</span>
            <tick-svg v-else></tick-svg>
          </div>


          <md-field :class="{'md-invalid': $v.form[field].$error}" v-if="textField.includes(field)">
            <label>{{ labels[field] }}</label>
            <md-input v-model="form[field]" @focus="active = field" @blur="customValidate(field)"></md-input>
            <span class="md-error">{{ getErrorMessage($v.form[field]) }}</span>
          </md-field>

          <!--            <md-field :class="{'md-invalid': $v.form.email.$error}" v-if="field === 'email'">-->
          <!--              <label>{{ labels.email }}</label>-->
          <!--              <md-input v-model="form.email" @focus="active = 'email'" @blur="customValidate('email')"></md-input>-->
          <!--              <span class="md-error">{{ getErrorMessage($v.form.email) }}</span>-->
          <!--            </md-field>-->

          <cm-select v-model="form.type" v-else-if="field === 'type'"
                     :label="labels.type"
                     :error="getErrorMessage($v.form.type)"
                     :items="types"
                     @change="customValidate('type')"
                     v-on:active="active = $event ? 'type' : $event"></cm-select>

          <md-field :class="{'md-invalid': $v.form.message.$error}" v-else-if="field === 'message'">
            <label>{{ labels.message }}</label>
            <md-textarea v-model="form.message"
                         @focus="active = 'message'"
                         @blur="customValidate('message')" md-autogrow />
            <span class="md-error">{{ getErrorMessage($v.form.message) }}</span>
          </md-field>

          <file-input v-model="form.file" v-else-if="field === 'file'"
                      :label="labels.file"
                      v-on:active="active = $event ? 'file' : $event"
                      @change="customValidate('file')"
                      :error="getErrorMessage($v.form.file)"></file-input>
        </div>

        <div class="btn-row contact-form__button">
          <button type="submit"
                  @click="submit"
                  :class="{ 'btn-transparent': !filledButton }"
                  class="btn btn-rounded"><span>{{ formButton }}</span></button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
import {minLength, required, email} from "vuelidate/lib/validators";
import TickSvg from '../../icons/tick.svg';
import CmSelect from "./form/cmSelect";
import FileInput from "./form/fileInput";

/**
 * @property {object} $v
 */
export default {
  name: "contactForm",
  components: {FileInput, CmSelect, InlineSvg, TickSvg},
  props: {
    title: String,
    text: String,
    button: String,
    filledButton: Boolean,
    hideText: Boolean,
    fields: {
      type: Array,
      default: function () {
        return ['name', 'email', 'type', 'message'];
      }
    },
    accept: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      form: {
        name: '',
        phone: '',
        email: '',
        type: null,
        message: '',
        file: null,
      },
      valid: {
        name: false,
        email: false,
        type: false,
        message: false,
      },
      active: false,
      loading: false,
      success: false,

      textField: ['name', 'phone', 'email']
    }
  },
  validations() {
    let valid = {
      form: {},
    }

    this.fields.forEach(field => {
      switch (field) {
        case 'name':
          valid.form[field] = {
            required,
            minLength: minLength(3)
          };
          break;
        case 'email':
          valid.form[field] = {
            required,
            email,
          };
          break;
        default:
          valid.form[field] = {
            required,
          };
          break;
      }
    });

    return valid;
  },
  computed: {
    options() {
      return this.$store.state.options;
    },
    labels() {
      return this.options.form;
    },
    formTitle() {
      return this.title || this.options.form.title;
    },
    formButton() {
      return this.button || this.options.form.button;
    },
    types() {
      return this.options.form.types.map(item => item.text);
    }
  },
  methods: {
    submit(event) {
      event.preventDefault();

      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.sendForm();
      }
      // this.success = true;
      // this.$emit('success');
    },
    sendForm() {
      let formData = new FormData();
      formData.append('action', 'sendCallback');

      let form = Object.assign(this.form, this.accept);
      // noinspection JSCheckFunctionSignatures
      Object.entries(form).forEach(([key, value]) => {
        formData.append(key, value);
      });

      let data = {
        options: {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'multipart/form-data',
          }
        },
        data: formData,
      };

      if (!this.loading) {
        this.loading = true;
        return this.$store.dispatch('sendRequest', data).then(res => {
          // console.log(res);
          this.loading = false;

          if (res.data.success && res.data.data.result) {
            this.success = true;

            let event = new Event("form_send");
            document.dispatchEvent(event);
            Object.assign(this.$data, this.$options.data.apply(this));
            this.$v.$reset();

            this.$emit('success');
          }
        });
      }
    },
    customValidate(name) {
      this.active = false;
      let value = this.form[name];
      if (!value || !value.length) {
        this.valid[name] = false;
        return false;
      }

      switch (name) {
        case 'name':
          if (value.length > 2) {
            this.valid[name] = true;
          }
          break;
        case 'email':
          if (email(value)) {
            this.valid[name] = true;
          }
          break;
        default:
          this.valid[name] = true;
          break;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../styles/common/variables";
@import "../../styles/common/mixins";

.contact-form {
  max-width: 562px;
  //max-width: 562px;
  //text-align: center;

  &__wrapper {
    //display: flex;
    //flex-direction: column;
    //justify-content: center;
    //align-items: center;
  }

  &__title {
    max-width: 370px;
    font-size: 34px;
    margin-bottom: 30px;
    color: #FFFFFF;

    @include mobile {
      text-align: center;
    }
  }

  &__text {
    margin-bottom: 30px;
  }

  .form {
    color: $c-gray;

    .form-field {
      position: relative;

      .step {
        position: absolute;
        //top: 0;
        top: 12px;
        left: -44px;

        font-size: 18px;

        &.active {
          color: #ffffff;
        }

        @include mobile {
          display: none;
        }
      }
    }
  }

  &__button {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
}
</style>

<style lang="scss">
@import '~vue-material/dist/vue-material.min.css';
@import "~vue-material/dist/theme/engine"; // Import the theme engine

@include md-register-theme("custom", (
  primary: #FEFD54, // The primary color of your brand
  accent: #ff5252, // The secondary color of your brand
  theme: dark // This can be dark or light
));

//@import "~vue-material/dist/components/MdField/theme"; // Apply the Button theme
@import "~vue-material/dist/theme/all"; // Apply the theme
@import "../../styles/common/variables";


.contact-form {
  input {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:active,
    &:-webkit-autofill:focus {
      background-color: transparent !important;
      //color: #555 !important;
      -webkit-box-shadow: 0 0 0 1000px $c-body inset !important;
      //-webkit-box-shadow: none !important;
      //-webkit-text-fill-color: #ffffff !important;
    }
  }
}
</style>
