var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: function() {
            return (this$1.active = false)
          },
          expression: "()=>this.active = false"
        }
      ],
      staticClass: "cm-select",
      class: {
        active: _vm.active,
        filled: _vm.value && _vm.value.length,
        error: _vm.error
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "cm-select__inner",
          on: {
            click: function($event) {
              $event.stopPropagation()
              _vm.active = !_vm.active
            }
          }
        },
        [
          _vm.label
            ? _c("div", { staticClass: "cm-select__label" }, [
                _vm._v(_vm._s(_vm.label))
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("input", {
            ref: "input",
            staticClass: "cm-select__input",
            attrs: { type: "text", readonly: "" },
            domProps: { value: _vm.value }
          }),
          _vm._v(" "),
          _c("span", { staticClass: "cm-select__tick" }, [_c("angle-svg")], 1)
        ]
      ),
      _vm._v(" "),
      _vm.error
        ? _c("div", { staticClass: "cm-select__error" }, [
            _vm._v(_vm._s(_vm.error))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide" } }, [
        _vm.active
          ? _c(
              "div",
              { staticClass: "cm-select__items" },
              _vm._l(_vm.items, function(item) {
                return _c(
                  "div",
                  {
                    staticClass: "cm-select__item",
                    on: {
                      click: function($event) {
                        return _vm.setValue(item)
                      }
                    }
                  },
                  [_vm._v(_vm._s(item))]
                )
              }),
              0
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }