<template>
  <nav class="scroll-navigation" :class="direction" :style="{opacity: opacity}" v-if="isFrontPage && isDesktop">
    <img :src="logo" :alt="siteName" />

    <ul class="scroll-navigation__menu">
      <li v-for="item in menu">
        <button type="button"
                :class="{active: active === item.key}"
                @click="scrollToSection(item.key)">{{ item.name }}
        </button>
      </li>
    </ul>

    <div class="scroll-navigation__socials socials">
      <a v-for="item in socials" :href="item.link">
        <inline-svg :src="item.icon"></inline-svg>
      </a>
    </div>
  </nav>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
import {gsap} from "gsap";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";
import scrollMonitor from 'scrollmonitor';

export default {
  name: "scrollMenu",
  components: {InlineSvg},
  data() {
    return {
      windowWidth: 0,
      isDesktop: false,
      direction: 'vertical',
      active: '',
    }
  },
  watch: {
    windowWidth(newWidth) {
      this.isDesktop = newWidth >= 1280;
    }
  },
  computed: {
    options() {
      return this.$store.state.options;
    },
    fields() {
      return this.$store.state.fields;
    },
    isFrontPage() {
      return this.$store.state.isFrontPage;
    },
    logo() {
      return this.options.small_logo;
    },
    siteName() {
      return this.$store.state.siteName;
    },
    menu() {
      let items = [];

      if (this.fields && this.isFrontPage) {
        this.fields.blocks.forEach((item, key) => {
          if (key) {
            items.push({
              name: this.fields[item].name || '',
              key: item,
            });
          }
        });
      }

      return items.reverse();
    },
    socials() {
      return this.options.socials;
    },
    opacity() {
      let scroll = window.pageYOffset;
      let windowHeight = window.innerHeight;
      let half = windowHeight / 2;

      let opacity = (scroll - half) / windowHeight;
      opacity = Number(opacity.toFixed(2));
      opacity = Math.max(0, opacity);

      if (opacity > 1) {
        opacity = 1;
      }

      return opacity;
    },
  },
  mounted() {
    this.windowWidth = window.innerWidth;
    if (this.isFrontPage) {
      gsap.registerPlugin(ScrollToPlugin);

      window.addEventListener('scroll', () => this.setOpacity());
      window.addEventListener('resize', () => this.windowWidth = window.innerWidth);
      this.setActive();
    }
  },
  methods: {
    setOpacity() {
      let scroll = window.pageYOffset;
      let windowHeight = window.innerHeight;
      let half = windowHeight / 2;

      let opacity = (scroll - half) / windowHeight;
      opacity = Number(opacity.toFixed(2));
      opacity = Math.max(0, opacity);

      if (opacity > 1) {
        opacity = 1;
      }

      // noinspection JSUnresolvedVariable
      if (this.$el && this.$el.style) {
        this.$el.style.opacity = opacity;
      }
    },
    scrollToSection(key) {
      let el = document.querySelector(`.${key}-section`);

      gsap.to(window, {
        scrollTo: el,
        // scrollTo: {
        //   y: el.offsetTop,
        //   offsetY: 32,
        //   // autoKill: true,
        //   // autoKill: false
        // },
        // ease: "power2",
        duration: 1,
      });
    },
    setActive() {
      this.menu.forEach(item => {
        let el = document.querySelector(`.${item.key}-section`);

        let elementWatcher = scrollMonitor.create(el);
        // noinspection JSUnresolvedFunction
        elementWatcher.fullyEnterViewport(() => {
          this.active = item.key;
        });
      });
    }
  },
}
</script>

<style lang="scss" scoped>
.scroll-navigation {
  display: inline-flex;
  position: fixed;
  left: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  background: #191919;
  border: 1px solid #414141;

  img {
    max-width: 28px;
  }

  &__menu {
    list-style: none;
    margin: 0;
    padding: 0;

    display: flex;

    li {
      &:not(:last-child) {
        //margin-right: 20px;
        margin-right: 12px;
      }

      button {
        border: 0;
        text-shadow: none;
        background: transparent;

        font-weight: 500;
        font-size: 14px;
        text-transform: uppercase;
        text-decoration: none;
        color: #FFFFFF;
        transition: 0.4s;

        &.active, &:hover {
          color: #FEFD54;
        }
      }
    }
  }

  &.vertical {
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    width: 70px;
    padding: 30px 19px;

    .scroll-navigation__menu {
      //flex-direction: column;
      white-space: nowrap;

      //transform: rotate(
      //    -90deg);
      //transform: rotate(
      //    -90deg) translateX(-70%);
      transform: rotate(
          -90deg) translate(-10px, -10px);
      transform-origin: top;
      //transform-origin: top left;
    }

    .socials {
      flex-direction: column;

      a {
        margin-right: 0;

        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>
