var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contact-form" }, [
    _c("div", { key: "form", staticClass: "contact-form__wrapper" }, [
      _vm.formTitle
        ? _c("div", { staticClass: "contact-form__title" }, [
            _vm._v(_vm._s(_vm.formTitle))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        { staticClass: "form" },
        [
          _vm._l(_vm.fields, function(field, key) {
            return _c(
              "div",
              { staticClass: "form-field" },
              [
                field !== "file"
                  ? _c(
                      "div",
                      {
                        staticClass: "step",
                        class: { active: _vm.active === field }
                      },
                      [
                        !_vm.valid[field]
                          ? _c("span", [_vm._v("0" + _vm._s(key + 1))])
                          : _c("tick-svg")
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.textField.includes(field)
                  ? _c(
                      "md-field",
                      { class: { "md-invalid": _vm.$v.form[field].$error } },
                      [
                        _c("label", [_vm._v(_vm._s(_vm.labels[field]))]),
                        _vm._v(" "),
                        _c("md-input", {
                          on: {
                            focus: function($event) {
                              _vm.active = field
                            },
                            blur: function($event) {
                              return _vm.customValidate(field)
                            }
                          },
                          model: {
                            value: _vm.form[field],
                            callback: function($$v) {
                              _vm.$set(_vm.form, field, $$v)
                            },
                            expression: "form[field]"
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "md-error" }, [
                          _vm._v(
                            _vm._s(_vm.getErrorMessage(_vm.$v.form[field]))
                          )
                        ])
                      ],
                      1
                    )
                  : field === "type"
                  ? _c("cm-select", {
                      attrs: {
                        label: _vm.labels.type,
                        error: _vm.getErrorMessage(_vm.$v.form.type),
                        items: _vm.types
                      },
                      on: {
                        change: function($event) {
                          return _vm.customValidate("type")
                        },
                        active: function($event) {
                          _vm.active = $event ? "type" : $event
                        }
                      },
                      model: {
                        value: _vm.form.type,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type"
                      }
                    })
                  : field === "message"
                  ? _c(
                      "md-field",
                      { class: { "md-invalid": _vm.$v.form.message.$error } },
                      [
                        _c("label", [_vm._v(_vm._s(_vm.labels.message))]),
                        _vm._v(" "),
                        _c("md-textarea", {
                          attrs: { "md-autogrow": "" },
                          on: {
                            focus: function($event) {
                              _vm.active = "message"
                            },
                            blur: function($event) {
                              return _vm.customValidate("message")
                            }
                          },
                          model: {
                            value: _vm.form.message,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "message", $$v)
                            },
                            expression: "form.message"
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "md-error" }, [
                          _vm._v(
                            _vm._s(_vm.getErrorMessage(_vm.$v.form.message))
                          )
                        ])
                      ],
                      1
                    )
                  : field === "file"
                  ? _c("file-input", {
                      attrs: {
                        label: _vm.labels.file,
                        error: _vm.getErrorMessage(_vm.$v.form.file)
                      },
                      on: {
                        active: function($event) {
                          _vm.active = $event ? "file" : $event
                        },
                        change: function($event) {
                          return _vm.customValidate("file")
                        }
                      },
                      model: {
                        value: _vm.form.file,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "file", $$v)
                        },
                        expression: "form.file"
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          }),
          _vm._v(" "),
          _c("div", { staticClass: "btn-row contact-form__button" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-rounded",
                class: { "btn-transparent": !_vm.filledButton },
                attrs: { type: "submit" },
                on: { click: _vm.submit }
              },
              [_c("span", [_vm._v(_vm._s(_vm.formButton))])]
            )
          ])
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }