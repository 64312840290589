<template>
  <div class="thank-you">
    <div class="thank-you__icon">
      <mail-svg></mail-svg>
    </div>

    <div class="thank-you__title">{{ title }}</div>
    <div class="thank-you__text" v-if="text">{{ text }}</div>

    <div class="thank-you__button">
      <button @click="close" class="btn">{{ button }}</button>
    </div>

    <div class="bg bg_el wave">
      <wave-svg></wave-svg>
    </div>
  </div>
</template>

<script>
import mailSvg from '../../icons/mail.svg';
import waveSvg from '../../icons/wave.svg';

export default {
  name: "thankYou",
  components: {mailSvg, waveSvg},
  props: {
    title: {
      type: String,
      default: 'Thank You'
    },
    text: String,
    button: {
      type: String,
      default: 'Ok'
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
}
</script>

<style lang="scss" scoped>
.thank-you {
  padding: 60px 60px 120px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__icon {
    margin-bottom: 30px;
  }

  &__title {
    font-size: 30px;
    color: #ffffff;
  }

  &__text {
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 30px;
  }

  &__button {}

  .wave {
    bottom: 0;
    left: 0;
    width: 100%;

    svg {
      width: 100%;
      height: auto;
    }
  }
}
</style>
