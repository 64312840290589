<template>
  <div class="custom-cursor-wrapper" v-show="showCursor">
    <div ref="cc1" class="custom-cursor"></div>
  </div>
</template>

<script>
import {gsap, Power1, Elastic} from "gsap";

export default {
  name: "customCursor",
  // watch: {
  //   loading(val) {
  //     this.addEvents();
  //   },
  // },
  data() {
    return {
      selectors: {
        project: ".projects-section__items",
        default: ["a", "img", ".image", ".active-cursor"],
      },
      showCursor: false,
      activeAnimation: false,
      position: {
        x: 0,
        y: 0,
      },
      cursorClass: 'with-custom-cursor',
      options: {
        default: {
          width: 10,
          height: 10,
          backgroundColor: "#FEFD54",
          opacity: 1,
        },
        active: {
          width: 30,
          height: 30,
        },
        project: {
          width: 80,
          height: 80,
          backgroundColor: "#FEFD54",
          opacity: 1,
        },
      },
      animations: {
        project: {
          kill: () => {
          },
        },
        out: {
          kill: () => {
          },
        },
        over: {
          kill: () => {
          },
        },
      }
    }
  },
  mounted() {
    // this.showCursor = true;
    this.addEvents();
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
  methods: {
    addEvents() {
      // let elements = document.querySelectorAll(this.selectors.default.join(', '));
      // elements.forEach(item => {
      //   item.addEventListener('mouseover', e => this.mouseOverAll(e));
      //   item.addEventListener('mouseleave', e => this.mouseLeaveAll(e));
      // });

      let projects = document.querySelectorAll(this.selectors.project);
      projects.forEach(item => {
        item.classList.add(this.cursorClass);
        item.addEventListener('mouseover', e => this.mouseOverProject(e));
        item.addEventListener('mouseleave', e => this.mouseLeaveProject(e));
      });


      document.documentElement.addEventListener("mousemove", (e) => this.moveEvents(e));
      // window.addEventListener('scroll', () => this.onScroll());
    },
    onScroll() {
      // this.position.y = this.position.y + window.scrollY;

      gsap.set(this.$refs.cc1, {
        x: this.position.x,
        y: this.position.y + window.scrollY,
        transformOrigin: "center",
      });
    },
    moveEvents(e) {
      this.mouseMove(e);
      let path = e.path || e.composedPath();

      path.forEach(item => {
        if (item.classList && !item.classList.contains(this.cursorClass)) {

          // this.selectors.default.forEach(selector => {
          //   if (this.match(item, selector)) {
          //     item.classList.add(this.cursorClass);
          //     item.addEventListener('mouseover', e => this.mouseOverAll(e));
          //     item.addEventListener('mouseleave', e => this.mouseLeaveAll(e));
          //   }
          // });

          if (this.match(item, this.selectors.project)) {
            item.classList.add(this.cursorClass);
            item.addEventListener('mouseover', e => this.mouseOverProject(e));
            item.addEventListener('mouseleave', e => this.mouseLeaveProject(e));
          }
        }
      });

      if (!this.pathInclude(path, this.selectors.project) && !this.activeAnimation) {
        this.showCursor = false;
      } else if (this.pathInclude(path, this.selectors.project)) {
        this.showCursor = true;
      }
    },
    mouseMove(e) {
      let documentOffsetTop = document.documentElement.offsetTop;
      let padding = (document.body.classList.contains('logged-in')) ? documentOffsetTop : 0;

      this.position = {
        x: e.pageX,
        y: e.pageY - padding,
      };

      gsap.set(this.$refs.cc1, {
        x: e.pageX,
        y: e.pageY - padding,
        transformOrigin: "center",
      });
    },
    mouseOverAll() {
      this.$refs.cc1.classList.add("cc-1");
      this.activeAnimation = true;
      this.showCursor = true;
      this.animations.out.kill();

      this.animations.over = gsap.to(this.$refs.cc1, Object.assign(this.options.active, {
        ease: Elastic.easeOut,
        duration: 1,
        onComplete: () => {
          this.activeAnimation = false;
          this.showCursor = true;
        },
      }));
    },
    mouseLeaveAll() {
      this.$refs.cc1.classList.remove("cc-1");
      this.activeAnimation = true;
      this.animations.over.kill();

      this.animations.out = gsap.to(this.$refs.cc1, Object.assign(this.options.default, {
        transformOrigin: "center",
        ease: Power1.easeOut,
        duration: 0.5,
        onComplete: () => {
          this.activeAnimation = false;
          this.showCursor = false;
        },
      }));
    },
    mouseOverProject() {
      this.$refs.cc1.classList.add("projects-cursor");
      this.activeAnimation = true;
      this.showCursor = true;
      this.animations.out.kill();

      this.animations.project = gsap.to(this.$refs.cc1, Object.assign(this.options.project, {
        ease: Elastic.easeOut,
        duration: 1,
        onComplete: () => {
          this.showCursor = true;
          this.activeAnimation = false;
        }
      }));
    },
    mouseLeaveProject() {
      this.$refs.cc1.classList.remove("projects-cursor");
      this.activeAnimation = true;
      this.animations.project.kill();

      this.animations.out = gsap.to(this.$refs.cc1, Object.assign(this.options.default, {
        // ease: Power1.easeOut,
        ease: Elastic.easeOut,
        duration: 0.5,
        onComplete: () => {
          this.showCursor = false;
          this.activeAnimation = false;
        }
      }));
    },
    match(element, selectors) {
      let matches = document.querySelectorAll(selectors);
      return Array.prototype.some.call(matches, function (el) {
        return el === element;
      });
    },
    pathInclude(path, selector) {
      selector = selector.substr(1);

      return path.find(item => item.classList && item.classList.contains(selector));
    }
  },
}
</script>

<style lang="scss">
@import "../../styles/common/_variables.scss";
@import "../../styles/common/_mixins.scss";

.custom-cursor-wrapper {
  display: none;
}

@include desktop {
  //*, a {
  //  cursor: none !important;
  //}

  body {
    .with-custom-cursor {
      cursor: none;
    }

    .custom-cursor-wrapper {
      display: block;
    }

    .custom-cursor {
      backface-visibility: hidden;
      perspective: 1000px;
      perspective-origin: center;
      transform-style: preserve-3d;
      will-change: transform;
      transform-origin: center;

      background-color: $c-yellow;
      border-radius: 50%;
      cursor: none;
      width: 10px;
      height: 10px;
      left: -5px;
      top: -5px;
      pointer-events: none;
      position: absolute;
      //mix-blend-mode: difference;
      z-index: 1000000000;
      overflow: hidden;


      &.cc-1 {
        color: $c-yellow;
        transform: rotate(360deg);
        transform-origin: center;
        transition: all;
        animation-iteration-count: infinite;
        font-size: 12px;
        position: absolute;
        top: -15px;
        left: -15px;
        /*z-index: 10000000001;*/
      }

      &.projects-cursor {
        mix-blend-mode: unset;
        transform: rotate(360deg);
        transform-origin: center;
        transition: all;
        animation-iteration-count: infinite;
        will-change: auto;
        background-color: $c-yellow;
        color: $c-black;
        top: -25px;
        left: -25px;

        &:before {
          content: "Drag";
          text-transform: uppercase;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          display: block;
          font-size: 14px;
          font-family: 'Poppins', sans-serif;
        }
      }
    }
  }
}
</style>
